import React from "react";
import {Avatar} from "@material-ui/core";
import clsx from 'clsx';

export default function Tool(props) {
    let {tool, selected, empty} = props;
    let selectedClass = clsx(selected && 'selected');

    if (empty) {
        return <div className={'tool empty'}/>
    }
    if (tool.icon) {
        return <img className={'icon-tool ' + selectedClass} onClick={() => props.onSelected(tool.id)} key={tool.name}
                    alt={tool.name} src={tool.image}/>
    } else {
        return (
            <div className={'tool ' + selectedClass} onClick={() => props.onSelected(tool.id)}>
                <Avatar key={tool.name} variant="rounded" alt={tool.name} src={tool.image}/>
            </div>
        )
    }
}