import {Container, Grid} from "@material-ui/core";
import OptionsPanel from "./sections/OptionsPanel";
import Workspace from "./Workspace";
import InfoBubble from "./InfoBubble";
import Copyright from "./Copyright";
import React from "react";
import connect from "react-redux/es/connect/connect";
import scene3d from "../threed/Scene3d";
import {makeStyles} from "@material-ui/core/styles";
import ViewMode from "./ViewMode";
import MeasurementText from "./Measurement/MeasurementText";
import {useParams} from 'react-router-dom';
import {getSubmission} from "./Submission/Service";
import LayoutManager from "../threed/layout/LayoutManager";
import SubmissionSteps from "./Submission/SubmissionSteps";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        height: "100%",
        position: "relative"
    },
    main: {
        height: "100%",
        position: "relative"
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

let rendererDom, workspaceDom;

function AtlasBuilder(props) {
    let {info} = props;
    const classes = useStyles();
    const [style, setStyle] = React.useState(calculateStyle());
    const {submissionId} = useParams();

    React.useEffect(() => {
        if (submissionId) {
            getSubmission(submissionId)
                .then(response => {
                    LayoutManager.getInstance().load(response.data.design_json);
                })
                .catch(error => {
                    console.log("Couldn't fetch data for id:", submissionId, error);
                })
        }
    }, [submissionId])

    React.useEffect(() => {
        scene3d.on('before-resize', updateStyle);
        scene3d.initialize(rendererDom);
        updateStyle()
        return () => {
            scene3d.off('before-resize', updateStyle);
        }
    }, []);

    function calculateStyle() {
        let aspectRatio = 1.4;
        let bounds = document.body.getBoundingClientRect();
        let _style = {
            width: '100%',
            height: '100%'
        }

        if (bounds.width / bounds.height < aspectRatio) {
            _style.height = bounds.width / aspectRatio
        }
        return _style;
    }

    function updateStyle() {
        let _style = calculateStyle();
        setStyle(_style)
    }

    return (
        <Container maxWidth={false} className={classes.root} style={style}>
            <Grid container spacing={2} className={classes.main}>
                <Grid id={'delete-target'} item className={"grid40 options"}>
                    <OptionsPanel in={true}/>
                </Grid>
                <Grid id={'create-target'} item className={"grid60 workspace"}
                      ref={(mount) => {
                          workspaceDom = mount
                      }}>
                    <SubmissionSteps/>
                    <Workspace/>
                </Grid>
                <div id={'scene3d'} ref={(mount) => rendererDom = mount}>
                    <MeasurementText text=''/>
                </div>
                <InfoBubble {...info}/>

                <ViewMode/>
            </Grid>
            <Copyright/>
        </Container>);
}


//REDUX
const mapStateToProps = (state) => {
    return {
        info: state.appState.info
    };
};
export default connect(mapStateToProps)(AtlasBuilder)

