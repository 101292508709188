import React from "react";
import Config from "../config/Config";
import {Divider, Grid, IconButton, Link, Switch, Tooltip, Typography} from "@material-ui/core";
import './Toolbar.scss';
import DialogBox from "./DialogBox";
import RefreshIcon from '@material-ui/icons/Refresh'
import HelpIcon from '@material-ui/icons/Help'

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ToolGroup from "./ToolGroup";
import DonePopup from "./DonePopup";
import connect from "react-redux/es/connect/connect";
import {
    setMaterial, setPrintMode,
    setSubmissionFormOpen,
    setViewMode,
    startOver,
    toggleMeasurement,
    toggleWireframe
} from "../store/reducers/appReducer";
import {KEYS} from "../store/StoreKeys";
import {withStyles} from "@material-ui/core/styles";
import {useSelector} from "react-redux";
import Tool from "./Tool";

const otherTools = [
    {
        id: "measurement",
        name: "Measurement tool",
        image: "/images/tape-measure.png",
        toggle: true,
        icon: true
    },
    {
        id: "wireframe",
        name: "Wireframe mode",
        image: "/images/wireframe.png",
        toggle: true,
        icon: true
    }
];

const toolMessages = {
    'cherry': {
        title: 'Tool selected',
        message: 'Cherry material is selected. This action will update the materials in 3D scene.'
    },
    'white_oak': {
        title: 'Tool selected',
        message: 'White oak material is selected. This action will update the materials in 3D scene.'
    },
    'maple': {
        title: 'Tool selected',
        message: 'Maple material is selected. This action will update the materials in 3D scene.'
    },
    'walnut': {
        title: 'Tool selected',
        message: 'Walnut material is selected. This action will update the materials in 3D scene.'
    },
    'steel_natural': {
        title: 'Tool selected',
        message: 'Steel, natural material is selected. This action will update the materials in 3D scene.'
    },
    'steel_blackened': {
        title: 'Tool selected',
        message: 'Steel, blackened material is selected. This action will update the materials in 3D scene.'
    },
    'bronze_natural': {
        title: 'Tool selected',
        message: 'Bronze, natural material is selected. This action will update the materials in 3D scene.'
    },
    'measurement': {
        title: 'Tool selected',
        message: 'Measurement tool is selected. This will activate the measurement tool.'
    },
    'help': {
        title: 'How to use the builder',
        message: <Typography variant="body1" color={'textSecondary'} component="div">
            <ol>
                <li>
                    Starting with the standard, simply drag and drop components to the desired position.
                </li>
                <li>
                    Subsequent standards will snap to a position that will create either a 32" or 22" wide module.
                </li>
                <li>
                    Use the scrolling frame that appears when you place the cursor over a component to view other
                    dimentions
                    available for that component.
                </li>
                <Divider/>
                <p>Still have questions? Feel free to give us a call, or send us an email.</p>
                <Divider/>
                <p>If you are having any issues with the builder program you can refresh your browser, or contact the design office directly through email or phone.</p>
                <span>Office: <Link href="tel:1-845-391-8855">845.391.8855</Link></span><br/>
                <span>Email: <Link target={'_empty'}
                                   href={'mailto:info@atlaseast.com'}>info@atlaseast.com</Link> </span>
            </ol>

        </Typography>
    },
};

const AtlasSwitch = withStyles(theme => ({
    root: {
        width: 140,
        height: 16,
        padding: 0,
        display: 'flex',
        margin: 'auto',
    },
    switchBase: {
        padding: 2,
        color: theme.palette.common.white,
        '& + $track': {
            opacity: 1,
            backgroundColor: theme.palette.text.secondary,
        },
        '&$checked': {
            transform: 'translateX(calc(140px - 44px))',
            '& + $track': {
                opacity: 1,
            },
        },
    },
    thumb: {
        width: 40,
        height: 12,
        boxShadow: 'none',
        borderRadius: 5,
    },
    track: {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 6,
        opacity: 1,
        backgroundColor: theme.palette.common.white,
    },
    checked: {},
}))(Switch);


function Toolbar(props) {
    const [modalOpen, setModalOpen] = React.useState(false);
    const [modalMessage, setModalMessage] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const {dispatch, viewModeOn} = props;

    const measurementOn = useSelector(state => state.appState.measurementOn)
    otherTools[0].image = measurementOn ? "/images/tape-measure-active.png" : "/images/tape-measure.png";

    const wireframeOn = useSelector(state => state.appState.wireframeOn)
    otherTools[1].image = wireframeOn ? "/images/wireframe-active.png" : "/images/wireframe.png";

    function toolSelected(id, value = null) {

        switch (id) {
            case 'done':
                return;
            case 'print':
                dispatch(setPrintMode(true));
                return;
            case 'submit':
                dispatch(setSubmissionFormOpen({open: true, purpose: 'submission'}));
                return;
            case 'save':
                dispatch(setSubmissionFormOpen({open: true, purpose: 'download'}));
                return;
            case 'restart':
                dispatch(startOver());
                return;
            case 'viewMode':
                dispatch(setViewMode(value));
                return;
            case KEYS.metalMaterial:
            case KEYS.woodMaterial:
                dispatch(setMaterial({name: id, value}));
                break;
            case 'measurement':
                dispatch(toggleMeasurement());
                break;
            case 'wireframe':
                dispatch(toggleWireframe());
                break;
            default:
                console.log('Other tool selected:', id, value)
                setModalOpen(true);
                setModalMessage(toolMessages[id]);
                break;
        }
    }

    const handleDialogClose = () => {
        setModalOpen(false);
    };
    const handlePopoverOpen = event => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);


    return (
        <Grid container alignItems={'center'} className={'toolbar'}>
            <DialogBox open={modalOpen} onClose={handleDialogClose} {...modalMessage} />

            {/*LEFT SIDE*/}
            <div className={'left-tools'}>
                <ToolGroup toolSelected={toolSelected}
                           tools={[
                               ...Config.woodOptions.solidHardwood.options,
                               {id: "Separator"},
                               ...Config.woodOptions.customStain.options
                           ]}
                           groupName={KEYS.woodMaterial}
                           expand={true}
                />
                <Divider orientation="vertical" variant={'middle'}/>
                <ToolGroup toolSelected={toolSelected}
                           tools={Config.metalOptions.materials.options}
                           groupName={KEYS.metalMaterial}
                           expand={true}
                />
                <Divider orientation="vertical" variant={'middle'}/>

                <Tool selected={measurementOn}
                      onSelected={() => toolSelected('measurement')}
                      key={'measurement'}
                      tool={otherTools[0]}
                      group={'groupName'}
                />

                <Tool selected={wireframeOn}
                      onSelected={() => toolSelected('wireframe')}
                      key={'wireframe'}
                      tool={otherTools[1]}
                      group={'groupName'}
                />


            </div>
            {/*CENTER*/}
            <div className={'center-tools'}>
                <Typography component="div" color={'textSecondary'}>
                    <Grid component="label" container direction={'column'} justify={'space-between'} alignItems="center"
                          spacing={0}>
                        <Grid item xs={12}>
                            <AtlasSwitch
                                color="default"
                                checked={viewModeOn}
                                onChange={(e) => toolSelected('viewMode', !viewModeOn)}
                                value="checkedC"
                            />
                        </Grid>
                        <Grid component="div" container justify={'space-between'}
                              alignItems="center" spacing={0}
                              className={'switch-texts'}
                        >
                            <Grid item>build</Grid>
                            <Grid item>view</Grid>
                        </Grid>
                    </Grid>
                </Typography>
            </div>

            {/*RIGHT SIDE*/}
            <div className={'right-tools'}>
                <Tooltip title="start over?">
                    <IconButton onClick={() => toolSelected('restart')}>
                        <RefreshIcon fontSize="large"/>
                    </IconButton>
                </Tooltip>
                <div>
                    <IconButton onClick={() => toolSelected('done')}
                                onMouseEnter={handlePopoverOpen}
                    >
                        <CheckCircleIcon fontSize="large"/>
                    </IconButton>
                    <DonePopup open={open}
                               onClose={handlePopoverClose}
                               anchorEl={anchorEl}
                               toolSelected={toolSelected}
                    />

                </div>
                <Tooltip title="help">
                    <IconButton onClick={() => toolSelected('help')}>
                        <HelpIcon fontSize="large"/>
                    </IconButton>
                </Tooltip>
            </div>
        </Grid>

    )
}

//REDUX
const mapStateToProps = (state) => {
    return {
        viewModeOn: state.appState.viewModeOn
    };
};
export default connect(mapStateToProps)(Toolbar)
