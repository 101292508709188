import React from "react";
import './AspectRatio.scss';

export default function AspectRatio(props) {
    const [aspectWidth,setAspectWidth] = React.useState('auto');

    const width = props.width || 100;
    const height = props.height || width / props.ratio;

    const child = React.Children.only(props.children);
    const StyledChild = React.cloneElement(child,
        {
            style: {
                ...child.props.style,
                padding: props.padding,
                width: '100%'
            }
        });

    function imgLoaded(e) {
        setAspectWidth(e.target.width);
    }

    return (
        <div className={'aspect-container'}
             style={{
                 padding: props.padding
             }}>
            <img onLoad={imgLoaded} style={{width: aspectWidth, height: '100%', display: 'block'}} src={"/images/aspect-image.png"}/>
            {/*<svg onLoad={imgLoaded} viewBox={`0 0 ${width} ${height}`}/>*/}
            <div className={'aspect-content'}>
                {StyledChild}
            </div>
        </div>
    )
}
