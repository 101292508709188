class Inventory {
    constructor() {
        this.components = [];
        this.componentModels = [];
    }

    add(component) {
        if (!this.components.includes(component)) {
            this.components.push(component);
            this.componentModels.push(component.model3d);
            return true;
        }
        return false;
    }

    remove(component) {
        let index = this.components.findIndex(c => component === c);
        if (index >= 0) {
            this.components.splice(index, 1);
            this.componentModels.splice(index, 1);
        }
    }

    getComponents() {
        return this.components;
    }

    getComponentModels() {
        return this.componentModels;
    }

    replaceModels(oldModel, newModel) {
        let index = this.componentModels.indexOf(oldModel);
        if (index !== -1) {
            this.componentModels[index] = newModel;
        }
    }
}

export const inventory = new Inventory();
window.inventory = inventory;
