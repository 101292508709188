import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide} from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export default function DialogBox(props) {
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        setOpen(props.open);
    }, [props]);

    const handleClose = () => {
        setOpen(false);
        props.onClose();
    };

    let dialogContent = props.message;
    if (typeof props.message === 'string') {
        dialogContent = <DialogContentText>
            {props.message}
        </DialogContentText>;
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">{props.title || ''}</DialogTitle>
            <DialogContent>
                {dialogContent}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Okay
                </Button>
            </DialogActions>
        </Dialog>
    )
}
