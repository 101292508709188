const DEFAULT_DIST = 99999999;
const NO_WEIGHT = -99999999;
export default class RegionSuggestion {

    constructor(column, parentRegion) {
        this.defineProperties();
        this.column = column;
        this.bestPin = null;
        this.parentRegion = parentRegion;
        this.top = 0;
        this.bottom = 0;
        this.distance = DEFAULT_DIST;
        this.bestPinWeight = NO_WEIGHT;
        this._suggested = true;
        this.pins = [];
        this._width = null;
    }

    static NotSuggested() {
        let suggestion = new RegionSuggestion();
        suggestion._suggested = false;
        return suggestion;
    }

    static best(suggestions) {
        return suggestions.reduce(
            (suggestion1, suggestion2) => {
                return suggestion1.better(suggestion2);
            },
            RegionSuggestion.NotSuggested()
        );
    }

    static sort(suggestions) {
        return suggestions.sort(
            (suggestion1, suggestion2) => {
                if (suggestion1.better(suggestion2)) {
                    return 1;
                } else {
                    return -1;
                }
            }
        );
    }

    setNextSuggestion(suggestion) {
        if (this.nextSuggestion) {
            this.nextSuggestion.prevSuggestion = null;
        }

        this.nextSuggestion = suggestion;

        if (suggestion) {
            suggestion.prevSuggestion = this;
        }
    }

    //Add a pin to suggesion.
    addPin(pin, component, pinPosition, sharedPin) {
        let constraint = component.constraint;

        if (!constraint.mountType.compatible(pin.type)) {
            return;
        }

        let componentPinPosition = component.pinPosition();

        let horizontalDist = Math.abs(componentPinPosition.x - pinPosition.x);
        let verticalDist = Math.abs(componentPinPosition.y - pinPosition.y);

        //Real distance as pin weight
        let pinWeight = -1 * (horizontalDist * 3 + verticalDist);

        /*
        Effective distance, taking into account of padding.
        if the component is dropped inside a padding area, then it should be snapped to the end of padding area away from the padded component
        even if the pin behind the padded component is closer.
        */
        let nextPaddingTop = this.parentRegion.nextPaddingTop;
        let prevPaddingBottom = this.parentRegion.prevPaddingBottom;

        if (nextPaddingTop) {
            let verticalDistWithPadding = Math.abs(componentPinPosition.y - pinPosition.y + nextPaddingTop);
            verticalDist = Math.min(verticalDist, verticalDistWithPadding);
        }
        if (prevPaddingBottom) {
            let verticalDistWithPadding = Math.abs(componentPinPosition.y - pinPosition.y + prevPaddingBottom);
            verticalDist = Math.min(verticalDist, verticalDistWithPadding);
        }

        if (constraint) {
            if (!sharedPin) {
                if (constraint.snapToBottom) {
                    pinWeight = -pinPosition.y;
                } else if (constraint.snapToTop) {
                    pinWeight = pinPosition.y;
                }

                if (pinPosition.y > constraint.maxElevation + component.pin.distToBottom) {
                    pinWeight = NO_WEIGHT;
                }
                if (pinPosition.y < constraint.minElevation + component.pin.distToBottom) {
                    pinWeight = NO_WEIGHT;
                }
            }
        }

        if (pinWeight > NO_WEIGHT) {
            this.pins.push(pin);
        }

        if (pinWeight > this.bestPinWeight) {
            this.bestPinWeight = pinWeight;
            this.distance = horizontalDist + verticalDist;
            this.bestPin = pin;
        }
    }

    setBestPinFrom(suggestion) {
        this.pins.some(pin => {
            if (pin.distToTop === suggestion.bestPin.distToTop) {
                this.bestPin = pin;
                return true;
            }
            return false;
        })
    }

    better(suggestion) {
        if (suggestion) {

            if (!this.isSuggested() && suggestion.isSuggested()) {
                return suggestion;
            }

            if (this.isSuggested() && !suggestion.isSuggested()) {
                return this;
            }

            if (this.distance > suggestion.distance) {
                return suggestion;
            }
        }
        return this;
    }

    setFinal(value) {
        this.final = value;
    }

    isFinal() {
        return !!this.final;
    }

    isSuggested() {
        return this._suggested && this.pins.length > 0;
    }

    freeOnRightSide() {
        let s = this;
        while (s.nextSuggestion) {
            s = s.nextSuggestion;
        }
        return !s.column.nextColumn.hasBothStandards();
    }

    freeOnLeftSide() {
        let s = this;
        while (s.prevSuggestion) {
            s = s.prevSuggestion;
        }
        return !s.column.prevColumn.hasBothStandards();
    }

    defineProperties() {
        Object.defineProperties(this,
            {
                //TODO: May cache the bounds?
                left: {
                    get: () => this.column._bounds().left
                },
                right: {
                    get: () => this.column._bounds().right
                },
                width: {
                    get: () => this._width || this.column.width,
                    set: value => {
                        this._width = value;
                    }
                }
            }
        )
    }
}
