import {EventEmitter} from "events";

export default class InputHandlerBase extends EventEmitter {
    constructor(ray, vpW, vpH) {
        super();
        this.ray = ray;
        this.vpW = vpW;
        this.vpH = vpH;
        this.sceneDom = document.getElementById('scene3d');
    }

    setViewSize({width, height}) {
        this.vpW = width;
        this.vpH = height;
    }

    getMouse(event) {
        let rect = this.sceneDom.getBoundingClientRect();
        return {
            x: (event.clientX - rect.left) / this.vpW * 2 - 1,
            y: (event.clientY - rect.top) / this.vpH * -2 + 1
        };
    }
}
