import React, {useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import {useDispatch, useSelector} from "react-redux";
import SubmissionForm from "./Form";
import SubmissionProgress from "./SubmissionProgress";
import SubmissionResult from "./SubmissionResult";
import {setSubmissionFormOpen} from "../../store/reducers/appReducer";
import {makeStyles} from "@material-ui/core/styles";
import submitDesign from "./Service";

const useStyles = makeStyles((theme) => ({
    root: {
        position: "absolute!important"
    },
    paper: {
        minHeight: 200,
        textAlign: 'center',
    },
    scrollPaper: {
        // justifyContent: 'flex-end',
    }
}));


function SubmissionStep(props) {
    const {step, onCancel, onClose, onNextStep, dataFromPrevStep, purpose} = props;
    switch (step) {
        case 1:
            return <SubmissionForm onSubmit={onNextStep} onCancel={onClose}/>
        case 2:
            return <SubmissionProgress onComplete={onNextStep} progressPromise={dataFromPrevStep} purpose={purpose}/>
        case 3:
            return <SubmissionResult onClose={onClose} purpose={purpose} submissionResult={dataFromPrevStep}/>
        default:
            onClose();
    }
}

export default function SubmissionSteps() {
    const submissionFormOpen = useSelector(state => state.appState.submissionFormOpen);
    const purpose = useSelector(state => state.appState.submissionPurpose);
    const [step, setStep] = useState(1);
    const [dataFromPrevStep, setDataFromPrevStep] = useState(null);
    const dispatch = useDispatch();
    const classes = useStyles();

    React.useEffect(() => {
        if (submissionFormOpen) {
            setStep(1);
            setDataFromPrevStep(null);
            if (purpose === 'download') {
                handleNextStep(submitDesign(null, 'download', {}))
            }
        }
    }, [submissionFormOpen]);

    function handleExited() {
        // setStep(1);
    }

    function handleCancel() {
        handleClose();
    }

    function handleClose() {
        dispatch(setSubmissionFormOpen({open: false}));
    }

    function handleCloseDialog(event,reason) {
        if (step === 1) {
            handleClose();
        }

        if(step === 3 && reason === 'escapeKeyDown'){
            handleClose();
        }
    }

    function handleNextStep(data) {
        setStep(step => step + 1);
        setDataFromPrevStep(data);
    }

    return (
        <Dialog
            disablePortal
            open={submissionFormOpen}
            fullWidth={true}
            maxWidth={'xs'}
            classes={{
                paper: classes.paper,
                scrollPaper: classes.scrollPaper,
                root: classes.root
            }}
            onClose={handleCloseDialog}
            onExited={handleExited}
        >
            <SubmissionStep step={step} onCancel={handleCancel} onClose={handleClose} onNextStep={handleNextStep}
                            dataFromPrevStep={dataFromPrevStep} purpose={purpose}/>
        </Dialog>
    )
}
