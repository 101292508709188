import React from 'react';
import {DropzoneArea} from "material-ui-dropzone";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {marginTop: theme.spacing(2), padding: theme.spacing(1)},
    preview: {marginTop: theme.spacing(2)},
    rootHidden: {
        display: 'none'
    }
}));

export default function ImageUploader(props) {
    const [files, setFiles] = React.useState([]);
    const classes = useStyles();

    function handleChange(files) {
        setFiles(files);
        if (props.onImageSelected) {
            props.onImageSelected(files.length ? files[0] : null);
        }

    }

    return (
        <DropzoneArea
            classes={{root: files.length ? classes.rootHidden : classes.root}}
            onChange={handleChange}
            dropzoneText={"Drag and drop an image here or click"}
            acceptedFiles={['image/*']}
            filesLimit={10}
            showPreviewsInDropzone={false}
            showPreviews={true}
            showAlerts={false}
            previewText={null}
            previewGridProps={{
                container: {spacing: 2},
                item: {xs: 12}
            }}
            initialFiles={props.image ? [props.image] : []}
            previewGridClasses={{container: classes.preview}}
        />
    );

}
