import {BoxBufferGeometry, Group, Mesh, MeshBasicMaterial, PlaneBufferGeometry} from "three";

function createPlane(color, width, height, depth) {
    let material = new MeshBasicMaterial({color: color});
    let geometry = new PlaneBufferGeometry(1, 1);
    if (depth) {
        geometry = new BoxBufferGeometry(1, 1, 1);
    }

    let mesh = new Mesh(geometry, material);
    mesh.scale.set(width, height, 1);
    return mesh;
}

export default class Wall {
    constructor(bounds) {
        this.calculateSizes(bounds);

        this.group = new Group();

        this.workArea = createPlane(0xFFFFFF, this.workAreaWidth, this.workAreaHeight);
        this.back = createPlane(0xFAFAFA, this.width, this.height);
        this.workAreaBorder = createPlane(0x000000, 1, 0.001);
        this.floor = createPlane(0xF4F4F4, this.width, this.height);
        this.floor.rotateX(Math.PI / -2);

        this.setBounds(bounds);

        this.workArea.add(this.workAreaBorder);
        this.workAreaBorder.position.y = -0.5
        this.setBorderOn(false);

        this.group.add(this.back, this.floor, this.workArea);
        // this.group.add(this.back, this.floor, this.workArea, createPlane(0xff0000, 3, 3, 3));
    }

    setBorderOn(on) {
        this.workAreaBorder.visible = on;
    }

    calculateSizes({left, bottom, right, top, width, height}) {
        this.width = right - left;
        this.height = top - bottom;
        this.workAreaWidth = width;
        this.workAreaHeight = height;
    }

    setBounds(bounds) {
        this.calculateSizes(bounds);

        let xOffset = (this.workAreaWidth - this.width) / 2;
        let x = this.width / 2 + xOffset;

        this.back.scale.set(this.width, this.height * 2, 1);
        this.back.position.set(x, this.height / 2, 0);

        let mod = 2;
        this.floor.scale.set(this.workAreaWidth, this.height * mod, 1);
        this.floor.position.set(x, 0, this.height / 2 * mod);

        this.workArea.scale.set(this.workAreaWidth, this.workAreaHeight, 1);
        this.workArea.position.set(x, this.workAreaHeight / 2, 0.1);

        this.left = bounds.left - xOffset;
        this.bottom = bounds.bottom;
        this.group.position.x = this.left;
        this.group.position.y = this.bottom;
        this.group.position.z = 0;
    }

}
