import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit'
// import monitorReducersEnhancer from './enhancers/monitorReducer'
// import loggerMiddleware from './middleware/logger'
import rootReducer from './reducers/rootReducer'
import createSagaMiddleware from 'redux-saga'
import rootSaga from "./saga";

export default function configureAppStore(preloadedState) {
    const sagaMiddleware = createSagaMiddleware();
    const store = configureStore({
        reducer: rootReducer,
        // middleware: [loggerMiddleware, ...getDefaultMiddleware()],
        middleware: [...getDefaultMiddleware(), sagaMiddleware],
        preloadedState,
        // enhancers: [monitorReducersEnhancer]

    });
    sagaMiddleware.run(rootSaga)

    if (process.env.NODE_ENV !== 'production' && module.hot) {
        module.hot.accept('./reducers/reducers', () => store.replaceReducer(rootReducer))
    }
    return store
}
