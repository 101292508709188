import React from 'react';
import {Typography} from '@material-ui/core'
import MaterialList from "./MaterialList";
import './MaterialOptions.scss';

function MaterialOptions(props) {
    const optionSelected = () => {
        props.optionSelected();
    };
    const optionGroups = Object.values(props.options);

    return (
        <div className={"MaterialOptions"}>
            <Typography variant="h5" className={'option-header'}>
                {props.title}
            </Typography>

            {optionGroups.map(group =>
                <MaterialList
                    key={group.key}
                    optionSelected={optionSelected}
                    group={group}/>
            )}

            {/*<div className={'next-button'}>*/}
            {/*<Button variant="contained" onClick={optionSelected}>Next</Button>*/}
            {/*</div>*/}
        </div>
    );
}

export default MaterialOptions;
