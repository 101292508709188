import React from 'react';
import {Typography} from '@material-ui/core'
import Config from "../../../config/Config";
import './StandardSelection.scss'
import clsx from 'clsx';
import FooterData from "data/FooterData";
import AspectRatio from "../../helper/AspectRatio";
import connect from "react-redux/es/connect/connect";
import {setFooterLeft, setSelectedStandard} from "../../../store/reducers/appReducer";

let classNames = {
    [Config.STANDARD_TYPES.WALL_MOUNT]: 'wall-mount',
    [Config.STANDARD_TYPES.COMBINATION]: 'combination'
};

let headers = {
    [Config.STANDARD_TYPES.WALL_MOUNT]: 'wall mounted standard',
    [Config.STANDARD_TYPES.COMBINATION]: 'combination standard'
};

let descriptions = {
    [Config.STANDARD_TYPES.WALL_MOUNT]: 'wall mounted system, attaches to the wall with mounting bars and creates a floating appearance.',
    [Config.STANDARD_TYPES.COMBINATION]: 'distributes the majority of weight of the system to the floor, attaches to the wall with mounting bars.'
};

function StandardSelection(props) {
    const {dispatch, wallDimensions, title} = props;

    const getClasses = standard => {
        return clsx(classNames[standard.type], standard.class);
    };

    const setDescription = standard => {
        let desc = new FooterData();
        if (standard) {
            let header = standard.props.height + ' ' + headers[standard.type];
            let description = descriptions[standard.type];
            desc.append(header, description);
        } else {
            desc.append(null, "All standard lengths are customizable in 2” increments")
        }

        dispatch(setFooterLeft(desc.toJson()))
    };

    const handleChange = standard => {
        dispatch(setSelectedStandard(standard));
        props.optionSelected();
    };

    const filteredItems = Config.Standards.filteredItems({maxHeight: wallDimensions.height});
    const itemCount = filteredItems.length;
    let spacerHeight = 13; //SVG rectangle 98-85
    let topPadding = 10; //SVG rectangle 98-85
    let singleSpacerHeight = spacerHeight / itemCount;
    let colWidth = 100 / itemCount;
    let imageHeight = 100 - spacerHeight * 2 - topPadding;
    let spacers = `repeat(${itemCount}, ${singleSpacerHeight}%)`;

    return (
        <div className={'standard-root'}>
            <Typography variant="h5" className={'option-header'}>
                {title}
            </Typography>

            <div className={'standards-list-container'}
                 style={{
                     gridTemplateColumns: `repeat(${itemCount}, ${colWidth}%)`,
                     gridTemplateRows: `${topPadding}% ${spacers} ${imageHeight}% ${spacers}`
                 }}>
                {filteredItems.map((standard, index) => {
                    index++; //grid indices starts from 1 not 0;
                    return <div key={index} className={'standards-list-item'}
                                style={{
                                    gridColumnStart: index,
                                    gridRowStart: itemCount - index + 2,
                                    gridRowEnd: -index
                                }}>
                        <AspectRatio width={54} height={1000} padding={'0 10px'}>
                            <img src={standard.url} alt={'standard'}
                                 className={getClasses(standard)}
                                 onClick={() => handleChange(standard)}
                                 onMouseEnter={() => setDescription(standard)}
                                 onMouseLeave={() => setDescription()}
                            />
                        </AspectRatio>
                    </div>
                })}
            </div>
        </div>
    )
}

//REDUX
const mapStateToProps = (state) => {
    return {
        wallDimensions: state.appState.wallDimensions
    };
};

export default connect(mapStateToProps)(StandardSelection)
