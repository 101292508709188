import ComponentGroup from "data/ComponentGroup";
import {KEYS} from "../store/StoreKeys";
import FooterData from "../data/FooterData";

export const epsilon = 0.00000001;

export const Dict = {
    solidHardwoodDescLine1: "all products available in solid hardwood with dovetail construction and clear lacquer finish.",
    solidHardwoodDescLine2: "oil finish available upon request.",
    opaqueLacquerDesc: "custom opaque lacquer finish is available in any Benjamin Moore color.",
    customStainDescLine1: "custom stain is available.",
    customStainDescLine2: "we are able to color match from samples.",
    bronzeNaturalDescLine1: "solid architectural bronze bar, no finish.",
    bronzeNaturalDescLine2: "bronze with patina or clear lacquer finish is available upon request.",
    woodSelectionHeader: 'select finish',
    metalSelectionHeader: 'select desired metal type',
    dimensionsHeader: 'enter your wall dimensions',
    standardSelectionHeader: 'click on a standard to get started',
    standardSelectionIdleDesc: "all standard lengths are customizable in 2” increments",
};

const solidHardwoodDesc =
    new FooterData(null, Dict.solidHardwoodDescLine1)
        .append(null, Dict.solidHardwoodDescLine2)
        .setIsMap(false)
        .toJson();

const opaqueLacquerDesc =
    new FooterData(null, Dict.opaqueLacquerDesc)
        .toJson();

const customStainDesc =
    new FooterData(null, Dict.customStainDescLine1)
        .append(null, Dict.customStainDescLine2)
        .setIsMap(false)
        .toJson();

const bronzeNaturalDesc =
    new FooterData(null, Dict.bronzeNaturalDescLine1)
        .append(null, Dict.bronzeNaturalDescLine2)
        .setIsMap(false)
        .toJson();


export const materialsByName = {
    //Wood
    "cherry": {
        id: "cherry",
        name: "cherry",
        image: "/images/materials/wood/cherry horizontal.jpg",
        textureHor: '/images/materials/wood/cherry horizontal.jpg',
        textureVert: '/images/materials/wood/cherry vertical.jpg',
        description: solidHardwoodDesc,
        priceKey: 'N'
    },
    "white_oak": {
        id: "white_oak",
        name: "white oak",
        image: "/images/materials/wood/white oak horizontal.jpg",
        textureHor: "/images/materials/wood/white oak horizontal.jpg",
        textureVert: "/images/materials/wood/white oak vertical.jpg",
        description: solidHardwoodDesc,
        priceKey: 'N'
    },
    "maple": {
        id: "maple",
        name: "maple",
        image: "/images/materials/wood/maple horizontal.jpg",
        textureHor: "/images/materials/wood/maple horizontal.jpg",
        textureVert: "/images/materials/wood/maple vertical.jpg",
        description: solidHardwoodDesc,
        priceKey: 'N'
    },
    "walnut": {
        id: "walnut",
        name: "walnut",
        image: "/images/materials/wood/walnut horizontal.jpg",
        textureHor: "/images/materials/wood/walnut horizontal.jpg",
        textureVert: "/images/materials/wood/walnut vertical.jpg",
        description: solidHardwoodDesc,
        priceKey: 'N'
    },
    //Custom stain
    "light_stain": {
        id: "light_stain",
        name: "light stain on hardwood",
        image: "/images/materials/wood/White Oak, white stain.jpg",
        textureHor: "/images/materials/wood/White Oak, white stain.jpg",
        textureVert: "/images/materials/wood/White Oak, white stain.jpg",
        description: customStainDesc,
        priceKey: 'LC_STAIN'
    },
    "dark_stain": {
        id: "dark_stain",
        name: "dark stain on hardwood",
        image: "/images/materials/wood/Walnut with EZ black stain horizontal.jpg",
        textureHor: "/images/materials/wood/Walnut with EZ black stain horizontal.jpg",
        textureVert: "/images/materials/wood/Walnut with EZ black stain.jpg",
        description: customStainDesc,
        priceKey: 'LC_STAIN'
    },
    // "white_opaque_lacquer": {
    //     id: "white_opaque_lacquer",
    //     name: "opaque lacquer",
    //     image: "/images/materials/wood/White Opaque Lacquer.jpg",
    //     textureHor: "/images/materials/wood/White Opaque Lacquer.jpg",
    //     textureVert: "/images/materials/wood/White Opaque Lacquer.jpg",
    //     description: opaqueLacquerDesc,
    //     priceKey: 'LC_STAIN'
    // },

    "white_opaque_lacquer": {
        id: "white_opaque_lacquer",
        name: "opaque lacquer",
        image: "/images/materials/wood/Blue Opaque Lacquer.jpg",
        textureHor: "/images/materials/wood/Blue Opaque Lacquer.jpg",
        textureVert: "/images/materials/wood/Blue Opaque Lacquer.jpg",
        description: opaqueLacquerDesc,
        priceKey: 'LC_STAIN'
    },

    //Metal
    "steel_natural": {
        id: "steel_natural",
        name: "steel, natural",
        image: "/images/materials/metal/cold-rolled steel.jpg",
        textureHor: "/images/materials/metal/cold-rolled steel.jpg",
        description: 'solid cold-rolled steel bar, clear lacquer finish.',
        priceKey: 'CRS'
    },
    "steel_blackened": {
        id: "steel_blackened",
        name: "steel, blackened",
        image: "/images/materials/metal/blackened steel.jpeg",
        textureHor: "/images/materials/metal/blackened steel.jpeg",
        description: 'solid cold-rolled steel bar with blackening patina and clear lacquer finish.',
        priceKey: 'BCRS'
    },
    "bronze_natural": {
        id: "bronze_natural",
        name: "bronze, natural",
        image: "/images/materials/metal/Bronze.jpeg",
        textureHor: "/images/materials/metal/Bronze.jpeg",
        description: bronzeNaturalDesc,
        priceKey: 'Bronze'
    }
}

const Config = {
    woodenMaterialsVertical: ['Walnut_Vertical', 'Walnut_Vertical2', 'white_oak_vertical_shorter1', 'White_oak__Vertical2', 'white_oak_vertical5', 'white_oak_vertical4', 'white_oak_vertical_shorter2', 'White_oak__Vertical1', 'white_oak_vertical_shorter3', 'Walnut_Vertical1', 'white_oak_vertical3', 'white_oak_vertical2'],
    woodenMaterialsHorizontal: ['Walnut_Long', 'Walnut_Long1', 'Walnut_Long_copy', 'white_oak16', 'white_oak1', 'Cherry_LONG', 'Walnut_Long2', 'white_oak4', 'Walnut_Long9', 'Walnut_Long7', 'Walnut_Long12',],
    metalMaterials: ['Aluminum', 'Steel_Brushed_5cm', '_37', '_Charcoal_', '_Charcoal_1'],
    allMaterials: ['Aluminum', 'Steel_Brushed_5cm', '_37', '_Charcoal_', '_Charcoal_1', 'Walnut_Long', 'Walnut_Long1', 'Walnut_Long7', 'Walnut_Long12', 'Walnut_Long_copy', 'white_oak16', 'white_oak1', 'Cherry_LONG', 'Walnut_Long2', 'white_oak4', 'Walnut_Long9', 'Walnut_Vertical', 'Walnut_Vertical2', 'white_oak_vertical_shorter1', 'White_oak__Vertical2', 'white_oak_vertical5', 'white_oak_vertical4'],

    transition: 500,
    woodOptions: {
        solidHardwood: {
            storeKey: KEYS.woodMaterial,
            title: "solid hardwood",
            key: 'solid-hardwood',
            dense: true,
            options: [
                materialsByName["cherry"],
                materialsByName["white_oak"],
                materialsByName["maple"],
                materialsByName["walnut"],
            ]
        },
        customStain: {
            storeKey: KEYS.woodMaterial,
            title: "custom finishes",
            key: 'custom-finishes',
            dense: true,
            options: [
                materialsByName["light_stain"],
                materialsByName["dark_stain"],
                materialsByName["white_opaque_lacquer"],
            ]
        }
    },
    metalOptions: {
        materials: {
            storeKey: KEYS.metalMaterial,
            key: 'metalOptions',
            options: [
                materialsByName["steel_natural"],
                materialsByName["steel_blackened"],
                materialsByName["bronze_natural"],
            ]
        }
    },
    components: {
        "bookshelves": {
            "filter": true,
            "id": "bookshelves",
            "title": "bookshelves",
            "items": [
                {
                    "url": "/images/components/all/bookshelves -D10 -H5.5 -W22.png",
                    "priceKey": "bs 22",
                    "order": 12,
                    "props": {
                        "height": "5.5",
                        "depth": "10",
                        "width": "22\" or 32\""
                    },
                    "pinToTop": 0.75,
                    "pinToBack": 0.9375
                }
            ],
            "include": true,
            "hasVariant": true,
            "type": "bookshelf",
            "description": "solid hardwood, dovetail construction",
            "visible": true
        },
        "cord management system": {
            "filter": true,
            "id": "cord management system",
            "title": "cord organizer panel",
            "items": [
                {
                    "url": "/images/components/all/cord management system -H20.75 -W22.png",
                    "priceKey": "Cord management system 22",
                    "order": 13,
                    "props": {
                        "height": "20.75",
                        "width": "22\" or 32\""
                    },
                    "pinToTop": 1.25,
                    "pinToBack": 0.5
                }
            ],
            "include": true,
            "hasVariant": true,
            "type": "cord",
            "description": "solid hardwood, solid metal bar.",
            "visible": true
        },
        "custom adaptor and universal TV mount": {
            "filter": false,
            "id": "custom adaptor and universal TV mount",
            "title": "custom adaptor and universal TV mount",
            "items": [
                {
                    "url": "/images/components/all/custom adaptor and universal TV mount -X40.png",
                    "priceKey": "TV mount adaptor (ALL size)",
                    "order": 14,
                    "props": {
                        "width": "40"
                    },
                    "pinToLeft": 17.75,
                    "pinToTop": 7.8125,
                    "pinToBack": 1
                },
                {
                    "url": "/images/components/all/custom adaptor and universal TV mount -X55.png",
                    "priceKey": "TV mount adaptor (ALL size)",
                    "order": 15,
                    "props": {
                        "width": "55"
                    },
                    "pinToLeft": 24.25,
                    "pinToTop": 11.5,
                    "pinToBack": 1
                }
            ],
            "include": true,
            "hasVariant": false,
            "type": "tv",
            "helpText": "Atlas makes its own TV adaptor and sources a universal TV mount to attach a TV or monitor to the AS4 system. Client is responsible for providing TV or monitor",
            "description": "solid metal construction, and off the shelf universal TV mount to attach TV or monitor to AS4 system. TV or monitor not included.",
            "visible": true
        },
        "deck with closet hang bar": {
            "filter": true,
            "id": "deck with closet hang bar",
            "title": "deck with closet hang bar",
            "items": [
                {
                    "url": "/images/components/all/deck with closet hang bar -D19 -W22.png",
                    "priceKey": "deck w/ hangbar 19.22",
                    "order": 16,
                    "props": {
                        "depth": "19",
                        "width": "22\" or 32\""
                    },
                    "pinToTop": 0.25,
                    "pinToBack": 1
                }
            ],
            "include": true,
            "hasVariant": true,
            "type": "hanger",
            "description": "solid hardwood, metal bar construction.",
            "visible": true
        },
        "decks": {
            "filter": false,
            "id": "decks",
            "title": "decks",
            "items": [
                {
                    "url": "/images/components/all/decks -D13 -W22.png",
                    "priceKey": "deck 13.22",
                    "order": 17,
                    "props": {
                        "depth": "13",
                        "width": "22\" or 32\""
                    },
                    "pinToTop": 0.25,
                    "pinToBack": 1
                },
                {
                    "url": "/images/components/all/decks -D16 -W22.png",
                    "priceKey": "deck 16.22",
                    "order": 18,
                    "props": {
                        "depth": "16",
                        "width": "22\" or 32\""
                    },
                    "pinToTop": 0.25,
                    "pinToBack": 1
                },
                {
                    "url": "/images/components/all/decks -D19 -W22.png",
                    "priceKey": "deck 19.22",
                    "order": 19,
                    "props": {
                        "depth": "19",
                        "width": "22\" or 32\""
                    },
                    "pinToTop": 0.25,
                    "pinToBack": 1
                }
            ],
            "include": true,
            "hasVariant": true,
            "type": "deck",
            "description": "solid hardwood, metal bar construction.",
            "visible": true
        },
        "desk": {
            "filter": false,
            "id": "desk",
            "title": "desk",
            "items": [
                {
                    "url": "/images/components/all/desk -D24 -T1 -W108.png",
                    "priceKey": "(per linear inch) desk solid",
                    "order": 7,
                    "props": {
                        "depth": "24",
                        "width": "108",
                        "thickness": 1
                    },
                    "pinToTop": 1.5,
                    "pinToBack": -0.5
                },
                {
                    "url": "/images/components/all/desk -D24 -T1 -W36.png",
                    "priceKey": "(per linear inch) desk solid",
                    "order": 1,
                    "props": {
                        "depth": "24",
                        "width": "36",
                        "thickness": 1
                    },
                    "pinToTop": 1.5,
                    "pinToBack": -0.5
                },
                {
                    "url": "/images/components/all/desk -D24 -T1 -W48.png",
                    "priceKey": "(per linear inch) desk solid",
                    "order": 2,
                    "props": {
                        "depth": "24",
                        "width": "48",
                        "thickness": 1
                    },
                    "pinToTop": 1.5,
                    "pinToBack": -0.5
                },
                {
                    "url": "/images/components/all/desk -D24 -T1 -W60.png",
                    "priceKey": "(per linear inch) desk solid",
                    "order": 3,
                    "props": {
                        "depth": "24",
                        "width": "60",
                        "thickness": 1
                    },
                    "pinToTop": 1.5,
                    "pinToBack": -0.5
                },
                {
                    "url": "/images/components/all/desk -D24 -T1 -W72.png",
                    "priceKey": "(per linear inch) desk solid",
                    "order": 4,
                    "props": {
                        "depth": "24",
                        "width": "72",
                        "thickness": 1
                    },
                    "pinToTop": 1.5,
                    "pinToBack": -0.5
                },
                {
                    "url": "/images/components/all/desk -D24 -T1 -W84.png",
                    "priceKey": "(per linear inch) desk solid",
                    "order": 5,
                    "props": {
                        "depth": "24",
                        "width": "84",
                        "thickness": 1
                    },
                    "pinToTop": 1.5,
                    "pinToBack": -0.5
                },
                {
                    "url": "/images/components/all/desk -D24 -T1 -W96.png",
                    "priceKey": "(per linear inch) desk solid",
                    "order": 6,
                    "props": {
                        "depth": "24",
                        "width": "96",
                        "thickness": 1
                    },
                    "pinToTop": 1.5,
                    "pinToBack": -0.5
                }
            ],
            "include": true,
            "hasVariant": false,
            "type": "desk",
            "helpText": "Now that you have a desk, add a pencil drawer below it for support",
            "description": "solid hardwood, can customized to any length.",
            "visible": true
        },
        "desk brackets": {
            "filter": true,
            "id": "desk brackets",
            "title": "desk drackets",
            "items": [
                {
                    "url": "/images/components/all/desk drackets -D18 -W22.png",
                    "priceKey": "desk brackets",
                    "order": 27,
                    "props": {
                        "depth": "18",
                        "width": "22\" or 32\""
                    },
                    "pinToLeft": 0.25,
                    "pinToTop": 0.5,
                    "pinToBack": 1
                }
            ],
            "include": true,
            "hasVariant": false,
            "type": "bracket",
            "description": "Solid metal construction.",
            "visible": true
        },
        "lower cabinets": {
            "filter": false,
            "id": "lower cabinets",
            "title": "lower cabinets",
            "items": [
                {
                    "url": "/images/components/all/lower cabinets -Left -D18 -H20.75 -W22.png",
                    "priceKey": "locab 22",
                    "order": 33,
                    "props": {
                        "height": "20.75",
                        "depth": "18",
                        "width": "22\" or 32\""
                    },
                    "pinToTop": 3.25,
                    "pinToBack": 1
                },
                {
                    "url": "/images/components/all/lower cabinets -Left -D18 -H24.75 -W22.png",
                    "onlyAdmin": true,
                    "priceKey": "24” H locab 22",
                    "order": 34,
                    "props": {
                        "height": "24.75",
                        "depth": "18",
                        "width": "22\" or 32\""
                    },
                    "pinToTop": 3.25,
                    "pinToBack": 1
                },
                {
                    "url": "/images/components/all/lower cabinets -Right -D18 -H20.75 -W22.png",
                    "priceKey": "locab 22",
                    "order": 37,
                    "props": {
                        "height": "20.75",
                        "depth": "18",
                        "width": "22\" or 32\""
                    },
                    "pinToTop": 3.25,
                    "pinToBack": 1
                },
                {
                    "url": "/images/components/all/lower cabinets -Right -D18 -H24.75 -W22.png",
                    "onlyAdmin": true,
                    "priceKey": "24” H locab 22",
                    "order": 38,
                    "props": {
                        "height": "24.75",
                        "depth": "18",
                        "width": "22\" or 32\""
                    },
                    "pinToTop": 3.25,
                    "pinToBack": 1
                }
            ],
            "include": true,
            "hasVariant": true,
            "type": "lower-cabinet",
            "description": "solid hardwood, dovetail construction.",
            "visible": true
        },
        "LP cabinet": {
            "filter": false,
            "id": "LP cabinet",
            "title": "LP cabinet",
            "items": [
                {
                    "url": "/images/components/all/LP cabinet -D12 -H15 -W22.png",
                    "priceKey": "LPcab 22",
                    "order": 39,
                    "props": {
                        "height": "15",
                        "depth": "12",
                        "width": "22\" or 32\""
                    },
                    "pinToTop": 2,
                    "pinToBack": 1
                },
                {
                    "url": "/images/components/all/LP cabinet -D12 -H29 -W22.png",
                    "priceKey": "Double LPcab 22",
                    "order": 40,
                    "props": {
                        "height": "29",
                        "depth": "12",
                        "width": "22\" or 32\""
                    },
                    "pinToTop": 2,
                    "pinToBack": 1
                }
            ],
            "include": true,
            "hasVariant": true,
            "type": "cabinet",
            "description": "solid hardwood, dovetail construction.",
            "visible": true
        },
        "pencil drawer": {
            "filter": true,
            "id": "pencil drawer",
            "title": "pencil drawer",
            "items": [
                {
                    "url": "/images/components/all/pencil drawer -D18 -H2 -W22.png",
                    "priceKey": "pencil drawer d 2.18.22",
                    "order": 41,
                    "props": {
                        "height": "2",
                        "depth": "18",
                        "width": "22\" or 32\""
                    },
                    "pinToLeft": 0.25,
                    "pinToTop": 0.5,
                    "pinToBack": 1
                }
            ],
            "include": true,
            "hasVariant": true,
            "type": "bracket",
            "description": "solid hardwood, dovetail construction, solid metal bar.",
            "visible": true
        },
        "racks": {
            "filter": false,
            "id": "racks",
            "title": "racks",
            "items": [
                {
                    "url": "/images/components/all/racks -D13 -W22.png",
                    "priceKey": "rack 13.22",
                    "order": 42,
                    "props": {
                        "depth": "13",
                        "width": "22\" or 32\""
                    },
                    "pinToTop": 0.25,
                    "pinToBack": 1
                },
                {
                    "url": "/images/components/all/racks -D16 -W22.png",
                    "priceKey": "rack 16.22",
                    "order": 43,
                    "props": {
                        "depth": "16",
                        "width": "22\" or 32\""
                    },
                    "pinToTop": 0.25,
                    "pinToBack": 1
                },
                {
                    "url": "/images/components/all/racks -D19 -W22.png",
                    "priceKey": "rack 19.22",
                    "order": 44,
                    "props": {
                        "depth": "19",
                        "width": "22\" or 32\""
                    },
                    "pinToTop": 0.25,
                    "pinToBack": 1
                }
            ],
            "include": true,
            "hasVariant": true,
            "type": "rack",
            "description": "solid metal construction.",
            "visible": true
        },
        "Standard": {
            "filter": false,
            "id": "Standard",
            "title": "Standard",
            "items": [
                {
                    "type": "combination",
                    "url": "/images/components/all/Standard -Combination -H117.png",
                    "description": "distributes the majority of weight of the system to the floor, attaches to the wall with mounting bars.",
                    "priceKey": "combi117",
                    "order": 3,
                    "props": {
                        "height": "117"
                    },
                    "pinToTop": 1,
                    "pinToBack": 2.375,
                    "title": "117\" combination standard"
                },
                {
                    "type": "combination",
                    "url": "/images/components/all/Standard -Combination -H29.png",
                    "description": "distributes the majority of weight of the system to the floor, attaches to the wall with mounting bars.",
                    "priceKey": "combi29",
                    "order": 1,
                    "props": {
                        "height": "29"
                    },
                    "pinToTop": 1,
                    "pinToBack": 2.375,
                    "title": "27\" combination standard"
                },
                {
                    "type": "combination",
                    "url": "/images/components/all/Standard -Combination -H93.png",
                    "description": "distributes the majority of weight of the system to the floor, attaches to the wall with mounting bars.",
                    "priceKey": "combi93",
                    "order": 2,
                    "props": {
                        "height": "93"
                    },
                    "pinToTop": 1,
                    "pinToBack": 2.375,
                    "title": "93\" combination standard"
                },
                {
                    "type": "wall-mount",
                    "url": "/images/components/all/Standard -Wall Mount -H107.png",
                    "description": "wall mounted system, attaches to the wall with mounting bars and creates a floating appearance.",
                    "priceKey": "wall107",
                    "order": 8,
                    "props": {
                        "height": "107"
                    },
                    "pinToTop": 1,
                    "pinToBack": 1.5625,
                    "title": "107\" standard"
                },
                {
                    "type": "wall-mount",
                    "url": "/images/components/all/Standard -Wall Mount -H25.png",
                    "description": "wall mounted system, attaches to the wall with mounting bars and creates a floating appearance.",
                    "priceKey": "wall25",
                    "order": 4,
                    "props": {
                        "height": "25"
                    },
                    "pinToTop": 1,
                    "pinToBack": 1.5625,
                    "title": "25\" standard"
                },
                {
                    "type": "wall-mount",
                    "url": "/images/components/all/Standard -Wall Mount -H51.png",
                    "description": "wall mounted system, attaches to the wall with mounting bars and creates a floating appearance.",
                    "priceKey": "wall51",
                    "order": 5,
                    "props": {
                        "height": "51"
                    },
                    "pinToTop": 1,
                    "pinToBack": 1.5625,
                    "title": "51\" standard"
                },
                {
                    "type": "wall-mount",
                    "url": "/images/components/all/Standard -Wall Mount -H81.png",
                    "description": "wall mounted system, attaches to the wall with mounting bars and creates a floating appearance.",
                    "priceKey": "wall81",
                    "order": 6,
                    "props": {
                        "height": "81"
                    },
                    "pinToTop": 1,
                    "pinToBack": 1.5625,
                    "title": "81\" standard"
                },
                {
                    "type": "wall-mount",
                    "url": "/images/components/all/Standard -Wall Mount -H91.png",
                    "description": "wall mounted system, attaches to the wall with mounting bars and creates a floating appearance.",
                    "priceKey": "wall91",
                    "order": 7,
                    "props": {
                        "height": "91"
                    },
                    "pinToTop": 1,
                    "pinToBack": 1.5625,
                    "title": "91\" standard"
                }
            ],
            "include": true,
            "hasVariant": false,
            "type": "standard",
            "visible": true
        },
        "upper cabinets": {
            "filter": false,
            "id": "upper cabinets",
            "title": "upper cabinets",
            "items": [
                {
                    "url": "/images/components/all/upper cabinets -Left -D14 -H22 -W22.png",
                    "priceKey": "upcab 22.22",
                    "order": 45,
                    "props": {
                        "height": "22",
                        "depth": "14",
                        "width": "22\" or 32\""
                    },
                    "pinToTop": 3.25,
                    "pinToBack": 1
                },
                {
                    "url": "/images/components/all/upper cabinets -Left -D14 -H32 -W22.png",
                    "priceKey": "upcab 32.22",
                    "order": 46,
                    "props": {
                        "height": "32",
                        "depth": "14",
                        "width": "22\" or 32\""
                    },
                    "pinToTop": 3.25,
                    "pinToBack": 1
                },
                {
                    "url": "/images/components/all/upper cabinets -Right -D14 -H22 -W22.png",
                    "priceKey": "upcab 22.22",
                    "order": 47,
                    "props": {
                        "height": "22",
                        "depth": "14",
                        "width": "22\" or 32\""
                    },
                    "pinToTop": 3.25,
                    "pinToBack": 1
                },
                {
                    "url": "/images/components/all/upper cabinets -Right -D14 -H32 -W22.png",
                    "priceKey": "upcab 32.22",
                    "order": 48,
                    "props": {
                        "height": "32",
                        "depth": "14",
                        "width": "22\" or 32\""
                    },
                    "pinToTop": 3.25,
                    "pinToBack": 1
                }
            ],
            "include": true,
            "hasVariant": true,
            "type": "upper-cabinet",
            "description": "solid hardwood, dovetail construction.",
            "visible": true
        },
        "short drawer cabinets": {
            "filter": false,
            "id": "short drawer cabinets",
            "title": "short drawer cabinets",
            "items": [
                {
                    "url": "/images/components/all/short drawer cabinets -4-4-6-6 -D18 -H20.75 -W22.png",
                    "description": "solid hardwood, dovetail construction, drawer slides.\n(2) 4” drawers and (2) 6” drawers",
                    "priceKey": "dcab (4-4-6-6) 20.22",
                    "order": 50,
                    "props": {
                        "height": "20.75",
                        "depth": "18",
                        "width": "22\" or 32\""
                    },
                    "pinToTop": 3.25,
                    "pinToBack": 1,
                    "title": "drawer cabinets"
                },
                {
                    "url": "/images/components/all/short drawer cabinets -4-4-12 -D18 -H20.75 -W22.png",
                    "description": "solid hardwood, dovetail construction, drawer slides.\n(2) 4” drawers and (1) 12” filing drawer",
                    "priceKey": "dcab (4-4-12) 20.22",
                    "order": 49,
                    "props": {
                        "height": "20.75",
                        "depth": "18",
                        "width": "22\" or 32\""
                    },
                    "pinToTop": 3.25,
                    "pinToBack": 1,
                    "title": "drawer cabinets"
                },
                {
                    "url": "/images/components/all/short drawer cabinets -6-6-8 -D18 -H20.75 -W22.png",
                    "description": "solid hardwood, dovetail construction, drawer slides.\n(2) 6” drawers and (1) 8” drawer",
                    "priceKey": "dcab (6-6-8) 20.22",
                    "order": 52,
                    "props": {
                        "height": "20.75",
                        "depth": "18",
                        "width": "22\" or 32\""
                    },
                    "pinToTop": 3.25,
                    "pinToBack": 1,
                    "title": "drawer cabinets"
                },
                {
                    "url": "/images/components/all/short drawer cabinets -8-12 -D18 -H20.75 -W22.png",
                    "description": "solid hardwood, dovetail construction, drawer slides.\n(1) 8” drawer and (1) 12” filing drawer",
                    "priceKey": "dcab (8-12) 20.22",
                    "order": 51,
                    "props": {
                        "height": "20.75",
                        "depth": "18",
                        "width": "22\" or 32\""
                    },
                    "pinToTop": 3.25,
                    "pinToBack": 1,
                    "title": "drawer cabinets"
                }
            ],
            "include": true,
            "hasVariant": true,
            "type": "short drawer cabinets",
            "description": "combination drawer cabinnets",
            "visible": true
        },
        "tall drawer cabinets": {
            "filter": false,
            "id": "tall drawer cabinets",
            "title": "tall drawer cabinets",
            "items": [
                {
                    "url": "/images/components/all/tall drawer cabinets -12-12 -D18 -H24.75 -W22.png",
                    "description": "solid hardwood, dovetail construction, drawer slides.\n(2) 12” filing drawers",
                    "priceKey": "dcab (12-12) 24.22",
                    "order": 55,
                    "props": {
                        "height": "24.75",
                        "depth": "18",
                        "width": "22\" or 32\""
                    },
                    "pinToTop": 3.25,
                    "pinToBack": 1,
                    "title": "drawer cabinets"
                },
                {
                    "url": "/images/components/all/tall drawer cabinets -4-4-4-12 -D18 -H24.75 -W22.png",
                    "description": "solid hardwood, dovetail construction, drawer slides.\n(3) 4” drawers and (1) 12” filing drawer",
                    "priceKey": "dcab (4-4-4-12) 24.22",
                    "order": 53,
                    "props": {
                        "height": "24.75",
                        "depth": "18",
                        "width": "22\" or 32\""
                    },
                    "pinToTop": 3.25,
                    "pinToBack": 1,
                    "title": "drawer cabinets"
                },
                {
                    "url": "/images/components/all/tall drawer cabinets -4-8-12 -D18 -H24.75 -W22.png",
                    "description": "solid hardwood, dovetail construction, drawer slides.\n(1) 4” drawer, (1) 8” drawer and (1) 12” filing drawer",
                    "priceKey": "dcab (4-8-12) 24.22",
                    "order": 54,
                    "props": {
                        "height": "24.75",
                        "depth": "18",
                        "width": "22\" or 32\""
                    },
                    "pinToTop": 3.25,
                    "pinToBack": 1,
                    "title": "drawer cabinets"
                },
                {
                    "url": "/images/components/all/tall drawer cabinets -8-8-8 -D18 -H24.75 -W22.png",
                    "description": "solid hardwood, dovetail construction, drawer slides.\n(3) 8” drawers",
                    "priceKey": "dcab (8-8-8) 24.22",
                    "order": 56,
                    "props": {
                        "height": "24.75",
                        "depth": "18",
                        "width": "22\" or 32\""
                    },
                    "pinToTop": 3.25,
                    "pinToBack": 1,
                    "title": "drawer cabinets"
                }
            ],
            "include": true,
            "hasVariant": true,
            "type": "tall drawer cabinets",
            "description": "combination drawer cabinnets",
            "visible": true
        }
    }
};

window.Config = Config;
Config.STANDARD_TYPES = {WALL_MOUNT: 'wall-mount', COMBINATION: 'combination'};

Config.COMPONENT_TYPES = {
    drawers12: '12.5” drawers',
    drawers18: '18” drawers',
    Bookshelves: 'bookshelves',
    Cords: 'cord management system',
    Tvs: 'custom adaptor and universal TV mount',
    DeckWithHangbar: 'deck with closet hang bar',
    Decks: 'decks',
    DeskSurfaces: 'desk',
    DeskBrackets: 'pencil drawer',
    DrawerCaps: 'drawer caps',
    LowerCabinet: 'lower cabinets',
    LPCabinets: 'LP cabinet',
    Racks: 'racks',
    Standards: 'Standard',
    UpperCabinet: 'upper cabinets',
    ShortDrawerCabinets: "short drawer cabinets",
    TallDrawerCabinets: "tall drawer cabinets",
}

// Config.drawers12 = new ComponentGroup(Config.components[Config.COMPONENT_TYPES.drawers12]);
// Config.drawers18 = new ComponentGroup(Config.components[Config.COMPONENT_TYPES.drawers18]);
Config.Bookshelves = new ComponentGroup(Config.components[Config.COMPONENT_TYPES.Bookshelves]);
Config.Cords = new ComponentGroup(Config.components[Config.COMPONENT_TYPES.Cords]);
Config.DeckWithHangbar = new ComponentGroup(Config.components[Config.COMPONENT_TYPES.DeckWithHangbar]);
Config.Decks = new ComponentGroup(Config.components[Config.COMPONENT_TYPES.Decks]);
Config.DeskBrackets = new ComponentGroup(Config.components[Config.COMPONENT_TYPES.DeskBrackets]);
Config.DeskSurfaces = new ComponentGroup(Config.components[Config.COMPONENT_TYPES.DeskSurfaces]);
// Config.DrawerCaps = new ComponentGroup(Config.components[Config.COMPONENT_TYPES.DrawerCaps]);
Config.LowerCabinet = new ComponentGroup(Config.components[Config.COMPONENT_TYPES.LowerCabinet]);
Config.LPCabinets = new ComponentGroup(Config.components[Config.COMPONENT_TYPES.LPCabinets]);
Config.Racks = new ComponentGroup(Config.components[Config.COMPONENT_TYPES.Racks]);
Config.Standards = new ComponentGroup(Config.components[Config.COMPONENT_TYPES.Standards]);
Config.Tvs = new ComponentGroup(Config.components[Config.COMPONENT_TYPES.Tvs]);
Config.UpperCabinet = new ComponentGroup(Config.components[Config.COMPONENT_TYPES.UpperCabinet]);
Config.ShortDrawerCabinets = new ComponentGroup(Config.components[Config.COMPONENT_TYPES.ShortDrawerCabinets]);
Config.TallDrawerCabinets = new ComponentGroup(Config.components[Config.COMPONENT_TYPES.TallDrawerCabinets]);

Config.componentGroups = [
    // Config.drawers12,
    // Config.drawers18,
    Config.Bookshelves,
    Config.Cords,
    Config.DeckWithHangbar,
    Config.Decks,
    Config.DeskBrackets,
    Config.DeskSurfaces,
    // Config.DrawerCaps,
    Config.LowerCabinet,
    Config.LPCabinets,
    Config.Racks,
    Config.Standards,
    Config.Tvs,
    Config.UpperCabinet,
    Config.ShortDrawerCabinets,
    Config.TallDrawerCabinets
];

Config.componentsByName = {};
Object.values(Config.componentGroups).forEach(group => {
        group.items.forEach(item => Config.componentsByName[item.name] = item);
    }
)


Config.debug = {
    pinHelper: false,
    regionHelper: false,
};

Config.setIsAdmin = (admin) => {
    Config.isAdmin = admin;
};

Config.isAdmin = false;
Config.debug = false;

window.Config = Config;
export default Config;


