import React from 'react';
import Config from "../../../config/Config";
import ComponentCarousel from "./ComponentCarousel";
import ViewpointOptions from "./ViewpointOptions";
import connect from "react-redux/es/connect/connect";

const grid = {
    //Row1
    [Config.COMPONENT_TYPES.UpperCabinet]: {row: {start: 1, end: 0}, col: {start: 1, end: 0}},
    [Config.COMPONENT_TYPES.LowerCabinet]: {row: {start: 1, end: 0}, col: {start: 2, end: 0}},
    [Config.COMPONENT_TYPES.LPCabinets]: {row: {start: 1, end: 0}, col: {start: 3, end: 0}},

    //Row2
    [Config.COMPONENT_TYPES.ShortDrawerCabinets]: {row: {start: 2, end: 0}, col: {start: 1, end: 0}, marginLeft: '45%'},
    [Config.COMPONENT_TYPES.DeskBrackets]: {row: {start: 2, end: 0}, col: {start: 3, end: 0}, marginLeft: '-45%'},

    //Row3
    [Config.COMPONENT_TYPES.Decks]: {row: {start: 3, end: 0}, col: {start: 1, end: 0}, bottom: "15%"},
    [Config.COMPONENT_TYPES.Racks]: {row: {start: 3, end: 0}, col: {start: 2, end: 0}, bottom: "15%"},
    [Config.COMPONENT_TYPES.DeckWithHangbar]: {row: {start: 3, end: 0}, col: {start: 3, end: 0},bottom: "15%"},

    //Row4
    [Config.COMPONENT_TYPES.Tvs]: {row: {start: 4, end: 0}, col: {start: 1, end: 0}},
    [Config.COMPONENT_TYPES.Cords]: {row: {start: 4, end: 0}, col: {start: 2, end: 0}},
    [Config.COMPONENT_TYPES.Bookshelves]: {row: {start: 4, end: 0}, col: {start: 3, end: 0}},

    //Row5
    [Config.COMPONENT_TYPES.DeskSurfaces]: {row: {start: 5, end: 0}, col: {start: 1, end: 4}},
    // [Config.COMPONENT_TYPES.DrawerCaps]: {row: {start: 5, end: 0}, col: {start: 3, end: 0}},

    //Col4
    [Config.COMPONENT_TYPES.Standards]: {row: {start: 2, end: 5}, col: {start: 4, end: 0}},
};

const adminGrid = {...grid};
adminGrid[Config.COMPONENT_TYPES.TallDrawerCabinets] = {row: {start: 2, end: 0}, col: {start: 1, end: 0}};
adminGrid[Config.COMPONENT_TYPES.ShortDrawerCabinets] = {row: {start: 2, end: 0}, col: {start: 2, end: 0}};
adminGrid[Config.COMPONENT_TYPES.DeskBrackets] = {row: {start: 2, end: 0}, col: {start: 3, end: 0}};


function getPos(id) {
    let pos = grid[id];
    if (Config.isAdmin) {
        pos = adminGrid[id]
    }
    return pos;
}


function ComponentSelection(props) {
    function getCarousel(componentGroup, index) {
        const pos = getPos(componentGroup.id);

        if (!pos) {
            return null
        }

        return (
            <div key={index}
                 className={"list-item"}
                 style={{
                     gridColumnStart: pos.col.start,
                     gridColumnEnd: pos.col.end,
                     gridRowStart: pos.row.start,
                     gridRowEnd: pos.row.end,
                     marginLeft: pos.marginLeft,
                     bottom: pos.bottom,
                 }}>
                <ComponentCarousel componentGroup={componentGroup}
                                   row={pos.row.start}
                                   col={pos.col.start}
                />
            </div>
        )
    }

    return (
        <div className={"component-section-root"}>
            {props.viewModeOn ?
                <ViewpointOptions/>
                :
                <div className={"list-container"}>
                    {Config.componentGroups.map(getCarousel)}
                </div>
            }
        </div>
    )
}


//REDUX
const mapStateToProps = (state) => {
    return {
        viewModeOn: state.appState.viewModeOn
    };
};
export default connect(mapStateToProps)(ComponentSelection)

