const fractions = {
    0: "",
    [1 / 2]: String.fromCharCode(189),
    [1 / 3]: String.fromCharCode(8531),
    [2 / 3]: String.fromCharCode(8532),
    [1 / 4]: String.fromCharCode(188),
    [3 / 4]: String.fromCharCode(190),
    [1 / 5]: String.fromCharCode(8533),
    [2 / 5]: String.fromCharCode(8534),
    [3 / 5]: String.fromCharCode(8535),
    [4 / 5]: String.fromCharCode(8536),
    [1 / 6]: String.fromCharCode(8537),
    [5 / 6]: String.fromCharCode(8538),
    [1 / 8]: String.fromCharCode(8539),
    [3 / 8]: String.fromCharCode(8540),
    [5 / 8]: String.fromCharCode(8541),
    [7 / 8]: String.fromCharCode(8542),
};

export default class Utils {
    static feet2inch(feet) {
        return feet * 12;
    }

    static inch2feet(inch) {
        return inch / 12;
    }

    static convertToFeetInch(value) {
        let feet = Math.floor(value / 12);
        let inches = value % 12;
        return {feet, inches};
    }


    static convertToSizeStr(value, round) {
        if (!value) {
            return value;
        }

        if (isNaN(Number(value))) {
            return null;
        }

        return `${this.convertToFractions(value, round)}"`;

        // Feet, inch version
        // if (value < 12) {
        //     return `${this.convertToFractions(value)}"`;
        // }
        //
        // let {feet, inches} = this.convertToFeetInch(value);
        // return `${feet}' ${this.convertToFractions(inches)}"`;
    }

    static convertToFractions(value, round = false) {
        if (Number.isInteger(value)) {
            return value.toString();
        }
        if (round) {
            value = Math.round(value * 8) / 8; // round it to closes 1/8
        }
        let base = Math.floor(value);

        let f = value - base;


        let fraction = fractions[f];

        if (typeof fraction === "string") {
            return (base + fraction);
        } else {
            return value;
        }
    }
}