import React from 'react';
import './SceneContainer.scss'

export default function SceneContainer(props) {
    return (
        <div id={'scene-container'}>
            <div className={'firm-name'}>
                <span className={'dark'}>ATLAS</span><span className={'light'}> INDUSTRIES</span>
            </div>
            <div className={'app-name'}>
                <span>AS4 Modular Furniture System Builder</span>
            </div>
        </div>
    )
}