import React from "react";
import './InfoSection.scss';
import Typography from "@material-ui/core/Typography";
import {useSelector} from "react-redux";

function InfoItem(props) {
    const {label, value, subfix, prefix} = props;
    if (value) {
        return <div className={'info-item'}>{label}: {prefix}{value} {subfix}</div>
    }
    return null;
}

export default function InfoSection(props) {
    const totalCost = useSelector(state => state.appState.totalCost);
    const totalWidth = useSelector(state => state.appState.totalWidth);

    return (
        <Typography component="div" color={'textSecondary'} className={'info-section'}>
            <InfoItem label={"total width"} value={totalWidth} subfix={'in'}/>
            <InfoItem label={"total cost"} value={totalCost} prefix={'$'}/>
        </Typography>
    )
}
