import React from 'react';
import '../Footer.scss'
import clsx from 'clsx';
import connect from "react-redux/es/connect/connect";
import {Typography} from "@material-ui/core";
import {FooterTypes} from "../../data/FooterData";

function OptionFooter(props) {
    const {footer} = props;

    const getFromFooterData = (footer) => {
        const color = footer.type !== FooterTypes.INFO ? "error" : "initial";
        return <div>
            {footer.data.map((d, index) => {
                let keyValue = footer.isMap && d.key_value;
                return <div key={index}>
                    {keyValue ?
                        <strong>{d.header}: </strong> :
                        <strong style={{display: 'block'}}>{d.header}</strong>
                    }
                    <Typography variant={'body1'} color={color} component={'span'}>{d.body}</Typography>
                </div>
            })}
        </div>;
    };

    const getContent = (index) => {
        let content = index === 1 ? footer.left : footer.right; // description : description2;

        if (typeof content === 'string') {
            return content;
        }

        if (typeof content === 'object') {
            return getFromFooterData(content)
        }
    };

    return (
        <div className={'footer'}>
            <div className={clsx('footer-left', footer.transiting.left && 'fade-out')}>
                <span>
                    {getContent(1)}
                </span>
            </div>

            {props.footerType !== 2 ? null :
                <div className={clsx('footer-right', footer.transiting.right && 'fade-out')}>
                    <span>
                        {getContent(2)}
                    </span>
                </div>
            }
        </div>
    );
}

//REDUX
const mapStateToProps = (state) => {
    return {footer: state.appState.footer};
};
export default connect(mapStateToProps)(OptionFooter)
