import {MOUSE} from 'three';
// import Hammer from 'hammerjs';

const STATE = {NONE: -1, ROTATE: 0, DOLLY: 1, PAN: 2, TOUCH_ROTATE: 3, TOUCH_DOLLY_PAN: 4};

export class CameraInputHandler {
    constructor(domElement, controls) {
        this.controls = controls;
        this.targetElement = domElement;
        this.state = STATE.NONE;
        this.initHammer();
    }

    initHammer() {
        // this.hammer = new Hammer(document.getElementsByTagName('body')[0]);
        //
        // const pinch = new Hammer.Pinch({event: 'pinch'});
        // const twoFingerPan = new Hammer.Pan({
        //     event: 'twoFingerPan',
        //     direction: Hammer.DIRECTION_ALL,
        //     pointers: 2,
        // });
        //
        // // NOTE: recognize the pinch & 2 finger pan together
        // twoFingerPan.recognizeWith(pinch);
        // pinch.recognizeWith(twoFingerPan);
        //
        // this.hammer.add([twoFingerPan, pinch]);
        //
        // // this.hammer.get('pinch').set({enable: true});
        // this.hammer.on('twoFingerPan', this.hammerPan.bind(this));
        // this.hammer.on('hammer.input', this.hammerInput.bind(this));
        // this.hammer.on('pinchstart', this.hammerPinchStart.bind(this));
        // this.hammer.on('pinch', this.hammerPinch.bind(this));
    };

    hammerPinchStart(event) {
        this.state = STATE.NONE;
        this.zoom = this.controls.distTarget;
    };

    hammerPinch(event) {
        this.state = STATE.NONE;
        this.controls.distTarget = this.zoom / event.scale;
    };

    hammerPan(event) {
        this.controls.pan(event.velocityX * 10, event.velocityY * 10);
        // console.log(event.velocityX * 50, event.velocityY * 50);
    };

    hammerInput(event) {
        if (event.pointers.length > 1) {
            console.log('hammerInput', event.type, event.pointers.length);
            this.state = STATE.NONE;
        }
    };

    getDiff(event) {
        this.prevMouse = this.prevMouse || this.downPoint;
        let mouse = {x: event.clientX, y: event.clientY};
        let diff = {
            x: event.clientX - this.prevMouse.x,
            y: event.clientY - this.prevMouse.y
        };
        this.prevMouse = mouse;
        return diff;
    }

    getMousePos(event) {
        return {x: event.clientX, y: event.clientY};
    }

    notForMe(e) {
        // if (this.targetElement !== e.target) {
        //     return true;
        // }
        return false;
    }

    pointerDown(event) {
        if (this.notForMe(event)) return;

        this.state = STATE.ROTATE;
        if (event.button === MOUSE.RIGHT || event.button === MOUSE.LEFT && (event.ctrlKey || event.metaKey || event.shiftKey)) {
            this.state = STATE.PAN;
        }

        this.isDown = true;
        this.downPoint = this.getMousePos(event);
        this.prevMouse = this.getMousePos(event);
        return false;
    }

    pointerMove(event) {
        if (this.notForMe(event)) return;
        if (this.isDown) {
            let diff = this.getDiff(event);

            switch (this.state) {
                case STATE.PAN:
                    this.controls.pan(diff.x / 2, diff.y / 2);
                    break;
                case STATE.ROTATE:
                    this.controls.orbitBy(diff.x / 2, diff.y / 2);
                    break;
            }
        }
        return true;
    }

    pointerUp(e) {
        if (this.notForMe(e)) return;
        this.isDown = false;
        return false;
    }

    gestureWheel(e) {
        // if (this.notForMe(e)) return;
        let f = 10;
        switch (e.deltaMode) {
            case 0:
                this.controls.dolly(e.deltaY * 0.2 * f);
                break;
            case 1:
                this.controls.dolly(e.deltaY * 20 * f);
                break;
            case 2:
                this.controls.dolly(e.deltaY * 40 * f);
                break;
        }
    };

}
