import {Vector3} from "three";

let zeroVector = new Vector3(0, 0, 0);

class Utils3d {
    constructor() {
        this.vpW = 0;
        this.vpH = 0;
    }

    setSize(vpW, vpH) {
        this.vpW = vpW;
        this.vpH = vpH;
    }

    setCamera(cam) {
        this.camera = cam;
    }

    getPointOnScreen(obj, vec = zeroVector, clamp = false) {
        vec = obj.localToWorld(vec.clone());
        vec.project(this.camera);
        vec.x = (vec.x * .5 + .5) * this.vpW;
        vec.y = (vec.y * -.5 + .5) * this.vpH;

        if (clamp) {
            vec.x = Math.max(vec.x, 0);
            vec.y = Math.max(vec.y, 0);
        }

        return vec;
    }
}

export const utils3d = new Utils3d();
