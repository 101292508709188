import React, {useState} from "react";
import TextField from "@material-ui/core/TextField";
import {useSelector} from "react-redux";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import ImageUploader from "./ImageUploader";
import submitDesign from "./Service";


export default function SubmissionForm(props) {
    const metalMaterial = useSelector(state => state.appState.metalMaterial);
    const woodMaterial = useSelector(state => state.appState.woodMaterial);

    const [selectedImage, setSelectedImage] = useState(null);
    const [fullName, setFullName] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(true);

    async function getFileAsDataUrl(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.addEventListener("load", function () {
                resolve(reader.result);
            }, false);

            if (file) {
                reader.readAsDataURL(file);
            }
        })
    }

    async function handleSubmit(event) {
        event.preventDefault();

        if (!validEmail || !email.length) {
            return;
        }

        let additionalData = {
            full_name: fullName,
            email: email,
            zip_code: zipCode,
        }

        if (selectedImage !== null) {
            let base64Data = await getFileAsDataUrl(selectedImage);
            additionalData.user_image = {data: base64Data};
        }

        let submitPromise = submitDesign(event, 'submission', additionalData);
        props.onSubmit(submitPromise);
    }

    function handleCancel() {
        props.onCancel();
    }

    React.useEffect(() => {
        if (!email || email.length < 0) {
            setValidEmail(true);
            return;
        }

        let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (pattern.test(email)) {
            setValidEmail(true);
        } else {
            setValidEmail(false);
        }
    }, [email]);

    //Would you like to upload an image or drawing of your space?
    return (
        <form onSubmit={handleSubmit}>
            <DialogTitle id="form-dialog-title">Submit</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please provide us with the following information
                </DialogContentText>
                <TextField id="full_name" name="full_name" label="Full Name" fullWidth value={fullName}
                           onChange={(e) => setFullName(e.target.value)}/>
                <TextField id="email" name="email" label="Email address" fullWidth value={email}
                           onChange={(e) => setEmail(e.target.value)}
                           error={!validEmail}
                           helperText={!validEmail && "Please enter valid email address."}
                    />
                <TextField id="zip_code" name="zip_code" label="Zip Code" fullWidth value={zipCode} pattern={/\d+/}
                           onChange={(e) => setZipCode(e.target.value)}/>
                <ImageUploader
                    image={selectedImage}
                    onImageSelected={setSelectedImage}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="primary">
                    Cancel
                </Button>
                <Button type={"submit"} color="primary">
                    Next
                </Button>
            </DialogActions>
        </form>
    )
}
