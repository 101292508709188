import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";

export default function SubmissionResult(props) {
    const {purpose, submissionResult} = props;
    let title = "your configuration has been submitted.";
    let body = "you will receive a confirmation email shortly.";

    if (submissionResult.isAxiosError) {
        if (submissionResult.response?.status === 500) {
            title = 'an error occurred on the server.';
        } else {
            title = 'an unknown error occurred during the submission.';
        }
        body = "please try again.";
    } else {
        if (purpose === "download") {
            title = "your configuration has been generated.";
            body = <span>
            <span>please click </span>
            <a target={"_blank"} href={submissionResult.pdf}>here</a>
            <span> to download the PDF document.. </span>
        </span>;
        }
    }

    return (
        <React.Fragment>
            <DialogTitle id="form-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <div style={{lineHeight: "100%"}}>{body}</div>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={props.onClose}>close</Button>
            </DialogActions>
        </React.Fragment>
    )
}
