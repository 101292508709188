import RegionSuggestion from "./RegionSuggestion";
import {Box2, Box3, BoxBufferGeometry, Mesh, MeshBasicMaterial, Vector2, Vector3} from "three";
import LayoutManager from "./LayoutManager";
import {BOUND_SIDES, COMPONENT_TYPES, SNAP_TYPE} from "./Constraint";
import Config, {epsilon} from '../../config/Config'
import {utils3d} from "../Utils3d";

export default class Region {
    constructor(pins, offsetTop = 0, offsetBottom = 0, column, isMain = false) {
        this.defineProperties();
        this.column = column;
        this.pins = pins;
        this.top = offsetTop;
        this.bottom = offsetBottom;

        // this.height = height;
        this.available = true;
        this.nextRegion = null;
        this.prevRegion = null;

        this.isMain = isMain;
        if (isMain) {
            this._createHelper();
        }
    }

    _addPinToSuggestion(pin, suggestion, component, sharedPin) {
        suggestion.addPin(pin, component, this.column.pinPosition(pin), sharedPin);
    }

    _canFitSharedPin(component, subRegion = null) {
        let constraint = component.constraint;
        let suggestion = RegionSuggestion.NotSuggested();

        if (constraint.sharePin) {
            let region = null;
            let pin = null;
            switch (constraint.boundSide) {
                case BOUND_SIDES.TOP:
                    region = this.nextRegion;
                    if (region) {
                        pin = region.firstPin;
                    }
                    break;
                case BOUND_SIDES.BOTTOM:
                    region = this.prevRegion;
                    if (region) {
                        pin = region.lastPin;
                    }
                    break;
            }

            if (region && !region.available && region.component.componentType === constraint.boundComponentType) {
                let fitSize = this._canFitSize(component, subRegion, true);
                if (fitSize.isSuggested()) {
                    suggestion = new RegionSuggestion(this.column, this);
                    this._addPinToSuggestion(pin, suggestion, component);
                }
            }

            if (constraint.forceSharePin) {
                suggestion.setFinal(true);
            }
            return suggestion;
        } else {
            return suggestion;
        }
    }

    _canFitSize(component, subRegion = null, sharedPin = false) {
        let suggestion = new RegionSuggestion(this.column, this);
        let constraint = component.constraint;

        let compDistToTop = component.pin.distToTop + constraint.marginTop;
        let compDistToBottom = component.pin.distToBottom + constraint.marginBottom;
        let compDistToLeft = component.pin.distToLeft;
        let compDistToRight = component.pin.distToRight;

        //bottom is free add some negative margin to open more room
        if (!this.nextRegion) {
            compDistToBottom -= 2;
        }
        //top is free add some negative margin to open more room
        if (!this.prevRegion) {
            compDistToTop -= 2;
        }

        let pins = subRegion ? subRegion.pins : this._pins;

        // console.log(' ')
        // console.log('pin Column index', sharedPin, this.column.colIndex, this.top, this.bottom)
        pins.forEach(pin => {
            let pinDistToTop = pin.distToTop - this.top;
            let pinDistToBottom = this.bottom - pin.distToTop;

            if (compDistToTop <= pinDistToTop && compDistToBottom <= pinDistToBottom) {
                pin.eliminated = false;
                let pinPos = this.column.pinPosition(pin);

                let min = new Vector2(pinPos.x - compDistToLeft + epsilon, pinPos.y - compDistToBottom);
                let max = new Vector2(pinPos.x + compDistToRight - epsilon, pinPos.y + compDistToTop);
                let box = new Box2(min, max);

                if (!sharedPin && component.componentType !== COMPONENT_TYPES.desk && LayoutManager.getInstance().checkComponentIntersection(box, component)) {
                    pin.eliminated = true;
                    // console.log('pin eliminated', this.column.pinPosition(pin).y)
                } else {
                    // console.log('pin _addPinToSuggestion', this.column.pinPosition(pin).y)
                    this._addPinToSuggestion(pin, suggestion, component, sharedPin);
                }
            } else {
                // console.log('pin compDistToTop', compDistToTop, pinDistToTop, compDistToBottom, pinDistToBottom)
            }
        });

        return suggestion;
    }

    canFit(component, subRegion = null) {
        let suggestion;
        if (!this.available) {
            return RegionSuggestion.NotSuggested();
        }

        let constraint = component.constraint;

        if (constraint.sharePin) {
            suggestion = this._canFitSharedPin(component, subRegion);
            if (suggestion.isSuggested() || suggestion.isFinal()) {
                return suggestion;
            }
        }

        if (constraint.snapToBottom === SNAP_TYPE.COLUMN) {
            if (this.nextRegion) {
                return RegionSuggestion.NotSuggested();
            }
        }
        if (constraint.snapToTop === SNAP_TYPE.COLUMN) {
            if (this.prevRegion) {
                return RegionSuggestion.NotSuggested();
            }
        }

        suggestion = this._canFitSize(component, subRegion);

        return suggestion;
    }

    setPrevRegion(region) {
        this.prevRegion = region;
        if (region) {
            region.nextRegion = this;
        }
    }

    setNextRegion(region) {
        this.nextRegion = region;
        if (region) {
            region.prevRegion = this;
        }
    }

    divide(component, suggestion) {
        let constraint = component.constraint;
        let top = suggestion.bestPin.distToTop - component.pin.distToTop - constraint.paddingTop;
        let bottom = suggestion.bestPin.distToTop + component.pin.distToBottom + constraint.paddingBottom;

        let topPins = [];
        let midPins = [suggestion.bestPin];
        let bottomPins = [];

        this._pins.forEach(pin => {
            if (pin.distToTop <= top) {
                topPins.push(pin);
            } else if (pin.distToTop <= bottom) {
                midPins.push(pin)
            } else {
                bottomPins.push(pin);
            }
        });

        let topRegion = new Region(topPins, this.top, top, this.column);
        let midRegion = new Region(midPins, top, bottom, this.column);
        let bottomRegion = new Region(bottomPins, bottom, this.bottom, this.column);

        topRegion.setPrevRegion(this.prevRegion);
        midRegion.setPrevRegion(topRegion);
        midRegion.setNextRegion(bottomRegion);
        bottomRegion.setNextRegion(this.nextRegion);
        return {topRegion, midRegion, bottomRegion};
    }

    merge() {
        let topRegion = this.prevRegion;
        let bottomRegion = this.nextRegion;

        this._pins = topRegion._pins
            .concat(this._pins)
            .concat(bottomRegion._pins);

        this.top = topRegion.top;
        this.bottom = bottomRegion.bottom;

        this.setPrevRegion(topRegion.prevRegion);
        this.setNextRegion(bottomRegion.nextRegion);
    }

    setComponent(component) {
        this.component = component;
        this.available = true;
        if (component) {
            this.available = false;
            component.setRegion(this);
        }
    }

    dispose() {
        this._deleteHelper();
    }

    _getBox(thickness = 0) {
        let bounds = this.column._bounds();
        if (!bounds) {
            return null;
        }
        let {top, bottom, left, right} = bounds;

        //this.top is distance to the top of column.
        let v1 = new Vector3(left, top - this.bottom, 0);
        let v2 = new Vector3(right, top - this.top, thickness);

        let box;
        if (thickness) {
            box = new Box3();
            box.setFromPoints([v1, v2]);
        } else {
            box = new Box2();
            box.set(new Vector2(v1.x, v1.y), new Vector2(v2.x, v2.y))
        }
        return box;
    }

    //DEBUGGING
    _deleteHelper() {
        if (this.helper) {
            LayoutManager.getInstance().regionRoot.remove(this.helper);
            if (this.isMain) {
                let id = 'col-' + this.column.colIndex;
                let el = document.getElementById(id)
                if (el) {
                    el.remove();
                }
            }
        }
    }

    _createHelper(deleteOnly) {
        if (!this.isMain && (!Config.debug || !Config.debug.regionHelper)) {
            return
        }
        this._deleteHelper();
        if (deleteOnly) {
            return;
        }

        let thickness = this.isMain ? 2.5 : 20;
        let box = this._getBox(thickness);

        let color = this.isMain ? 0x00ff00 : this.available ? 0x0000ff : 0xff0000;
        let opacity = this.isMain ? 0 : this.available ? 0.1 : 0.3;

        let material = new MeshBasicMaterial({color: color, transparent: true, opacity: opacity});
        let size = new Vector3(),
            center = new Vector3();
        box.getSize(size);
        box.getCenter(center);

        let geometry = new BoxBufferGeometry(size.x, size.y, size.z);
        this.helper = new Mesh(geometry, material);
        this.helper.position.copy(center);
        this.helper.name = 'Region Helper';
        this.helper.userData = {bounds: this.column._bounds(false)};

        this._getHelperText(center);

        window.helper = this.helper;
        LayoutManager.getInstance().regionRoot.add(this.helper);
    }

    _getHelperText(center) {
        if (!this.isMain || !Config.debug || !Config.debug.regionHelper) {
            return
        }

        let id = 'col-' + this.column.colIndex;
        let el = document.getElementById(id)
        if (!el) {
            el = document.createElement('div');
            el.id = id;
            el.style.position = 'absolute';
            el.style.zIndex = '123123';
            document.body.append(el);
        }
        let pos = utils3d.getPointOnScreen(this.helper, center);
        el.style.top = pos.y + 'px';
        el.style.left = pos.x + 'px';
        el.innerText = id;
    }

    defineProperties() {
        Object.defineProperties(this,
            {
                lastPin: {
                    get: () => this._pins[this._pins.length - 1]
                },
                firstPin: {
                    get: () => this._pins[0]
                },
                pins: {
                    get: () => this._pins,
                    set: (pins) => {
                        this._pins = pins;
                        this._pins.sort((p1, p2) => p1.distToTop - p2.distToTop);
                    }
                },
                nextPaddingTop: {
                    get: () => {
                        if (this.nextRegion && this.nextRegion.component) {
                            return this.nextRegion.component.constraint.paddingTop;
                        } else {
                            return 0;
                        }
                    }
                },
                prevPaddingBottom: {
                    get: () => {
                        if (this.prevRegion && this.prevRegion.component) {
                            return this.prevRegion.component.constraint.paddingBottom;
                        } else {
                            return 0;
                        }
                    }
                }

            }
        )
    }
}
