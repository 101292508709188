import {FormHelperText, Grid} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import TextField from "@material-ui/core/TextField/TextField";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Utils from "data/Utils";
import RemoveIcon from '@material-ui/icons/Remove';

const useStyles = makeStyles(theme => ({
    helperText: {
        marginTop: '0px'
    }
}));

export default function DimensionInput(props) {
    const classes = useStyles();
    const [error, setError] = React.useState(false);
    const [sizes, setSizes] = React.useState({feet: 0, inch: 0});
    const {value} = props;

    React.useEffect(() => {
        let {feet, inches} = Utils.convertToFeetInch(value);
        setSizes({feet: feet, inch: inches})
    }, []);

    const onChange = (type, event) => {
        let newSizes = {...sizes, [type]: Number(event.target.value)};
        setSizes(newSizes);

        let totalSize = Utils.feet2inch(newSizes.feet) + newSizes.inch;

        if (totalSize > props.maxSize) {
            setError(true);
        } else {
            props.onChange(totalSize);
            setError(false);
        }
    };

    const handleFocus = (event) => event.target.select();
    return (
        <div>
            <Grid container spacing={2} alignItems="center" wrap="nowrap">
                <Grid item xs={4}>
                    <InputLabel htmlFor={props.id + "-feet-input"}>{props.label}</InputLabel>
                </Grid>
                <Grid item container alignItems={'center'} justify={'center'} xs={8}>
                    <TextField id={props.id + "-feet-input"} margin="dense" size="small" type="number"
                               InputProps={{endAdornment: <InputAdornment position="end">feet</InputAdornment>}}
                               inputProps={{min: 0, max: props.maxSize, step: 1}}
                               error={error}
                               onChange={(event) => onChange('feet', event)}
                               disabled={props.disabled}
                               value={sizes.feet}
                               onFocus={handleFocus}
                    />
                    <RemoveIcon fontSize={'small'} style={{padding: '0 5px', margin: '0 5px'}}/>
                    <TextField id={props.id + "-inch-input"} margin="dense" size="small" type="number"
                               InputProps={{endAdornment: <InputAdornment position="end">inches</InputAdornment>}}
                               inputProps={{min: 0, max: Utils.feet2inch(1), step: 0.25}}
                               error={error}
                               onChange={(event) => onChange('inch', event)}
                               disabled={props.disabled}
                               value={sizes.inch}
                               onFocus={handleFocus}
                    />
                </Grid>
            </Grid>
            {error ?
                <Grid container spacing={0} justify="flex-end" alignItems="center" wrap="nowrap">
                    <Grid item xs={7}>
                        <FormHelperText className={classes.helperText} error margin="dense">
                            {"Maximum size is " + Utils.convertToSizeStr(props.maxSize)}
                        </FormHelperText>
                    </Grid>
                </Grid>
                : null
            }
        </div>

    )
}

