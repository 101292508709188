import {combineReducers} from 'redux'

import {componentsReducer} from './reducers'
import appReducer from "./appReducer";

const rootReducer = combineReducers({
    appState: appReducer,
    componentsState: componentsReducer,
});

export default rootReducer;
