import React from 'react';
import Config from "../../../config/Config";
import './ComponentCarousel.scss'
import {Box, Slide} from "@material-ui/core";
import clsx from 'clsx';
import FooterData from "data/FooterData";
import AspectRatio from "../../helper/AspectRatio";
import connect from "react-redux/es/connect/connect";
import {
    componentPicked,
    setFooter,
    setFooterLeft,
    setFooterRight,
    setFooterRightWithTimeout
} from "../../../store/reducers/appReducer";
import scene3d from "../../../threed/Scene3d";
import {inputHandler} from "../../../workspace/InputHandler";
import {useDispatch} from "react-redux";

const handler = (target, item, dispatch) => {
    let down = false;
    let inProgress = false; // Until one component is successfully acquired, don't let another to start.

    return {
        pointerDown: event => {
            if (event.target !== target) {
                return false;
            }

            if (inProgress) {
                return false;
            }
            inProgress = true;
            down = true;
            dispatch(componentPicked(item))
            scene3d.addModel(event, item).finally(
                _ => inProgress = false
            )
            return true;
        },
        pointerUp: event => {
            down = false;
            // console.log('eventttttt pointerUp');
            return false;
        },
        pointerMove:
            event => {
                if (event.target !== target) {
                    return false;
                }
                if (down) {
                    // event.preventDefault();
                    // console.log('eventttttt pointerMove');
                    // scene3d.addModel(event, item);
                    down = false;
                    return true;
                }
                return false;
            },
    }
};

function CarouselImage(props) {
    const {item, preserveAspect} = props;
    let imageDom;
    let _handler;
    const dispatch = useDispatch();

    React.useEffect(
        () => {
            _handler = handler(imageDom, item, dispatch);
            inputHandler.addHandler(_handler, 10);
        },
        [],
        () => inputHandler.removeHandler(_handler)
    );

    const ImageElement = _ => {
        return <img src={item.url} alt={'component'}
                    draggable={false}
                    className={'carousel-image'}
                    ref={mount => imageDom = mount}/>

    };

    if (preserveAspect) {
        return (
            <AspectRatio width={54} height={1000} padding={'0 0px'}>
                <ImageElement/>
            </AspectRatio>
        )
    } else {
        return (
            <div className={'wrapper'}>
                <ImageElement/>
            </div>
        )
    }
}

function ComponentCarousel(props) {
    const {componentGroup, dispatch, selectedStandard, wallDimensions} = props;

    const [slideIndex, setSlideIndex] = React.useState(0);
    const [direction, setDirection] = React.useState(+1);
    const {id, title, description} = componentGroup;

    const filterOptions = {
        maxHeight: wallDimensions.height,
        maxWidth: wallDimensions.width,
    };

    if (componentGroup.isStandard()) {
        filterOptions["type"] = selectedStandard.type
    }

    const filteredComponents = componentGroup.filteredItems(filterOptions);

    React.useEffect(() => {
        let index = 0;

        //Activate the selected standard by default.
        if (componentGroup.isStandard()) {
            index = filteredComponents.findIndex((component) => component.url === selectedStandard.url);
        }

        setSlideIndex(index);
    }, []);


    function setSlide(index) {
        if (slideIndex === index) {
            return;
        }

        setDirection(slideIndex - index);

        let next = (index) % filteredComponents.length;
        if (next < 0) {
            next = filteredComponents.length - 1;
        }
        setSlideIndex(next);
        setDescription(next, true);
    }

    function changeIndex(val) {
        setSlide(slideIndex + val);
    }

    function setDescription(index = slideIndex, delay = false) {
        let item = filteredComponents[index];
        let left = new FooterData(item.title || title, item.description || description);
        let right = new FooterData();
        right.setIsMap(true);

        let itemProps = item.props;
        for (let key in itemProps) {
            right.append(key, itemProps[key]);
        }

        dispatch(setFooterLeft(left.toJson()));
        if (delay) {
            dispatch(setFooterRightWithTimeout(right.toJson(), Config.transition));
        } else {
            dispatch(setFooterRight(right.toJson()));
        }
    }

    return (
        <Box width={'100%'} height={'100%'}
             className={clsx("carousel-container",
                 filteredComponents.length <= 1 && 'single-item',
                 'row' + props.row,
                 'col' + props.col,
             )}
             onMouseEnter={() => setDescription()}
             onMouseLeave={() => dispatch(setFooter(null))}
        >
            <div className={'arrow prev-arrow'} onClick={() => changeIndex(-1)}/>
            <div className={'arrow next-arrow'} onClick={() => changeIndex(+1)}/>
            <div className={'dots'}>
                {filteredComponents.map((_, index) => {
                    let classes = clsx('dot', index === slideIndex && 'active');

                    return <div key={index} className={classes} onClick={() => setSlide(index)}/>
                })}
            </div>

            <div className={'carousel'}>
                {filteredComponents.map((item, index) => {
                    let slideIn = slideIndex === index;
                    let dirIn = 'right';
                    let dirOut = 'left';
                    if (direction < 0) {
                        dirIn = 'left';
                        dirOut = 'right';
                    }
                    return (<Slide in={slideIn}
                                   timeout={Config.transition}
                                   key={index}
                                   direction={slideIn ? dirIn : dirOut}
                                   mountOnEnter
                                   unmountOnExit
                        >
                            <div className={"image-wrapper"}>
                                <CarouselImage id={id} title={title} item={item}
                                               preserveAspect={componentGroup.isStandard()}/>
                            </div>
                        </Slide>
                    )
                })}
            </div>
        </Box>
    )

}


//REDUX
const mapStateToProps = (state) => {
    return {
        selectedStandard: state.appState.selectedStandard,
        wallDimensions: state.appState.wallDimensions

    };
};
export default connect(mapStateToProps)(ComponentCarousel)
