import React from 'react';

export default function Copyright() {
    const getCopyrightText = () => {
        return "© 2020 Atlas Industries";
    };
    return (
        <div className={'copyright'}>
            {getCopyrightText()}
        </div>
    )
}
