import React from "react";
import {Button} from "@material-ui/core";
import ControlCameraIcon from '@material-ui/icons/ControlCamera';
import clsx from 'clsx';

const viewButtons = [
    {column: 2, row: 1, name: "Top"},
    {column: 1, row: 2, name: "Left"},
    {
        notButton: true,
        column: 2, row: 2,
        name: <ControlCameraIcon fontSize={'large'} size={'large'}/>,
    },
    {column: 3, row: 2, name: "Right"},
    {column: 2, row: 3, name: "Front"},
    // {column: 2, row: 4, name: "Back"},
];

export default function ViewpointOptions(props) {
    const ViewButton = (props) => {
        let buttonClass = clsx(viewSide === props.name && 'selected');
        return (
            <div className={'list-item'} style={{gridColumn: props.column, gridRow: props.row}}>
                {props.notButton
                    ? <div className={buttonClass}>{props.name}</div>
                    : <Button className={buttonClass}
                              value={props.name}
                              variant={"outlined"}
                              onClick={() => handleViewSide(props.name)}
                    >
                        {props.name}
                    </Button>
                }
            </div>
        )
    };

    const [viewSide, setViewSide] = React.useState('Front');
    const handleViewSide = (newViewSide) => {
        setViewSide(newViewSide);
    };

    return (<div className={'view-mode'}>
            <div className={"view-list-container"}>
                {viewButtons.map(button => <ViewButton key={button.name} {...button}/>)}
            </div>
        </div>
    );
}