import React from "react";
import {AppBar, Button, Dialog, IconButton, Slide, Toolbar} from "@material-ui/core";
import {setViewMode} from "../store/reducers/appReducer";
import CloseIcon from '@material-ui/icons/Close'
import connect from "react-redux/es/connect/connect";
import "./ViewMode.scss";
import scene3d from "../threed/Scene3d";

function ViewMode(props) {
    const {dispatch, viewModeOn} = props;

    const handleClose = () => {
        dispatch(setViewMode(false));
    };

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    return (
        <Dialog fullScreen open={viewModeOn} onClose={handleClose} keepMounted={true}>
            <div className={'view-mode-container'}>
                <AppBar>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon/>
                        </IconButton>

                        <Button color={'inherit'} onClick={() => scene3d.setViewPoint('top')}>Top</Button>
                        <Button color={'inherit'} onClick={() => scene3d.setViewPoint('front')}>Front</Button>
                        <Button color={'inherit'} onClick={() => scene3d.setViewPoint('left')}>Left</Button>
                        <Button color={'inherit'} onClick={() => scene3d.setViewPoint('right')}>Right</Button>

                    </Toolbar>
                </AppBar>
                {/*This is to fill up empty space occupied by AppBar*/}
                <Toolbar/>
                <div id={'viewmode-content'}/>
            </div>
        </Dialog>
    );
}

//REDUX
const mapStateToProps = (state) => {
    return {viewModeOn: state.appState.viewModeOn};
};
export default connect(mapStateToProps)(ViewMode)
