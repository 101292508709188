import {MTLLoader} from 'three/examples/jsm/loaders/MTLLoader'
import {OBJLoader} from 'three/examples/jsm/loaders/OBJLoader'
import {TDSLoader} from 'three/examples/jsm/loaders/TDSLoader'
import {ColladaLoader} from 'three/examples/jsm/loaders/ColladaLoader'
import {Cache, ObjectLoader} from "three";


Cache.enabled = true;

export default class Loader {
    constructor() {
        this.assets = new Map();
    }

    getObjLoader() {
        if (!this.objLoader) {
            this.objLoader = new OBJLoader();
            this.objLoader.setMaterials(Loader.defaultMaterials);
        }

        return this.objLoader;
    }

    getDaeLoader() {
        if (!this.daeLoader) {
            this.daeLoader = new ColladaLoader();
        }
        return this.daeLoader;
    }


    get3DsLoader() {
        if (!this.tdsLoader) {
            this.tdsLoader = new TDSLoader();
        }
        return this.tdsLoader;
    }

    getMeshLoader() {
        if (!this.meshLoader) {
            this.meshLoader = new ObjectLoader();
        }
        return this.meshLoader;
    }

    getMtlLoader() {
        if (!this.mtlLoader) {
            this.mtlLoader = new MTLLoader();
        }
        return this.mtlLoader;
    }


    _onError = callback => error => {
        console.log(JSON.stringify(error));
        if (callback) {
            callback(error);
        }
    };

    _onProgress = (xhr) => {
        // console.log(JSON.stringify(xhr));
    };

    loadMtl(url = Loader.materialUrl) {
        let self = this;
        let mtlLoader = this.getMtlLoader();
        return new Promise(function (resolve, reject) {
            mtlLoader.load(url,
                mtrls => { // onLoad
                    mtrls.preload();

                    self.assets.set(url, mtrls);
                    resolve(mtrls);
                },
                self._onProgress,
                self._onError(reject)
            )
        });
    }

    loadObj(url, materials) {
        let self = this;
        let objLoader = this.getObjLoader();
        if (materials) {
            objLoader.setMaterials(materials);
        }
        return new Promise(function (resolve, reject) {
            objLoader.load(url,
                (object) => {
                    self.assets.set(url, object);
                    // console.log('loadObj:', object);
                    resolve(object);
                },
                self._onProgress,
                self._onError(reject)
            );
        });
    };

    load3ds(url) {
        let self = this;
        let loader = this.get3DsLoader();
        return new Promise(function (resolve, reject) {
            loader.load(url,
                (object) => {
                    self.assets.set(url, object);
                    // console.log('loadObj:', object);
                    resolve(object);
                },
                self._onProgress,
                self._onError(reject)
            );
        });
    };

    loadDae(url) {
        let self = this;
        let loader = this.getDaeLoader();
        return new Promise(function (resolve, reject) {
            loader.load(url,
                (object) => {
                    self.assets.set(url, object);
                    // console.log('loadDae:', object);
                    resolve(object);
                },
                self._onProgress,
                self._onError(reject)
            );
        });
    };

    loadMesh(url) {
        let self = this;
        let meshLoader = this.getMeshLoader();
        return new Promise(function (resolve, reject) {
            meshLoader.load(url,
                (object) => {
                    self.assets.set(url, object);
                    // console.log('loadMesh:', object);
                    resolve(object);
                },
                self._onProgress,
                self._onError(reject)
            );
        });
    }

    loadObjMtl(objUrl, mtlUrl) {
        let self = this;
        let objLoader = this.getObjLoader();
        return this.loadMtl(mtlUrl).then(
            function (materials) {
                objLoader.setMaterials(materials);
                return self.loadObj(objUrl);
            }
        );
    };
}

Loader.materialUrl = `/images/components/3d/materials/materials.mtl`;
Loader.defaultMaterials = new Loader().loadMtl();
