export const FooterTypes = {INFO: 0, WARNING: 1, ERROR: 2};
export default class FooterData {
    constructor(header = null, body = null) {
        this.data = [];
        this.type = FooterTypes.INFO;
        if (header || body) {
            this.append(header, body);
        }
    }

    toJson() {
        return {
            data: this.data,
            type: this.type,
            isMap: this.isMap(),
            delay: this.delay
        }
    }

    setDelay(time) {
        this.delay = time;
    }

    append(header = null, body = null) {
        let key_value = !!(header && body);
        this.data.push({header: header, body: body, key_value});
        return this;
    }

    isMap() {
        if (typeof this._isMap !== "undefined") {
            return this._isMap;
        } else {
            return this.data.length > 1;
        }
    }

    setIsMap(val) {
        this._isMap = val;
        return this;
    }

    setType(type) {
        this.type = type;
    }
}
