import React from "react";
import Tool from "./Tool";
import connect from "react-redux/es/connect/connect";
import {Slide} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";

function ToolGroup(props) {
    let {tools, groupName, expand} = props;
    const {selectedTool, dispatch} = props;
    const [mouseOverButton, setMouseOverButton] = React.useState(false);
    const [mouseOverContent, setMouseOverContent] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    function setTool(name, value) {
        props.toolSelected(name, value);
    }

    function toolSelected(tool) {
        let value = tool.id;
        if (selectedTool !== value) {
            setTool(groupName, tool);
            return;
        }
        if (tool.toggle) {
            setTool(groupName, null)
        }
    }

    function getTool(tool) {
        if (tool) {
            if (tool.id === "Separator") {
                return <Divider key={'separator'} orientation="vertical"
                                style={{
                                    height: "40px",
                                    width: "2px",
                                    background: "#0000008A"
                                }}/>
            }
            return <Tool selected={selectedTool === tool.id}
                         onSelected={() => toolSelected(tool)}
                         key={tool.id}
                         tool={tool}
                         group={groupName}/>
        } else {
            return <Tool empty/>
        }
    }

    function handleMouseOver(element, over) {
        let button = mouseOverButton;
        let content = mouseOverContent;
        if (element === 'button') {
            button = over;
        } else {
            content = mouseOverContent;
        }

        setMouseOverButton(button);
        setMouseOverContent(content);

        if (open) {
            setOpen(button || content);
        } else {
            setOpen(button);
        }
        // setOpen(true);
    }

    if (expand) {

        return (
            <div className={'popup-container'}
                 onMouseEnter={() => handleMouseOver('button', true)}
                 onMouseLeave={() => handleMouseOver('button', false)}
            >
                <Slide in={open} direction={'up'}>
                    <div className={'toolbar-popup-container'}
                         onMouseEnter={() => handleMouseOver('content', true)}
                         onMouseLeave={() => handleMouseOver('content', false)}
                    >
                        <div className={'toolbar toolbar-popup'}>
                            <ToolGroup {...props} expand={false}/>
                        </div>
                    </div>
                </Slide>

                <div>
                    {getTool(tools.find(t => t.id === selectedTool))}
                </div>
            </div>
        );
    } else {
        return (tools.map(getTool))
    }
}

//REDUX
const mapStateToProps = (state, ownProps) => {
    const {groupName} = ownProps;
    return {selectedTool: state.appState[groupName]};
};
export default connect(mapStateToProps)(ToolGroup)
