import Region from "./Region";
import Pin, {PIN_TYPE} from "./Pin";
import RegionSuggestion from "./RegionSuggestion";

const MIN_COLUMN_WIDTH = 22;
const MAX_COLUMN_WIDTH = 32;
const CENTER_COLUMN_WIDTH = (MAX_COLUMN_WIDTH + MIN_COLUMN_WIDTH) / 2;

let colIndex = 1;
export default class Column {
    constructor(left = null, right = null) {
        this.divide = this.divide.bind(this);
        this.defineProperties();

        this.regions = [];
        this.leftStandard = left;
        this.rightStandard = right;
        this.height = 0;
        this.width = 0;
        this.nextColumn = null;
        this.prevColumn = null;
        this.colIndex = colIndex++;
    }

    bestPlaceForStandard(standard) {
        if (!this.hasBothStandards()) {
            let bounds = standard.bounds();

            let refStandard = this.rightStandard;
            let refDirection = -1;
            if (this.leftStandard) {
                refStandard = this.leftStandard;
                refDirection = +1;
            }

            let refPos = refStandard.position.clone();
            let suggestedPosition = refPos.clone();

            //Horizontal position
            refPos.x += CENTER_COLUMN_WIDTH * refDirection;
            if ((refPos.x - bounds.hCenter) * refDirection < 0) {
                suggestedPosition.x += (MAX_COLUMN_WIDTH + bounds.width) * refDirection;
            } else {
                suggestedPosition.x += (MIN_COLUMN_WIDTH + bounds.width) * refDirection;
            }

            //Vertical position
            let refBounds = refStandard.bounds();
            if (Math.abs(bounds.top - refBounds.top) < Math.abs(bounds.bottom - refBounds.bottom)) {
                suggestedPosition.y = refBounds.top - bounds.height;
            } else {
                suggestedPosition.y = refPos.y;
            }

            this.lastSuggestedPos = suggestedPosition;
        } else {
            this.lastSuggestedPos = null;
        }
        return this.lastSuggestedPos;
    }

    hasBothStandards() {
        return (this.leftStandard && this.rightStandard);
    }

    removeStandard(standard) {
        let removed = false;
        if (this.leftStandard === standard) {
            this.leftStandard = null;
            removed = true;
        }

        if (this.rightStandard === standard) {
            this.rightStandard = null;
            removed = true;
        }

        if (removed) {
            this.width = 0;
            this.disposeMainRegion();
        }
    }

    addStandard(standard, position, createNewColumn = true) {
        let newColumn = null;
        if (position) {
            standard.position.x = position.x;
            standard.position.y = position.y;
        }

        if (this.leftStandard) {
            this.rightStandard = standard;
            if (createNewColumn) {
                newColumn = new Column(standard, null);
                newColumn.setPrevColumn(this);
            }
        } else {
            this.leftStandard = standard;
            if (createNewColumn) {
                newColumn = new Column(null, standard);
                newColumn.setNextColumn(this);
            }
        }

        this._print();
        return newColumn;
    }

    isEmpty() {
        return this.regions.length <= 1;
    }

    setPrevColumn(column) {
        if (this.prevColumn) {
            this.prevColumn.nextColumn = null;
        }
        this.prevColumn = column;
        if (column) {
            column.nextColumn = this;
        }
    }

    setNextColumn(column) {
        if (this.nextColumn) {
            this.nextColumn.prevColumn = null;
        }
        this.nextColumn = column;
        if (column) {
            column.prevColumn = this;
        }
    }

    dispose() {
        this.leftStandard = null;
        this.rightStandard = null;
        this.regions = []
        this.nextColumn = null;
        this.prevColumn = null;
        this.disposeMainRegion();
    }

    disposeMainRegion() {
        if (this.mainRegion) {
            this.mainRegion.dispose();
            this.mainRegion = null;
        }
    }

    _getSuggestions(component, subRegion = null) {
        let suggestions = [];

        this.regions.forEach(region => {
            let regionSuggestion = region.canFit(component, subRegion);
            if (regionSuggestion.isSuggested()) {
                regionSuggestion.setNextSuggestion(subRegion);
                suggestions.push(regionSuggestion);
            }
        });
        return suggestions;
    }

    canMount(component, subRegion = null) {
        if (component.constraint.mountType === PIN_TYPE.FLOAT) {
            return this.canMountSpan(component);
        } else {
            let suggestions = this._getSuggestions(component, subRegion);
            return RegionSuggestion.best(suggestions);
        }
    }

    canMountSpan(component) {
        console.log('CMS Can Mount span3:', this.colIndex)
        console.log('   CMS component.width:', component.width)

        let column = this;
        let totalWidth = 0;
        let componentEffectiveWidth = (component.width - component.constraint.marginLeftRight);

        let suggestions = [null];
        let nextSuggestions = [];

        do {
            nextSuggestions = suggestions.flatMap(
                suggestion => {
                    return column._getSuggestions(component, suggestion);
                },
                RegionSuggestion.NotSuggested()
            );

            if (nextSuggestions.length) {
                totalWidth += column.width;
                suggestions = nextSuggestions;
            }
            column = column.prevColumn;

        } while (nextSuggestions.length > 0 && column
            // && (totalWidth <= componentEffectiveWidth && !floatPin)
            );

        console.log('   CMS total length', totalWidth, column ? "CCCCC" : " :(((((");
        if (totalWidth < componentEffectiveWidth) {
            console.log('   CMS not suggested');
            console.log('   CMS');
            return RegionSuggestion.NotSuggested();
        } else {
            let best = RegionSuggestion.best(suggestions);
            if (!best.freeOnRightSide() && !best.freeOnLeftSide() && totalWidth < component.width) {
                return RegionSuggestion.NotSuggested();
            }


            //TODO: this may not be true. Best suggestion might not be using all the columns.
            // But as this is only used by desk, we can safely ignore it.
            best.width = totalWidth;
            console.log('   CMS best. Col:', best.column.colIndex, best);
            console.log('   CMS');
            return best;
        }
    }

    addComponent(component, suggestion = null) {
        suggestion = suggestion || this.canMount(component);
        if (suggestion && suggestion.isSuggested()) {
            let valid = this._mount(component, suggestion);
            if(valid){
                this._print();
            }
            return valid;
        }
    }

    removeComponent(component) {
        component.column = null;

        component.regions.forEach(region => {
            if (region) {
                let column = region.column;
                column.removeRegion(region.prevRegion);
                column.removeRegion(region.nextRegion);

                region.merge();
                region.setComponent(null);

                column._print();
            }
        });
        component.setRegion(null);
    }

    _print() {
        function round(val) {
            return Math.round(val * 100) / 100.0;
        }

        this.regions.sort((r1, r2) => {
            return r1.top - r2.top;
        });

        let s = '';
        this.regions.forEach(region => {
            s += `|${region.available ? '.' : ':'}${round(region.top)}/${round(region.bottom)}|`;
        });
        console.log('Regions:', s);
        this.updateHelpers();
    }

    pinPosition(pin) {
        let leftPos = this.leftStandard.pinPosition(pin);
        let rightPos = this.rightStandard.pinPosition(pin);
        leftPos.y = Math.min(leftPos.y, rightPos.y);
        return leftPos;
    }

    pinPositions(type = PIN_TYPE.STANDARD) {
        if (this.pins && this.hasBothStandards()) {
            return this.pins
                .filter(p => type.compatible(p.type))
                .map(this.pinPosition.bind(this));
        } else {
            return [];
        }
    }

    _mount(component, suggestion) {
        function shouldMount(bnd, sug) {
            return (bnd.right > sug.left && bnd.left < sug.right);
        }

        const rightSideFree = suggestion.freeOnRightSide();
        const leftSideFree = suggestion.freeOnLeftSide();

        console.log('rightSideFree:', rightSideFree);
        console.log('leftSideFree:', leftSideFree);

        let mountPosition = this.pinPosition(suggestion.bestPin);

        // TODO: Freemargin can be set liek this:  suggestion.width + component.width;
        // This will let component move within the column.
        let valid = component.mountAt(mountPosition, suggestion.width, rightSideFree, leftSideFree);

        if (component.constraint.mountType === PIN_TYPE.FLOAT) {
            return valid;
        }

        let bounds = component.bounds();
        let s = suggestion;

        if (shouldMount(bounds, suggestion)) {
            this.divide(component, suggestion);
        }

        while (s.prevSuggestion) {
            if (shouldMount(bounds, s.prevSuggestion)) {
                s.prevSuggestion.setBestPinFrom(s);
                s = s.prevSuggestion;
                s.column.divide(component, s);
            } else {
                s = s.prevSuggestion;
            }
        }

        s = suggestion;
        while (s.nextSuggestion) {
            if (shouldMount(bounds, s.nextSuggestion)) {
                s.nextSuggestion.setBestPinFrom(s);
                s = s.nextSuggestion;
                s.column.divide(component, s);
            } else {
                s = s.nextSuggestion;
            }
        }

        return valid;
    }

    divide(component, suggestion) {
        let parentRegion = suggestion.parentRegion;

        let {topRegion, midRegion, bottomRegion} = parentRegion.divide(component, suggestion);
        midRegion.setComponent(component);
        component.column = this;

        this.removeRegion(parentRegion);
        this.regions.push(topRegion);
        this.regions.push(midRegion);
        this.regions.push(bottomRegion);
        this.updateHelpers();
    }

    removeRegion(region) {
        let index = this.regions.indexOf(region);
        if (index >= 0) {
            region._deleteHelper();
            this.regions.splice(index, 1);
        }
    }

    refresh() {
        this.updateHelpers(true);
        if (this.hasBothStandards()) {

            let shorter = this.leftStandard;
            if (this.leftStandard.pinCount > this.rightStandard.pinCount) {
                shorter = this.rightStandard
            }

            this.pinCount = shorter.pinCount;
            this.height = shorter.height;
            this.width = this.rightStandard.position.x - this.leftStandard.position.x;

            this._createPins(shorter);
            if (this.prevColumn) {
                this.prevColumn.refresh();
            }

            if (this.regions.length <= 1) {
                let region = new Region(this.pins, 0, shorter.height, this);
                this.regions = [region];

                if (!this.mainRegion) {
                    //Main region. This covers the whole column. It's utilised for detecting the mouse hover events on columns.
                    this.mainRegion = new Region(this.pins, 0, shorter.height, this, true);
                }
            }
        } else {
            this.regions = [];
            this.pins = [];
        }
        this.updateHelpers();
    }

    _createPins(standard) {
        const PIN_SPACING = 2;
        this.pins = [];
        let distToTop = standard.pin.distToTop;
        let distToBottom = standard.pin.distToBottom;
        let distToBack = standard.pin.distToBack;
        let distToLeft;
        for (let i = 0; i < standard.pinCount; i++) {
            let top = distToTop + i * PIN_SPACING;
            let bottom = distToBottom - i * PIN_SPACING;
            let pin = new Pin(this, top, bottom, distToBack);
            this.pins.push(pin);

            //TV components are either:
            // - centered on the column
            distToLeft = this.width / 2;
            let columnCenterPin = new Pin(this, top, bottom, distToBack, -distToLeft, PIN_TYPE.CENTER);
            this.pins.push(columnCenterPin);

            //Or centered between 2 columns
            if (this.nextColumn) {
                //If 2 adjacent columns are same width, the center will overlap with another standard pin.
                //So we skip this.
                if (this.width !== this.nextColumn.width) {
                    distToLeft = (this.width + this.nextColumn.width) / 2;
                    let twoColumnsCenterPin = new Pin(this, top, bottom, distToBack, -distToLeft, PIN_TYPE.CENTER);
                    this.pins.push(twoColumnsCenterPin);
                }
            } else {
                distToLeft = this.width;
                //Use the last standard as a center pin mount.
                let lastStandardCenterPin = new Pin(this, top, bottom, distToBack, -distToLeft, PIN_TYPE.CENTER);
                this.pins.push(lastStandardCenterPin);
            }
        }
    }

    defineProperties() {
        Object.defineProperties(this, {
            leftStandard: {
                get: () => {
                    return this._leftStandard;
                },
                set: (standard) => {
                    if (this._leftStandard) {
                        this._leftStandard.rightColumn = null;
                    }

                    this._leftStandard = standard;

                    if (standard) {
                        standard.rightColumn = this;
                    }
                    this.refresh();
                }
            },
            rightStandard: {
                get: () => {
                    return this._rightStandard;
                },
                set: (standard) => {
                    if (this._rightStandard) {
                        this._rightStandard.leftColumn = null;
                    }

                    this._rightStandard = standard;

                    if (standard) {
                        standard.leftColumn = this;
                    }
                    this.refresh();
                }
            }
        })
    }

    _bounds(rectangle = true) {
        if (!this.leftStandard || !this.rightStandard) {
            return false;
        }

        if (rectangle) {
            let shorter = this.leftStandard;
            if (this.leftStandard.pinCount > this.rightStandard.pinCount) {
                shorter = this.rightStandard
            }

            return {
                top: shorter.bounds().top,
                bottom: shorter.bounds().bottom,
                left: this.leftStandard.bounds().right,
                right: this.rightStandard.bounds().left,
            }
        } else {
            let left = this.leftStandard.bounds().right;
            let right = this.rightStandard.bounds().left;

            return {
                leftTop: this.leftStandard.bounds().top,
                rightTop: this.rightStandard.bounds().top,
                leftBottom: this.leftStandard.bounds().bottom,
                rightBottom: this.rightStandard.bounds().bottom,
                left,
                right,
            }
        }
    }

    updateHelpers(deleteOnly) {
        if (this.pins) {
            this.pins.forEach(pin => {
                pin._createHelper(this, deleteOnly);
            });
        }

        this.regions.forEach(r => {
            r._createHelper(deleteOnly);
        })
    }
}
