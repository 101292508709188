import React from "react";
import {Avatar, ListItem, ListItemAvatar, ListItemText} from "@material-ui/core";
import {setFooterLeft} from "../../../store/reducers/appReducer";
import {useDispatch} from "react-redux";

function MaterialListItem(props) {
    const {material, optionSelected, selected, dense} = props;
    const dispatch = useDispatch();

    return (
        <ListItem button
                  key={material.name}
                  onClick={() => optionSelected && optionSelected(material)}
                  selected={selected === material.id}
                  onMouseEnter={() => {
                      dispatch(setFooterLeft(material.description));
                  }}
                  onMouseLeave={() => {
                      dispatch(setFooterLeft(''));
                  }}
                  className={dense ? 'dense' : ''}
        >
            <ListItemAvatar>
                <Avatar variant={"rounded"} alt={material.name} src={material.image}
                        className={'avatar'}/>
            </ListItemAvatar>
            <ListItemText
                primary={material.name}
                secondary={material.secondaryText}
            />
        </ListItem>
    )

}

export default MaterialListItem