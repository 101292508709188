import React from 'react';
import {Grid} from "@material-ui/core";
import SceneContainer from "./SceneContainer";
import Toolbar from "./Toolbar";
import InfoSection from "./InfoSection";

function Workspace(props) {
    return (
        <Grid container
              direction="column"
              justify="center"
              alignItems="stretch"
              wrap="nowrap"
              className={'verticalContainer'}
        >

            <Grid item className={'grid85 grid85-col'} style={{position: 'relative'}}>
                <SceneContainer></SceneContainer>
                <InfoSection/>
            </Grid>

            <Grid item className={'grid15 grid15-col'}>
                <Toolbar/>
            </Grid>
        </Grid>
    );
}

export default Workspace;
