import Column from "./Column";
import RegionSuggestion from "./RegionSuggestion";

export default class Columns {
    constructor(width, height) {
        this.setSize(width, height);
        this.height = height;
        this.columns = [];
    }

    setSize(width, height) {
        this.width = width;
        this.height = height;
    }


    _deleteColumn(column, index = this.columns.indexOf(column)) {
        if (index >= 0) {
            this.columns.splice(index, 1);
            column.dispose();
        }
    }

    _print() {
        let s = '';
        this.columns.sort((c1, c2) => {
            let p1 = 0;
            let p2 = 0;
            if (c1.leftStandard) {
                p1 = c1.leftStandard.bounds().right;
            } else {
                p1 = c1.rightStandard.bounds().left;
            }

            if (c2.leftStandard) {
                p2 = c2.leftStandard.bounds().right;
            } else {
                p2 = c2.rightStandard.bounds().left;
            }
            return p1 - p2;
        });

        this.columns.forEach(col => {
            let left = col.leftStandard ? '+' : '-';
            let right = col.rightStandard ? '+' : '-';
            s += `|${left}${col.width || '*'}${right}|`;
        });
        console.log('Columns:', s)
    }

    totalWidth() {
        let total = 0;
        let left = 9999;
        let right = -9999;

        this.columns.forEach(col => {
            total += col.width;
            let bounds = col._bounds();
            if(bounds){
                left = Math.min(bounds.left,left);
                right = Math.max(bounds.right,right);
            }
            this.left = left;
            this.right = right;
        });

        if (this.columns.length > 0) {
            // Add 1 standard width
            total += 0.5;
        }

        return total;
    }

    _addStandaloneStandard(standard, interactive) {
        let colRight = new Column(standard, null);
        let colLeft = new Column(null, standard);
        colLeft.nextColumn = colRight;
        colRight.prevColumn = colLeft;
        this.columns.push(colLeft);
        this.columns.push(colRight);

        this._print();
        return true;
    }

    addStandard(standard, interactive) {
        if (this.columns.length <= 0) {
            return this._addStandaloneStandard(standard, interactive);
        } else {
            //Find closest position for the standard in the columns.
            let bestColumn = null;
            let minDist = 999999;
            let bestPos = null;
            this.columns.forEach(col => {
                let suggestedPos = col.bestPlaceForStandard(standard);

                if (suggestedPos && suggestedPos.x >= 0 && suggestedPos.x <= this.width) {
                    let dist = Math.abs(suggestedPos.x - standard.position.x);

                    if (dist < minDist) {
                        minDist = dist;
                        bestColumn = col;
                        bestPos = suggestedPos;
                    }
                }
            });
            /*
                Each standard must be attached to 2 columns.(left and right)
                So, when a new standard is dropped into the scene we need to obtain 2 columns to attach it.
                One of the columns is already found.
                For the second, either we create a new column or we'll find a suitable neighbour column to attach.
             */
            if (bestColumn) {
                let index = this.columns.indexOf(bestColumn);
                /*
                   Standard is placed between 2 standards.
                   Find the neighbour standard on the other side.
                 */

                let neighbourColumn = null;
                let neighbourDist = null;
                if (index > 0 && index < this.columns.length - 1) {
                    if (bestColumn.leftStandard) {
                        neighbourColumn = this.columns[index + 1];
                    } else {
                        neighbourColumn = this.columns[index - 1];
                    }
                    neighbourDist = Math.abs(bestColumn.lastSuggestedPos.x - neighbourColumn.lastSuggestedPos.x);
                }

                if (!interactive) {
                    let dist = Math.abs(bestPos.x - standard.position.x);
                    bestPos = null;
                    if (dist >= 20) {
                        return this._addStandaloneStandard(standard, interactive);
                    }
                }
                // if the neighbour is close enough, place the standard inside that column as well
                if (neighbourColumn && neighbourDist < 20) {
                    bestColumn.addStandard(standard, bestPos, false);
                    neighbourColumn.addStandard(standard, bestPos, false);
                } else {
                    //if there is no neighbour or it is far away then create another column
                    let newColumn = bestColumn.addStandard(standard, bestPos);
                    this.columns.push(newColumn);
                }

                this._print();
                return true;
            } else {
                console.log('columns addStandard No bestColumn');
                return false;
            }
        }
    }

    addComponent(component) {
        if (this.columns.length <= 0) {
            return false;
        } else {
            //Find closest position for the standard in the columns.
            let bestSuggestion = RegionSuggestion.NotSuggested();
            this.columns.forEach(col => {
                let regionSuggestion = col.canMount(component);
                bestSuggestion = regionSuggestion.better(bestSuggestion);
            });

            if (bestSuggestion && bestSuggestion.isSuggested()) {
                console.log('CMS best of best:', bestSuggestion)
                this._print();
                return bestSuggestion;
            } else {
                console.log('columns addComponent No bestColumn');
                return false;
            }
        }
    }


    removeStandard(standard) {
        let index = this.columns.length - 1;
        while (index >= 0) {
            let column = this.columns[index];
            column.removeStandard(standard);
            if (!column.leftStandard && !column.rightStandard) {
                this._deleteColumn(column, index);
            }
            index--;
        }
        this._print();
    }

    removeComponent(component) {
        let column = component.column;
        if (column) {
            column.removeComponent(component);
        }
        this._print();

    }

    dispose() {
        let index = this.columns.length - 1;
        while (index >= 0) {
            let column = this.columns[index];
            column.dispose();
            index--;
        }
        this.columns = [];
    }
}
