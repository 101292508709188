import Utils from "./Utils";
import Config from "../config/Config";

export function getNameFromImageUrl(url) {
    let nameExt = url.split('/').pop();
    let name = nameExt.split('.');
    name.pop();
    name = name.join('.');
    return name;
}

export default class ComponentGroup {
    constructor(data, title) {
        this.title = title || data.title;
        this.description = data.description;
        this.id = data.id;
        this.row = data.row;
        this.col = data.col;

        this.items = data.items.map(_item => {
            let item = {..._item};

            for (let key in item.props) {
                item[key] = item.props[key];
                if (['width', 'height', 'depth', 'thickness'].includes(key)) {
                    let feetInch = Utils.convertToSizeStr(item.props[key]);
                    if (feetInch) {
                        item.props[key] = feetInch;
                    }
                }
            }

            item.name = getNameFromImageUrl(item.url);
            // item.materialUrl = Loader.materialUrl;
            item.modelUrl = `/images/components/3d/${item.name}.obj`;
            //32 inch variation of the same component.
            item.modelUrl32 = item.modelUrl
                .replace('W22', 'W32')
                .replace('-Left ', '')
                .replace('-Right ', '');

            item.priceKey = item.priceKey.toLowerCase();
            item.priceKey32 = item.priceKey.replace(/22$/, '32');

            item.helpText = data.helpText;
            item.componentType = data.type;

            return item;
        });
        this.items.sort((a, b) => a.order - b.order);

        this.defineProperties();
    }

    isStandard() {
        return (this.id.toLowerCase() === 'standard')
    }

    filteredItems(filter) {
        // console.log(filter);
        let items = this.items.filter(item => {
            let itemHeight = item.height;
            if (item.type === 'wall-mount') {
                itemHeight = Number(itemHeight) + 6; // Wall mount standards starts 6" above the floor
            }

            if (filter.maxWidth && item.width > filter.maxWidth) {
                return false;
            }
            if (filter.maxHeight && itemHeight > filter.maxHeight) {
                return false;
            }
            if (filter.type && item.type !== filter.type) {
                return false;
            }

            if(!Config.isAdmin && item.onlyAdmin){
                return false;
            }
            return true;
        });

        return items;
    }

    defineProperties() {
        Object.defineProperties(this, {
            length: {
                get: () => {
                    return this.items.length;
                }
            },
        });
    }
}
