import React from "react";
import './style.scss'
import LayoutManager from "../../threed/layout/LayoutManager";

export default function MeasurementText(props) {
    const [style, setStyle] = React.useState({});
    const [clazz, setClass] = React.useState('');
    const [text, setText] = React.useState('');

    const handleMeasurementChange = ({pointOnScreen, text, measureTool = false, visible = true}) => {
        if (!visible) {
            setStyle({display: 'none'});
            setText('');
        } else {
            setText(text);
            setStyle({
                top: pointOnScreen.y + 'px',
                left: pointOnScreen.x + 'px',
                display: 'flex',
            });

            setClass(measureTool ? 'm-tool' : 'bay')
        }
    }

    React.useEffect(() => {
        if (LayoutManager.getInstance().measurementLine) {
            LayoutManager.getInstance().measurementLine.on('measurementChanged', handleMeasurementChange)
        }
        return () => {
            LayoutManager.getInstance().measurementLine.off('measurementChanged', handleMeasurementChange);
        }
    }, [LayoutManager.getInstance().measurementLine])

    return (
        <div id="measurements" style={style}>
            <div className={`measurement-text ${clazz}`}>
                {text}
            </div>
        </div>
    )
}
