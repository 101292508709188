import React from 'react';
import './InfoBubble.scss'
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    ClickAwayListener,
    Popover,
    Typography
} from "@material-ui/core";
import InfoIcon from '@material-ui/icons/Info';
import connect from "react-redux/es/connect/connect";
import {closeInfoPopup} from "../store/reducers/appReducer";


function InfoBubble(props) {
    const {message, position, open, dispatch} = props;

    const handlePopoverClose = () => {
        dispatch(closeInfoPopup());
    };

    return (

        <Popover
            open={open}
            anchorReference="anchorPosition"
            anchorPosition={{top: position.y, left: position.x}}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            // onClose={handlePopoverClose}
            disableRestoreFocus
        >
            <ClickAwayListener mouseEvent='onMouseDown' onClickAway={handlePopoverClose}>
                <Card className={'info-card'}>
                    <CardHeader
                        avatar={<InfoIcon fontSize={'large'}/>}
                        title={<Typography gutterBottom variant="h5" component="h2">
                            tip
                        </Typography>}
                    />
                    <CardContent className={'content'}>
                        <Typography variant="body2" color="textSecondary" component="p">
                            {message}
                        </Typography>
                    </CardContent>

                    <CardActions>
                        <Button size="small" color="primary" onClick={handlePopoverClose}>
                            Okay
                        </Button>
                    </CardActions>
                </Card>
            </ClickAwayListener>
        </Popover>
    )
}


export default connect()(InfoBubble)
