import React from "react";
import {Avatar, List, ListItem, ListItemAvatar, ListItemText, Typography} from "@material-ui/core";
import {setFooterLeft, setMaterial} from "../../../store/reducers/appReducer";
import {connect} from 'react-redux'
import MaterialListItem from "./MaterialListItem";

function MaterialList(props) {
    const {group, dispatch, selected} = props;

    const optionSelected = (material) => {
        dispatch(setMaterial({name: group.storeKey, value: material}));
        props.optionSelected();
    };

    return <div className={"MaterialList"} key={group.title}>
        <Typography variant="body1" component={'h3'} className={'header'}>
            {group.title}
        </Typography>
        <List component="nav" className={'options'} dense={group.dense}>
            {
                group.options.map(material =>
                    <MaterialListItem key={material.name} material={material} optionSelected={optionSelected} selected={selected}
                                      dense={group.dense}/>
                )
            }
        </List>
    </div>
}


//REDUX
const mapStateToProps = (state, ownProps) => {
    const {group} = ownProps;
    return {appState: state.appState, selected: state.appState[group.storeKey]};
};
export default connect(mapStateToProps)(MaterialList)