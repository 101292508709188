import * as THREE from 'three';
import {
    Color,
    HemisphereLight,
    MeshBasicMaterial,
    PointLight,
    RepeatWrapping,
    Scene,
    TextureLoader,
    Vector2,
    WebGLRenderer
} from 'three';
import Ray from "./Ray";
import {Vector3} from "three/src/math/Vector3";
import CamControl, {CamType} from "./CamControl";
import {inputHandler} from '../workspace/InputHandler'
import {DragInputHandler} from "../workspace/DragInputHandler";
import LayoutManager from "./layout/LayoutManager";
import {utils3d} from "./Utils3d";
import Loader from "../workspace/Loader";
import {CameraInputHandler} from "../workspace/CameraInputHandler";
import Config from "../config/Config";
import {EventEmitter} from 'events';
import {KEYS} from "../store/StoreKeys";
import {MeasureInputHandler} from "../workspace/MeasureInputHandler";
import {EffectComposer} from "three/examples/jsm/postprocessing/EffectComposer";
import {RenderPass} from "three/examples/jsm/postprocessing/RenderPass";
import {ShaderPass} from "three/examples/jsm/postprocessing/ShaderPass";
import {SobelOperatorShader} from "three/examples/jsm/shaders/SobelOperatorShader";
import {LuminosityShader} from "three/examples/jsm/shaders/LuminosityShader";
import Component from "../data/Component";
import {LineMaterial} from "three/examples/jsm/lines/LineMaterial";
import {LineSegmentsGeometry} from "three/examples/jsm/lines/LineSegmentsGeometry";
import {Line2} from "three/examples/jsm/lines/Line2";


window.THREE = THREE;
let hiddenLine = false;
window.hiddenLine = hiddenLine;

let zoom = 1.5;

let camOptionsBuildMode = {
    distance: 1000,
    focusPos: new Vector3(0, 0, 0),
    distRange: {
        max: 2000,
        min: 1
    },
    //Same rotation with sketchup camera while taking the pictures of the models.
    rotation: new Vector3(45.877, 13.7, 0),
    eyeSeparation: 0.3,
    damping: 0.2,
    fov: 50,
    camType: CamType.ORTHOGRAPHIC,
    frustumSize: 150,
    zoom: zoom
};

let camOptionsViewMode = {
    distance: 1500,
    focusPos: new Vector3(60, 15, 0),
    distRange: {
        max: 5000,
        min: 100
    },
    //Same rotation with sketchup camera while taking the pictures of the models.
    rotation: new Vector3(45.877, 13.7, 0),
    eyeSeparation: 0.3,
    damping: 0.1,
    fov: 50,
    camType: CamType.ORTHOGRAPHIC,
    frustumSize: 150,
    zoom: 1.8
};

window.camOptionsBuildMode = camOptionsBuildMode;
window.camOptionsViewMode = camOptionsViewMode;

class Scene3d extends EventEmitter {
    constructor() {
        super();
        this.animate = this.animate.bind(this);
        this.scene = new Scene();

        this.renderer = new WebGLRenderer({antialias: true, alpha: true});
        // this.renderer = new SVGRenderer();
        this.renderer.setClearColor(0xFFFFFF, 0.0);
        this.renderer.setPixelRatio(2);
        // this.renderer.toneMapping = ReinhardToneMapping;
        // this.renderer.toneMapping = CineonToneMapping;
        // this.renderer.toneMapping = LinearToneMapping;
        // this.renderer.toneMappingExposure = 1.0;
        // this.renderer.shadowMap.enabled=true;
        // this.renderer.outputEncoding = LinearEncoding;
        this.renderer.domElement.id = 'canvas3d-atlas';

        this.renderOn = true;

        this.resolveInitialized = null;
        this.promiseInitialized = new Promise(resolve => this.resolveInitialized = resolve);
    }

    setupComposer() {
        this.composer = new EffectComposer(this.renderer);
        const renderPass = new RenderPass(this.scene, this.camera);
        this.composer.addPass(renderPass);

        // color to grayscale conversion

        const effectGrayScale = new ShaderPass(LuminosityShader);
        this.composer.addPass(effectGrayScale);

        // you might want to use a gaussian blur filter before
        // the next pass to improve the result of the Sobel operator

        // Sobel operator

        this.effectSobel = new ShaderPass(SobelOperatorShader);
        this.effectSobel.uniforms['resolution'].value.x = window.innerWidth * window.devicePixelRatio;
        this.effectSobel.uniforms['resolution'].value.y = window.innerHeight * window.devicePixelRatio;
        this.composer.addPass(this.effectSobel);
    }

    async initialize(sceneContainer) {
        window.addEventListener('resize', this.onWindowResize.bind(this), false);

        this.defaultMaterials = await Loader.defaultMaterials;
        this.hiddenLineMaterials = {};

        Object.keys(this.defaultMaterials.materials).forEach(key => {
            let mat = new MeshBasicMaterial({color: new Color(0xffffff)});
            mat.name = key;
            this.hiddenLineMaterials[key] = mat;
        })

        //Setup renderer
        this._setSceneContainer()
        this.sceneContainer.appendChild(this.renderer.domElement);

        this.initCam();
        utils3d.setCamera(this.camera);
        //Setup raycaster
        this.ray = new Ray(this.camera);
        // let helper = new PlaneHelper(this.ray.plane, 300, 0xffff00);
        // this.scene.add(helper);

        this.setupComposer();

        //DEBUG
        window.cam = this.camera;
        window.con = this.controls;

        this.addSceneLights();
        this.animate();

        //This has to come after animate !
        this.initManagers();
        this.initialized = true;
        this.resolveInitialized(true);

        if (Config.debug) {
            this.addModel(null, Config.Standards.items[4], new Vector3(80, -100, 0));
            this.addModel(null, Config.Standards.items[4], new Vector3(80, -100, 0));
            this.addModel(null, Config.Standards.items[4], new Vector3(80, -100, 0));
            // this.addModel(null, Config.Standards.items[1], new Vector3(0, -100, 0));
            // this.addModel(null, Config.Standards.items[1], new Vector3(999, -100, 0));
            // this.addModel(null, Config.Standards.items[1], new Vector3(999, -100, 0));
            // this.addModel(null, Config.Standards.items[1], new Vector3(0, -100, 0));
            // this.addModel(null, Config.Standards.items[1], new Vector3(0, -100, 0));
            // this.addModel(null, Config.Standards.items[1], new Vector3(0, -100, 0));
        }
    }

    initManagers() {
        this.dragInputHandler = new DragInputHandler(this.ray, this.vpW, this.vpH, LayoutManager.getInstance().createSnapPoints);
        this.measureInputHandler = new MeasureInputHandler(this.ray, this.vpW, this.vpH, LayoutManager.getInstance().createSnapPoints);
        LayoutManager.getInstance(this).initialize(this.getWorkplaceBounds(), this.dragInputHandler, this.measureInputHandler);

        inputHandler.addHandler(this.dragInputHandler, 2);

        this.cameraInputHandler = new CameraInputHandler(this.renderer.domElement, this.controls);
        if (Config.debug) {
            inputHandler.addHandler(this.cameraInputHandler, 100);
        }
    }

    getPointIn3d(x, y) {
        let parentBound = this.sceneContainer.getBoundingClientRect();
        let mouse = {
            x: (x - parentBound.left) / this.vpW * 2 - 1,
            y: (y - parentBound.top) / this.vpH * -2 + 1
        };
        return this.ray.intersectPlane(mouse);
    }

    initCam() {
        // Camera.direction from Sketchup to controls.Rotation
        // let x = 0.698704, y = -0.237658, z = 0.674783;
        // let s =new Spherical();
        // s.setFromVector3(new THREE.Vector3(y,x,z));
        // var phi = s.phi /Math.PI*180;
        // var theta = s.theta /Math.PI*180;
        // this.controls.setRotation(phi,-theta-6,0);//??


        //Setup Camera
        this.controls = new CamControl(camOptionsBuildMode, this.renderer.domElement);
        this.camera = this.controls.camera;
        this.setViewportSize();
        this.controls.update();
    }

    setViewPoint(viewPoint) {
        switch (viewPoint) {
            case 'top':
                // this.controls.setRotation(90,0,0);
                this.controls.rotTarget.set(0, 90, 0);
                break;
            case 'front':
                this.controls.rotTarget.set(0, 0, 0);
                break;
            case 'left':
                this.controls.rotTarget.set(90, 0, 0);
                break;
            case 'right':
                this.controls.rotTarget.set(-90, 0, 0);
                break;
        }
    }

    addModel(event, modelParams, position = null, interactive = true) {
        return new Promise((resolve, reject) => {
            this.setMeasureOn(false);
            let isUserDragging = !!event;
            /* The images of the models are exported from Sketchup.
                We need to adjust the model's position so that the projected image of the model matches the Sketchup image used in the UI.
            */
            if (isUserDragging) {
                let bound = event.target.getBoundingClientRect();
                // The projected 3d position of bottom center of image.
                let x = (bound.left + bound.width / 2);
                let y = (bound.bottom - bound.height * 0.04);
                position = this.getPointIn3d(x, y);
            }

            if (!modelParams) {
                reject('params are missing')
                return;
            }

            let self = this;
            this.model = new Component(modelParams, this.defaultMaterials, (model) => {

                if (interactive) {
                    /*
                        The bottom point of the image is bottom,front,right (BFR) corner considering the position of the camera
                        while taking the pictures in sketchup.

                       1- Project the ray from camera to the BFR corner and calculate the vertical offset of the projection from the models bottom.
                       2- Add this offset to the model so that projection of BFR corner of the model matches the HTML image's bottom.
                     */
                    // Use camera's position as the ray.
                    let camPos = this.camera.position;

                    //phi (spherical coords)
                    let distZ = model.size.z / 2;
                    let distX = Math.abs(distZ / camPos.z * camPos.x);
                    let dist = new Vector2(distX, distZ);

                    let x1 = model.size.x / 2 - distX;
                    let dist2 = x1 * distX / dist.length();

                    let d = dist.length() + dist2;

                    //theta (spherical coords)
                    let heightDiff = d / Math.sqrt(camPos.z * camPos.z + camPos.x * camPos.x) * camPos.y;

                    let s = 1.3 / zoom;
                    position.y += heightDiff * s;
                    model.model3d.scale.set(s, s, s);
                }

                model.model3d.position.copy(position);

                LayoutManager.getInstance().initComponent(model.model3d, interactive);

                if (interactive) {
                    model.animatePosition({
                        z: model.model3d.position.z + model.size.z / 2,
                        y: model.model3d.position.y - model.size.y / 2
                    }, 1, () => {
                        if (isUserDragging) {
                            //Let user drag the component
                            self.dragInputHandler.setComponent(model, event);
                            resolve(model);
                        } else {
                            //Directly place component in the workspace
                            LayoutManager.getInstance().addComponent(model, interactive);
                            resolve(model);
                        }
                    });
                } else {
                    LayoutManager.getInstance().addComponent(model);
                    resolve(model);
                }
            });
            window.model = this.model;
        })
    }

    _setSceneContainer(viewModeOn = false) {
        if (viewModeOn) {
            this.sceneContainer = document.getElementById('viewmode-content');
        } else {
            this.sceneContainer = document.getElementById('scene3d');
        }
    }

    setRenderOn(on) {
        this.renderOn = on;
        this.animate();
    }

    getWorkplaceBounds() {
        let refBound;

        if (this.viewModeOn) {
            refBound = this.sceneContainer.getBoundingClientRect();
        } else {
            refBound = document.getElementById('scene-container').getBoundingClientRect();
        }

        let topRight = this.getPointIn3d(refBound.right, refBound.top);
        let bottomLeft = this.getPointIn3d(refBound.left, refBound.bottom);
        let topLeft = this.getPointIn3d(refBound.left, refBound.top);
        return {topRight, bottomLeft, topLeft}
    }

    setMeasureOn(on) {
        if (on) {
            inputHandler.addHandler(this.measureInputHandler, 1);
            inputHandler.removeHandler(this.dragInputHandler);
        } else {
            inputHandler.addHandler(this.dragInputHandler, 1);
            inputHandler.removeHandler(this.measureInputHandler);
        }
        this.measureInputHandler.reset();
        LayoutManager.getInstance().clearLine()
    }

    setViewMode(viewModeOn) {
        let builder = document.getElementById('scene3d');
        let viewer = document.getElementById('viewmode-content');
        let canvas = document.getElementById('canvas3d-atlas');

        if (viewModeOn) {
            viewer.appendChild(canvas);
        } else {
            builder.appendChild(canvas);
        }

        this.viewModeOn = viewModeOn;
        this._setSceneContainer(viewModeOn);

        if (viewModeOn) {
            inputHandler.addHandler(this.cameraInputHandler, 100);
            inputHandler.removeHandler(this.dragInputHandler, 100);
            // let size = LayoutManager.getInstance().size();
            // camOptionsViewMode.zoom = Math.max( size.width,size.height)/ 200;


            let layout = LayoutManager.getInstance();
            camOptionsViewMode.focusPos = layout.center();

            let wall = layout.wall;
            let frustumSize = wall.workAreaHeight * 1.1;
            if (wall.workAreaWidth > wall.workAreaHeight) {
                frustumSize = (wall.workAreaWidth + wall.workAreaHeight) / 1.8;
            }
            camOptionsViewMode.frustumSize = frustumSize;


            this.controls.setOptions(camOptionsViewMode);
            this.setViewportSize();
        } else {
            inputHandler.removeHandler(this.cameraInputHandler);
            inputHandler.addHandler(this.dragInputHandler);

            this.controls.setOptions(camOptionsBuildMode);
            this.setViewportSize();
        }
        LayoutManager.getInstance().setViewMode(viewModeOn);
        this._positionCamLight();
    }

    setViewportSize(container = null, updateLayout = true) {
        this.emit('before-resize');

        if (!container) {
            container = this.renderer.domElement.parentElement;
        }
        this.vpH = container.clientHeight;
        this.vpW = container.clientWidth;

        if (this.composer) {
            this.composer.setSize(this.vpW, this.vpH);
            this.effectSobel.uniforms['resolution'].value.x = this.vpW * window.devicePixelRatio;
            this.effectSobel.uniforms['resolution'].value.y = this.vpH * window.devicePixelRatio;
        }

        LayoutManager.getInstance().measurementLine.setResolution(this.vpW, this.vpH);

        this.controls.onWindowResize(this.vpW, this.vpH);
        inputHandler.setViewSize(this.vpW, this.vpH);

        this.renderer.setSize(this.vpW, this.vpH);
        utils3d.setSize(this.vpW, this.vpH);

        if (this.initialized && updateLayout) {
            if (this.viewModeOn) {
                //View mode is full screen. Just center the camera to the wall.
                // this.controls.focusTarget=LayoutManager.getInstance().center();
                // this.controls.focusActual=this.controls.focusTarget;
            } else {
                //Align the walls with the workspace html container.
                requestAnimationFrame(() => {
                    //Camera is not updated unless we do this.
                    LayoutManager.getInstance().setBounds(this.getWorkplaceBounds());
                })
            }
        }

    }

    onWindowResize() {
        this.setViewportSize();
    }

    addSceneLights() {
        this.hemiLight = new HemisphereLight(0xffffff, 0xaaaaaa, 0.8);
        this.scene.add(this.hemiLight);
        // this.ambLight = new AmbientLight(0xffffff, 0.6);
        // this.scene.add(this.ambLight);

        this.pointLight2 = new PointLight(0xffffff, 0.3, 0, 1);
        this.pointLight2.position.set(200, -100, 100)
        this.scene.add(this.pointLight2);

        this.pointLight = new PointLight(0xffffff, 0.3, 0, 1);
        this.pointLight.position.set(-200, -100, 100)
        this.scene.add(this.pointLight);

        // this.pointLightHelper = new PointLightHelper(this.pointLight,10,0x00ff00);
        // this.scene.add(this.pointLightHelper);


        // this.camLight = new DirectionalLight(0xffffff, 0.1);
        // this.scene.add(this.camLight);
        // this.camLightHelper = new DirectionalLightHelper(this.camLight, 10, 0x0000ff)
        // this.scene.add(this.camLightHelper);

        this._positionCamLight();
    }

    _positionCamLight() {
        // let pos = new Vector3();
        // this.camera.getWorldDirection(pos);
        // pos.multiplyScalar(-500);

        // let pos = this.camera.position.clone();
        // this.camLight.position.copy(pos);
        // this.camLightHelper.update();
    }

    animate(timespan, force = false) {
        if (!this.renderOn && !force) {
            return;
        }
        requestAnimationFrame(this.animate);
        // if (this.viewModeOn) {
        this._positionCamLight();
        // }
        if (window.hiddenLine) {
            this.composer.render()
        } else {
            this.renderer.render(this.scene, this.camera);
        }
        this.controls.update();
    }

    getCanvasPortion(originalCanvas, x, y, w, h) {
        let canvas = document.createElement('canvas');
        canvas.width = w;
        canvas.height = h;
        canvas.getContext('2d').drawImage(originalCanvas, x, y, w, h, 0, 0, w, h);
        return canvas.toDataURL('image/jpeg', 0.8);
    }

    getDataUrl(strMime = 'image/jpeg') {
        //Prepare scene for screenshot.
        this.setViewMode(true);
        this.renderer.setClearAlpha(1.0);
        LayoutManager.getInstance().wall.setBorderOn(true);

        //Render scene.
        this.animate(null, true);

        // Calculate workspace position/size
        let workSpace = LayoutManager.getInstance().wall.workArea;
        let margin = {
            x: 25 / workSpace.scale.x,
            y: 25 / workSpace.scale.y,
        };

        let topRight = utils3d.getPointOnScreen(workSpace, new Vector3(0.5 + margin.x, 0.5, 0.5), true).multiplyScalar(2);
        let bottomLeft = utils3d.getPointOnScreen(workSpace, new Vector3(-0.5, -0.5 - margin.y, -0.5), true).multiplyScalar(2);

        let region = {
            //Top left in 2d considering to camera angle
            x: bottomLeft.x, // bottom left is the lowest x in 2d.
            y: topRight.y, // top right is the lowest y in 2d.

            //Add 10% to size to cover possible component extensions
            w: (topRight.x - bottomLeft.x),
            h: (bottomLeft.y - topRight.y), // bottom left is the highest y in 2d.
        };

        // if the 10% is exceeding the canwas size, crop it.
        let overFlow = {
            x: region.x + region.w - this.renderer.domElement.width,
            y: region.y + region.h - this.renderer.domElement.height
        };
        if (overFlow.x > 0) {
            region.w -= overFlow.x;
        }
        if (overFlow.y > 0) {
            region.h -= overFlow.y;
        }

        // Capture only specified portion of the canvas.
        let dataURL = this.getCanvasPortion(this.renderer.domElement, region.x, region.y, region.w, region.h);

        // Rollback changes to the scene.
        LayoutManager.getInstance().wall.setBorderOn(false)
        this.renderer.setClearAlpha(0.0);
        this.setViewMode(false);

        return dataURL;
    }

    saveAsImage(fileName = 'atlas-scene') {
        var imgData, imgNode;

        try {
            let strMime = "image/jpeg";
            imgData = this.getDataUrl(strMime);

            this.saveFile(imgData.replace(strMime, "image/octet-stream"), fileName + ".jpg");

        } catch (e) {
            console.log(e);
            return;
        }
    }

    saveFile = function (strData, filename) {
        var link = document.createElement('a');
        if (typeof link.download === 'string') {
            document.body.appendChild(link); //Firefox requires the link to be in the body
            link.download = filename;
            link.href = strData;
            link.click();
            document.body.removeChild(link); //remove the link when done
        } else {
            window.location.replace(strData);
        }
    };

    createHiddenLine(node) {
        // let edgesGeo = new THREE.EdgesGeometry(node.geometry, 70);
        // let pos = edgesGeo.attributes.position.array;


        let pos = node.geometry.attributes.position.array;
        let col = pos.map(p => 1)
        let geo = new LineSegmentsGeometry()

        geo.setPositions(pos);
        geo.setColors(col);

        let mat = new LineMaterial({
            color: 0x000000,
            linewidth: 1, // in pixels
            vertexColors: true,
            //resolution:  // to be set by renderer, eventually
            dashed: false

        });
        mat.resolution.set(this.vpW, this.vpH);
        let line = new Line2(geo, mat);

        return line;
    }

    setHiddenLine(on) {
        let materials = this.hiddenLineMaterials;
        let hiddenLineRoot = this.scene.getObjectByName('Hidden Line Root')
        let root = this.scene.getObjectByName('Component Root')
        if (!on) {
            materials = this.defaultMaterials.materials;
            // hiddenLineRoot.traverse(node => hiddenLineRoot.remove(node));
        }


        root.traverse(node => {
            if (!node.isLineSegments) {
                if (node.material && node.material.isMaterial) {
                    node.material = materials[node.material.name];
                }
                if (node.material && Array.isArray(node.material)) {
                    node.material = node.material.map(mat => {
                        return materials[mat.name]
                    })
                }
                if (node.isMesh) {
                    if (on) {
                        // let line = this.createHiddenLine(node);
                        // node.parent.add(line);
                        // line.position.copy(node.position);
                        // line.position.y += 0.1;
                    }
                }
            } else {
                if (on) {
                    // let line = this.createHiddenLine(node);
                    // node.parent.add(line);
                    // line.position.copy(node.position);
                    // line.position.y+=0.1;
                }
            }
        })
    }

    setMaterial(type, material) {
        const setMaterials = (materialNames, texture) => {
            materialNames.forEach(matName => {
                let mat = this.defaultMaterials.materials[matName];
                if (mat) {
                    mat.map = texture;
                    mat.neesUpdate = true;
                }
            })
        }

        const getTexture = (url) => {
            let texture = new TextureLoader().load(url)
            texture.wrapS = RepeatWrapping;
            texture.wrapT = RepeatWrapping;
            return texture;
        }

        let textureUrlHor = material.textureHor;
        let textureUrlVert = material.textureVert;
        let texture = getTexture(textureUrlHor);

        if (type === KEYS.woodMaterial) {
            setMaterials(Config.woodenMaterialsHorizontal, texture)

            let textureVert = getTexture(textureUrlVert);
            setMaterials(Config.woodenMaterialsVertical, textureVert);
        } else if (type === KEYS.metalMaterial) {
            setMaterials(Config.metalMaterials, texture)
        }
    }
}

const scene3d = new Scene3d();
window.scene = scene3d;
export default scene3d;
