import {createAction, createReducer} from "@reduxjs/toolkit";
import FooterData from "../../data/FooterData";
import Config from "../../config/Config";
import {KEYS} from "../StoreKeys";
import LayoutManager from "../../threed/layout/LayoutManager";
import priceCalculator from "../../config/PriceCalculator";
import {inputHandler} from '../../workspace/InputHandler'


export const setMaterial = createAction('set/material');
export const setPageNum = createAction('set/pageNum');
export const setFooter = createAction('set/footer');
export const setFooterLeft = createAction('set/footer-left');
export const setFooterRight = createAction('set/footer-right');
export const setFooterRightTransiting = createAction('set/footer-right-transition');
export const showInfoPopup = createAction('show/info-popup');
export const closeInfoPopup = createAction('close/info-popup');
export const setViewMode = createAction('set/viewMode');
export const setPrintMode = createAction('set/printMode');
export const setSelectedStandard = createAction('set/selectedStandard');
export const startOver = createAction('start over');
export const setSubmissionFormOpen = createAction('setSubmissionFormOpen');
export const setWallDimensions = createAction('set/WallDimensions');
export const toggleMeasurement = createAction('toggleMeasurement');
export const toggleWireframe = createAction('toggleWireframe');
export const componentPicked = createAction('componentPicked');
export const setTotalWidth = createAction('set/totalWidth');
export const setTotalCost = createAction('set/totalCost');

export function setFooterRightWithTimeout(footer, delay) {
    return function (dispatch) {
        dispatch(setFooterRightTransiting(true));

        setTimeout(() => {
            dispatch(setFooterRightTransiting(false));
            dispatch(setFooterRight(footer));
        }, delay)
    }
}

const defaultState = {
    viewModeOn: false,
    printModeOn: false,
    measurementOn: false,
    wireframeOn: false,
    // woodMaterial: 'cherry',
    // metalMaterial: 'bronze_natural',
    pageNum: 0,
    woodMaterial: null,
    metalMaterial: null,
    priceKey: 'N_CRS',
    woodMaterial_PriceKey: 'N',
    metalMaterial_PriceKey: 'CRS',
    selectedStandard: Config.Standards.items[5],
    'active-tool': null,
    submissionFormOpen: false,
    footer: {
        left: '',
        right: new FooterData('', '').toJson(),
        transiting: {left: false, right: false}
    },
    info: {
        open: false,
        message: '',
        position: {x: 0, y: 0}
    },
    wallDimensions: {width: 16 * 12, height: 10 * 12, notSet: false},
    totalCost: 0,
    totalWidth: 0,
};

// function Z

if (Config.debug) {
    // setTimeout(() => {
    //     initViewMode(defaultState.viewModeOn);
    // }, 1000);
}

const appReducer = createReducer(defaultState, {
    [setMaterial]: (state, action) => {
        state[action.payload.name] = action.payload.value.id;
        state[action.payload.name + '_PriceKey'] = action.payload.value.priceKey;

        state.priceKey = state['woodMaterial_PriceKey'] + '_' + state['metalMaterial_PriceKey']
        priceCalculator.calculatePrice();
    },
    [setPageNum]: (state, action) => {
        state.pageNum = action.payload;
    },
    [toggleMeasurement]: (state, action) => {
        state.measurementOn = !state.measurementOn;
    },
    [toggleWireframe]: (state, action) => {
        state.wireframeOn = !state.wireframeOn;
    },
    [componentPicked]: (state, action) => {
        state.measurementOn = false;
    },
    [setSubmissionFormOpen]: (state, action) => {
        const {open, purpose} = action.payload;
        state.submissionFormOpen = open;
        state.submissionPurpose = purpose;

        //Disable input handler when submission dialog is open so it wont intercept the mouse events.
        inputHandler.setEnabled(!open)
    },
    [startOver]: (state, action) => {
        state.pageNum = 0;
        state[KEYS.woodMaterial] = null;
        state[KEYS.metalMaterial] = null;
        state.viewModeOn = false;
    },
    [setFooter]: (state, action) => {
        if (action.payload) {
            state.footer.right = action.payload.right;
            state.footer.left = action.payload.left;
        } else {
            state.footer.right = '';
            state.footer.left = '';
        }
    },
    [setFooterLeft]: (state, action) => {
        state.footer.left = action.payload;
    },
    [setFooterRight]: (state, action) => {
        state.footer.right = action.payload;
    },
    [setFooterRightTransiting]: (state, action) => {
        state.footer.transiting.right = action.payload;
    },
    [showInfoPopup]: (state, action) => {
        state.info.open = true;
        state.info.message = action.payload.message;
        state.info.position = action.payload.position;
    },
    [closeInfoPopup]: (state, action) => {
        state.info.open = false;
    },
    [setViewMode]: (state, action) => {
        state.viewModeOn = action.payload;
    },
    [setPrintMode]: (state, action) => {
        state.printModeOn = action.payload;
    },
    [setSelectedStandard]: (state, action) => {
        state.selectedStandard = action.payload;
    },
    [setWallDimensions]: (state, action) => {
        state.wallDimensions = action.payload;
        LayoutManager.getInstance().setWallDimensions(action.payload);
    },
    [setTotalWidth]: (state, action) => {
        let width = action.payload;
        // Round to closes 0.5
        state.totalWidth = Math.round(width * 2) / 2;
        // state.totalWidth = width ;
    },
    [setTotalCost]: (state, action) => {
        state.totalCost = action.payload;
    }
});

export default appReducer;
