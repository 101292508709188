import {EventEmitter} from 'events';

class InputHandler extends EventEmitter {
    constructor() {
        super();
        this.handlers = [];
        this.enabled = true;
        this.handlerIndex = 1000;

        document.addEventListener('pointerdown', this.pointerDown.bind(this));
        document.addEventListener('pointermove', this.pointerMove.bind(this));
        document.addEventListener('pointerup', this.pointerUp.bind(this));
        document.addEventListener('wheel', this.gestureWheel.bind(this));
        document.addEventListener('contextmenu', this.contextMenu.bind(this), false);
        document.addEventListener('keydown', this.keyDown.bind(this), false);

        this.isDown = false;
        this.hasMoved = false;
    }

    setEnabled(enabled){
        this.enabled = enabled
    }

    setViewSize(width,height){
        this.delegateToHandlers({width,height}, 'setViewSize');
    }

    keyDown(event) {
        //Firefox bug. See:
        // https://developer.mozilla.org/en-US/docs/Web/API/Document/keydown_event
        if (event.isComposing || event.keyCode === 229) {
            return;
        }


        switch (event.code) {
            case 'Escape':
                this.delegateToHandlers(event, 'escape');
                break;
            default:
                this.delegateToHandlers(event, 'keyDown');
        }
    }

    contextMenu(e) {
        e.preventDefault();
    }

    gestureWheel(event) {
        this.delegateToHandlers(event, 'gestureWheel');
    };

    pointerDown(event) {
        this.delegateToHandlers(event, 'pointerDown',true);

        this.isDown = true;
        this.dispatch('down', event);
    }

    pointerUp(event) {
        this.delegateToHandlers(event, 'pointerUp',true);

        if (this.hasMoved) {
            this.dispatch('drop', event);
        } else {
            this.dispatch('tap', event);
        }
        this.isDown = false;
        this.hasMoved = false;
    }

    pointerMove(event) {
        event.preventDefault();
        this.delegateToHandlers(event, 'pointerMove');
        this.dispatch('pointerMove', event);
        if (this.isDown) {
            this.hasMoved = true;

            this.dispatch('drag', event);
        }
    }

    // Subscribers of mouse events
    dispatch(type, event) {
        this.emit(type, event);
    }

    addHandler(handler, order = null) {
        if (!this.handlers.find(map => map.handler === handler)) {

            let o = order || this.handlerIndex++;
            this.handlers.push({order: o, handler: handler});
            this.handlers.sort((h1, h2) => h1.order - h2.order);
        }
    }

    removeHandler(handler) {
        let index = this.handlers.findIndex(map => map.handler === handler);
        if (index >= 0) {
            this.handlers.splice(index, 1);
        }
    }

    delegateToHandlers(event, type,preventDefault=false) {
        if(!this.enabled){
            return
        }

        // console.log('input handler ',type);
        const isConsumed= this.handlers.some(map => {
            let consumed = false;
            if (map.handler[type]) {
                consumed = map.handler[type](event);
            }
            return consumed;
        });

        if(isConsumed && preventDefault){
            event.preventDefault();
        }
    }
}

export const inputHandler = new InputHandler();
window.ih = inputHandler;
