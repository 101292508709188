import Papa from 'papaparse';
import {inventory} from "../threed/Inventory";
import {COMPONENT_TYPES} from "../threed/layout/Constraint";
import {store} from 'App'
import {setTotalCost} from "../store/reducers/appReducer";
import Config from "./Config";
import axios from "axios";
import {getPrices, getSubmission} from "../components/Submission/Service";
import LayoutManager from "../threed/layout/LayoutManager";

const priceKeys = [
    "price",
    "N_CRS",
    "N_BCRS",
    "N_Bronze",
    "LC_STAIN_CRS",
    "LC_STAIN_BCRS",
    "LC_STAIN_Bronze"
]

class PriceCalculator {
    constructor() {
        this.handleCsvData = this.handleCsvData.bind(this);
        let _this = this;
        this.promisePriceData = new Promise((resolve, reject) => {
            _this.resolve = resolve;
            _this.reject = reject;
        })
    }

    init() {
        if (Config.isAdmin) {
            this.fetchPriceData();
        }
    }

    // fetchPriceData() {
    //     let _this = this;
    //     let csvConfig = {
    //         header: true,
    //         download: true,
    //         dynamicTyping: true,
    //         delimiter: ",",
    //         complete: _this.handleCsvData,
    //         transform: _this.transform,
    //         skipEmptyLines: 'greedy'
    //     }
    //     // let url = window.location.origin + prices;
    //     let url = "https://atlas-builder.storage.googleapis.com/prices/prices.csv";
    //     // let url = 'https://www.builder.atlaseast.com/prices.csv'
    //     Papa.parse(url, csvConfig);
    // }

    fetchPriceData() {
        let _this = this;
        // let url = 'https://www.builder.atlaseast.com/prices.csv'
        getPrices()
            .then(response => {
                _this.handleCsvData(response);
            })
            .catch(error => {
                console.log("Couldn't fetch prices: ", error);
            })
    }

    transform(val, header) {
        switch (header) {
            case 'item_code':
                return val;
            case 'price':
                if (val) {
                    return val.replace(/[,$]/g, '')
                } else {
                    return val;
                }
            default:
                if (val) {
                    return val.replace(',', '.');
                } else {
                    return val;
                }
        }
    }

    transformRow(row){
        priceKeys.forEach(key=>{
            row[key] = Number(row[key]);
        })
    }

    handleCsvData(csv) {
        this.priceData = {};
        csv.data.map(row => {
            let key = row.item_code.trim().toLowerCase();
            this.transformRow(row);
            this.priceData[key] = row;
        })

        this.resolve(this.priceData);
    }


    async calculatePrice() {
        if (!Config.isAdmin) {
            return;
        }
        await this.promisePriceData;
        let total = 0;
        console.log('store', store.getState())
        inventory.components.forEach(component => {
                let priceData = this.priceData[component.priceKey] || 0;
                if (priceData) {
                    let priceKey = store.getState().appState.priceKey;
                    let price = priceData[priceKey];

                    if (component.componentType === COMPONENT_TYPES.desk) {
                        price = price * component.width;
                    }
                    total += price;
                }
            }
        )
        store.dispatch(setTotalCost(total));
        return total;
    }
}

const priceCalculator = new PriceCalculator();
window.priceCalculator = priceCalculator;
export default priceCalculator;
