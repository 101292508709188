import {List, ListItem, ListItemIcon, ListItemText, ListSubheader, Popover} from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import PublishIcon from "@material-ui/icons/Publish";
import React from "react";
import Config from "../config/Config";

export default function DonePopup(props) {
    const {anchorEl, open} = props;

    const handlePopoverClose = () => {
        props.onClose();
    };

    const handleToolSelected = (tool) => {
        handlePopoverClose();
        props.toolSelected(tool)
    };

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
        >
            <List component="nav"
                  subheader={
                      <ListSubheader component="div" id="nested-list-subheader">
                          please select what you would like to do next...
                      </ListSubheader>
                  }>
                {/*<ListItem button onClick={() => handleToolSelected('print')}>*/}
                    {/*<ListItemIcon>*/}
                        {/*<PrintIcon/>*/}
                    {/*</ListItemIcon>*/}
                    {/*<ListItemText primary="print"/>*/}
                {/*</ListItem>*/}
                <ListItem button onClick={() => handleToolSelected('save')}>
                    <ListItemIcon>
                        <PictureAsPdfIcon/>
                    </ListItemIcon>
                    <ListItemText primary="save and print pdf"/>
                </ListItem>
                {
                    !Config.isAdmin &&
                    <ListItem button onClick={() => handleToolSelected('submit')}>
                        <ListItemIcon>
                            <PublishIcon/>
                        </ListItemIcon>
                        <ListItemText primary="submit for review and quote"/>
                    </ListItem>
                }
            </List>
        </Popover>
    )
}
