import React from 'react';
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import './App.css';
import "./components/styles.scss";
import configureAppStore from "./store/store";
import {Provider, useDispatch, useSelector} from 'react-redux'
import AtlasBuilder from "./components/AtlasBuilder";
import priceCalculator from "./config/PriceCalculator";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import SubmissionSteps from "./components/Submission/SubmissionSteps";
import Config from "./config/Config";
import PdfView from "./components/PdfView/PrintView";
import {setPrintMode} from "./store/reducers/appReducer";


const theme = createMuiTheme({
    palette: {
        background: {
            default: "#ffffff"
        }
    },
    typography: {
        // fontFamily: ["Swis LtCn",]
        fontFamily: ["Swis LtCn"],
        button: {
            textTransform: 'none'
        }
    }
});

priceCalculator.init();

export const store = configureAppStore();

function App() {

    let dispatch = useDispatch();
    const printModeOn = useSelector(state => state.appState.printModeOn);

    React.useEffect(() => {
        const printModeOff = () => {
            dispatch(setPrintMode(false))
        };

        window.addEventListener('afterprint', printModeOff, false);
        console.log('Register print events');
        return () => {
            console.log('Remove print events');
            window.removeEventListener('afterprint', printModeOff);
        }
    });

    return (
        <React.Fragment>
            <CssBaseline/>
            <PdfView/>
            <div style={{width:"100%",height:"100%", display: printModeOn ? "none" : "block" }}>
                <BrowserRouter>
                    <Switch>
                        <Route path={'/FAIR'} exact
                               render={
                                   (props) => {
                                       Config.setIsAdmin(true);
                                       priceCalculator.init();
                                       return <AtlasBuilder {...props} isAdmin={true}/>
                                   }
                               }/>
                        <Route path={'/FAIR/:submissionId'} exact
                               render={
                                   (props) => {
                                       Config.setIsAdmin(true);
                                       priceCalculator.init();
                                       return <AtlasBuilder {...props} isAdmin={true}/>
                                   }
                               }/>
                        <Route path={'/'} exact
                               render={
                                   (props) => {
                                       Config.setIsAdmin(false);
                                       return <AtlasBuilder {...props} isAdmin={true}/>
                                   }
                               }/>
                    </Switch>
                </BrowserRouter>
            </div>
        </React.Fragment>
    );
}


export default function AppWrapper(props) {
    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <App/>
            </ThemeProvider>
        </Provider>
    );
}

