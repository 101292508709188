import gsap from "gsap";
import Pin, {PIN_TYPE} from "../threed/layout/Pin";
import {Box2, Vector2, Vector3} from "three";
import Constraint, {COMPONENT_TYPES} from "../threed/layout/Constraint";
import ModelObj from "../workspace/ModelObj";
import {inventory} from "../threed/Inventory";
import Utils from "./Utils";
import LayoutManager from "../threed/layout/LayoutManager";
import {epsilon} from "../config/Config";

export default class Component {
    constructor(props, materials, onModelLoaded) {
        this._props = props;
        this.regions = [];
        for (let key in props) {
            this[key] = props[key];
        }

        this.materials = materials;

        this.constraint = Constraint.initialize(this);
        this.defineProperties();

        this.variation = 22;
        this.modelData = {
            22: {modelPath: this.modelUrl, priceKey: props.priceKey},
            32: {modelPath: this.modelUrl32, priceKey: props.priceKey32},
        };

        this.modelObj = new ModelObj(props, materials, this.setDimensions)

        let _this = this;
        this.loadModel().then(model => {
            onModelLoaded(this);
        });
    }

    async loadModel() {
        let modelPath = this.modelPath;
        let modelData = await this.modelObj.loadModel(modelPath, this.materialUrl, this.materials);

        this.setModel(modelData.model);
        this.size = modelData.dimensions.size;

        // The bracers behind the standards are wider than the actual standards.
        // We need to ignore their width and use pure standard width which is 0.5.
        if (this.isStandard()) {
            this.size.x = 0.5;
        }
        this.center = modelData.dimensions.center;
        this.pivot = modelData.dimensions.pivot;
        this.setDimensions();
    }

    isStandard() {
        return (this._props.type === 'combination' || this._props.type === 'wall-mount')
    }

    setModel(model) {
        model.userData = this;
        this.model3d = model;
    }

    async initVariation(variation) {
        if (!this.constraint.hasVariation) {
            return;
        }

        let oldModel = this.model3d;

        if (this.variation === variation) {
            return;
        }
        this.variation = variation;

        if (!this.model3d) {
            await this.loadModel();
        }

        if (oldModel) {
            inventory.replaceModels(oldModel, this.model3d);
            oldModel.parent.add(this.model3d);
            oldModel.parent.remove(oldModel);
        }
        this.setDimensions();
    }

    setDimensions() {
        this.pin = new Pin(this,
            this._props.pinToTop,
            this.height - this._props.pinToTop,
            this._props.pinToBack,
            this._props.pinToLeft
        );

        if (this.isStandard()) {
            this.pinCount = Math.floor(this.height / 2);
        }
    }

    animatePosition(pos, scale, onComplete) {
        gsap.to(this.model3d.position, {...pos, duration: 0.1, onComplete: onComplete});
        gsap.to(this.model3d.scale, {x: scale, y: scale, z: scale, duration: 0.1});
    }

    bounds() {
        let size2 = this.size.clone().multiplyScalar(0.5);

        let bottomLeft = this.model3d.position.clone();
        bottomLeft.sub(size2).add(this.pivot);

        let topRight = this.model3d.position.clone();
        topRight.add(size2).add(this.pivot);

        return {
            left: bottomLeft.x,
            bottom: bottomLeft.y,
            top: topRight.y,
            right: topRight.x,
            hCenter: (bottomLeft.x + size2.x),
            vCenter: (bottomLeft.y + size2.y),
            width: this.size.x,
            height: this.size.y,
        }
    }

    _boundingBox() {
        let bounds = this.bounds();
        let box = new Box2();
        box.set(new Vector2(bounds.left, bounds.bottom), new Vector2(bounds.right, bounds.top))
        return box;
    }

    intersectsWithComponent(component) {
        let otherBoundBox = component._boundingBox();
        return this.intersectsWithBox(otherBoundBox);
    }

    intersectsWithBox(otherBoundBox) {
        let selfBoundBox = this._boundingBox();
        return selfBoundBox.intersectsBox(otherBoundBox);
    }

    _validatePosition() {
        let box = this._boundingBox();

        let valid = !inventory.components.some(component => {
            if (component.isStandard() || component === this) {
                return false;
            }
            return box.intersectsBox(component._boundingBox());

        });

        if (valid) {
            let columns = LayoutManager.getInstance().columns;
            let margin = this.constraint.marginLeftRight;
            if (box.min.x < columns.left - margin || box.max.x > columns.right + margin) {
                valid = false;
            }
        }
        return valid;
    }

    _adjustPosition(pos) {
        if (this.constraint.mountType !== PIN_TYPE.FLOAT) {
            return pos;
        }

        let _this = this;

        function trySide(leftSide, pos, component) {
            if (leftSide) {
                pos.x -= (_this.bounds().right - component.bounds().left) + epsilon; // Shouldn't touch each other
            } else {
                pos.x -= (_this.bounds().left - component.bounds().right) - epsilon;// Shouldn't touch each other
            }
            _this.model3d.position.copy(pos);
        }

        let valid = true;
        inventory.components.forEach(component => {
            if (component.constraint.mountType !== PIN_TYPE.FLOAT || component === this) {
                return false;
            }

            if (this.intersectsWithComponent(component)) {
                console.log("!!! intersection!");
                let diff = this.model3d.position.x - component.model3d.position.x;
                let leftSide = diff < 0;

                // 1. Try to move the component to one side
                trySide(leftSide, pos, component);

                // 2. if that side is not good try the other side.
                if (this._validatePosition()) {
                    return true;
                } else {
                    trySide(!leftSide, pos, component);
                }

                // 3. if that side is not good as well then we can't validate the component placement.
                valid = valid && this._validatePosition();
            }
        });
        return valid;
    }

    mountAt(position, freeMargin, rightSideFree, leftSideFree) {
        let diff = position.sub(this.pinPosition());

        if (this.constraint.mountType === PIN_TYPE.FLOAT) {
            let leftMargin = leftSideFree ? this.constraint.marginLeftRight : 0;
            let rightMargin = rightSideFree ? this.constraint.marginLeftRight : 0;
            rightMargin = this.width - rightMargin;
            // Don't cross right margin
            let fitX = Math.min(freeMargin - rightMargin + diff.x, 0);
            // Don't cross left margin
            fitX = Math.max(diff.x - leftMargin, fitX);

            diff.x = fitX;
        }

        let newPos = this.model3d.position.clone().add(diff);
        this.model3d.position.copy(newPos);

        //After initial positioning we need to calculate position based on component intersections.
        let valid = this._adjustPosition(newPos);

        if (this.variation === 32) {
            // Move both models to sync.
            this.modelData[22].model3d.position.copy(newPos);
        }
        return valid;
    }

    pinOffset(pin = this.pin) {
        let side = -1;
        if (this.isStandard()) {
            side = 1;
        }
        return new Vector3(
            (this.width / 2 - pin.distToLeft) * side,
            this.height - pin.distToTop,
            this.depth / -2 + pin.distToBack,
        );
    }

    pinPosition(pin = this.pin) {
        return this.model3d.position.clone()
            .add(this.pinOffset(pin));
    }

    setRegion(region) {
        if (region) {
            this.regions.push(region);
        } else {
            this.regions = [];
        }
    }

    defineProperties() {
        Object.defineProperties(this,
            {
                width: {
                    get: () => this.size.x
                },
                height: {
                    get: () => this.size.y
                },
                depth: {
                    get: () => this.size.z
                },
                position: {
                    get: () => this.model3d.position
                },
                modelPath: {
                    get: () => this.modelData[this.variation].modelPath,
                    set: (value) => this.modelData[this.variation].modelPath = value,
                },
                model3d: {
                    get: () => this.modelData[this.variation].model3d,
                    set: (value) => this.modelData[this.variation].model3d = value,
                },
                size: {
                    get: () => this.modelData[this.variation].size || this.modelData[22].size,
                    set: (value) => this.modelData[this.variation].size = value,
                },
                center: {
                    get: () => this.modelData[this.variation].center || this.modelData[22].center,
                    set: (value) => this.modelData[this.variation].center = value,
                },
                pivot: {
                    get: () => this.modelData[this.variation].pivot || this.modelData[22].pivot,
                    set: (value) => this.modelData[this.variation].pivot = value,
                },
                priceKey: {
                    get: () => this.modelData[this.variation].priceKey || this.modelData[22].priceKey,
                    set: (value) => this.modelData[this.variation].priceKey = value,
                },
                key: {
                    get: () => {
                        let key = this.priceKey;
                        if (key === "(per linear inch) desk solid") {
                            key = `${Utils.inch2feet(this.width)}' desk`
                        }
                        return key;
                    }
                }
            }
        )
    }
}
