import React from 'react';
import {Slide} from '@material-ui/core'
import {setFooterLeft} from "../../store/reducers/appReducer";
import connect from "react-redux/es/connect/connect";

function OptionPanel(props) {

    const handleEntering = () => {
        if (props.initialDescription) {
            props.dispatch(setFooterLeft(props.initialDescription));
        }
    };

    return (
        <Slide timeout={props.transition}
               direction="down" in={props.in}
               mountOnEnter unmountOnExit
               onExited={props.transition ? props.onExited : null}
               onEntering={handleEntering}
        >
            <div className={'container'}>
                {props.children}
            </div>
        </Slide>
    );
}

export default connect()(OptionPanel);
