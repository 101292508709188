import {all, select, takeEvery} from "@redux-saga/core/effects";
import {
    setMaterial,
    setPrintMode,
    setViewMode,
    startOver,
    toggleMeasurement,
    toggleWireframe
} from "./reducers/appReducer";
import scene3d from "../threed/Scene3d";
import LayoutManager from "../threed/layout/LayoutManager";

function* performSetMaterial(action) {
    try {
        let {name, value} = action.payload;
        scene3d.setMaterial(name, value);
    } catch (error) {
        console.error('error:', error)
    }
}

function* performStartOver(action) {
    try {
        console.log('perform startover')
        // scene3d.startOver();
        LayoutManager.getInstance().clear();
    } catch (error) {
        console.error('error:', error)
    }
}

function* performSetViewMode(action) {
    let wallDimensions = yield select(state => state.appState.wallDimensions);
    scene3d.setViewMode(action.payload, wallDimensions);
}

function* performSetPrintMode(action) {
    let printModeOn = yield select(state => state.appState.printModeOn);
    scene3d.setRenderOn(!printModeOn);
    scene3d.onWindowResize();
}

function* performToggleMeasurement(action) {
    try {
        let measurementOn = yield select(state => state.appState.measurementOn)
        scene3d.setMeasureOn(measurementOn);
    } catch (error) {
        console.error('error in performToggleMeasurement', error)
    }
}

function* performToggleWireframe(action) {
    try {
        let wireframeOn = yield select(state => state.appState.wireframeOn)
        scene3d.setHiddenLine(wireframeOn);
    } catch (error) {
        console.error('error in performToggleWireframe', error)
    }
}

export default function* rootSaga() {
    yield all([
        yield takeEvery(startOver, performStartOver),
        yield takeEvery(setViewMode, performSetViewMode),
        yield takeEvery(setPrintMode, performSetPrintMode),
        yield takeEvery(setMaterial, performSetMaterial),
        yield takeEvery(toggleMeasurement, performToggleMeasurement),
        yield takeEvery(toggleWireframe, performToggleWireframe),
    ])
}
