/**
 * Created by hasan on 07/08/2017.
 */
import Loader from "./Loader";
import {Box3, DoubleSide, Vector3} from "three";
import {LineSegmentsGeometry} from "three/examples/jsm/lines/LineSegmentsGeometry";
import {LineMaterial} from "three/examples/jsm/lines/LineMaterial";
import {Line2} from "three/examples/jsm/lines/Line2";

export default class ModelObj {
    constructor(params, materials, onModelLoaded) {
        // this.component = new Component(params);
        this.name = params.name;

        this.onLoaded = onModelLoaded;
        this.materials = {};

        this.loader = new Loader();
        // this.loadModel(materials);
    }

    loadModel(modelUrl, materialUrl, materials, onDimensionsChange) {
        this.promiseModelLoaded = new Promise((resolve, reject) => {
            this.resolveModel = resolve;
            this.rejectModel = reject;
        })

        if (modelUrl) {
            if (materialUrl) {
                this.loader.loadObjMtl(modelUrl, materialUrl).then(this.modelLoaded);
            } else {
                this.loader.loadObj(modelUrl, materials).then(this.modelLoaded);
            }
        }
        return this.promiseModelLoaded;
    }

    modelLoaded = (model) => {
        let dimensions = this.adjustModel(model);
        this.extractMaterials(model);

        this.resolveModel({model, dimensions});
    };

    _addMaterial(name, material) {
        material.side = DoubleSide;
        if (material.map) {
            material.color.set(0xFFFFFF);
        }
        this.materials[name] = material;
    }

    extractMaterials = (group) => {
        group.traverse(node => {
            if (node.uuid === group.uuid) {
                return; //Same object.
            }
            if (node.isMesh) {
                let materials = node.material;
                if (!Array.isArray(node.material)) {
                    materials = [node.material];
                }
                materials.forEach(mat => {
                    this._addMaterial(mat.name, mat);
                })
            }
            if (node.isGroup || node.isObject3D) {
                this.extractMaterials(node);
            }
        });
    };

    createHiddenLine(node) {
        // let edgesGeo = new THREE.EdgesGeometry(node.geometry, 70);
        // let pos = edgesGeo.attributes.position.array;


        let pos = node.geometry.attributes.position.array;
        let col = pos.map(p => 1)
        let geo = new LineSegmentsGeometry()

        geo.setPositions(pos);
        geo.setColors(col);

        let mat = new LineMaterial({
            color: 0x000000,
            linewidth: 1, // in pixels
            vertexColors: true,
            //resolution:  // to be set by renderer, eventually
            dashed: false

        });
        mat.resolution.set(this.vpW, this.vpH);
        let line = new Line2(geo, mat);

        return line;
    }

    adjustModel(model) {
        let center = new Vector3(0, 0, 0);
        let size = new Vector3(1, 1, 1);
        let pivot = new Vector3(0, 0.5, 0);

        // center the model
        let box = new Box3();
        box.setFromObject(model);
        box.getCenter(center);
        box.getSize(size);

        let dimensions = {
            size: size.clone(),
            center: center.clone(),
            pivot: pivot.clone().multiply(size)
        }


        center.multiplyScalar(-1);
        pivot.multiply(size).add(center);

        model.traverse(node => {
            if (node.isMesh || node.isLineSegments) {
                let geo = node.geometry;
                geo.translate(pivot.x, pivot.y, pivot.z);

                if (node.isLineSegments) {
                    node.material.color.set(0x000000);
                }
            }
        });

        return dimensions;
    }
}
