import React from 'react';
import {Button, Checkbox, FormControlLabel, FormGroup, Typography} from '@material-ui/core'
import DimensionInput from "./DimensionInput";
import './DimensionOptions.scss';
import Utils from "data/Utils";
import LayoutManager from "../../../threed/layout/LayoutManager";
import connect from "react-redux/es/connect/connect";
import {setWallDimensions} from "../../../store/reducers/appReducer";

function DimensionOptions(props) {
    const {wallDimensions, dispatch, title} = props;

    const handleChange = name => event => {
        let value = name === 'notSet' ? event.target.checked : event;
        let s = {...wallDimensions, [name]: value};

        dispatch(setWallDimensions(s));
        LayoutManager.getInstance().setBounds();
    };

    return (
        <div className={"DimensionOptions"}>
            <Typography variant="h5" className={'option-header'}>
                {title}
            </Typography>

            <DimensionInput id="width" label="wall width" maxSize={Utils.feet2inch(16)} onChange={handleChange('width')}
                            disabled={wallDimensions.notSet} value={wallDimensions.width}/>
            <DimensionInput id="height" label="wall height" maxSize={Utils.feet2inch(16)}
                            onChange={handleChange('height')}
                            disabled={wallDimensions.notSet} value={wallDimensions.height}/>

            <FormGroup row>
                <FormControlLabel
                    control={<Checkbox checked={wallDimensions.notSet} onChange={handleChange('notSet')}
                                       value="notSet"/>}
                    label="I don't know my wall dimensions"
                />
            </FormGroup>
            <div className={'next-button'}>
                <Button variant="contained" onClick={props.optionSelected}>next</Button>
            </div>
        </div>
    )
}


//REDUX
const mapStateToProps = (state) => {
    return {
        wallDimensions: state.appState.wallDimensions
    };
};
export default connect(mapStateToProps)(DimensionOptions)
