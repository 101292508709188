import {Plane, Raycaster, Vector3} from "three";

class CastResult {
    constructor(intersect) {
        if (intersect) {
            this.intersect = intersect;
            this.material = this._getMaterial();
            this.object = intersect.object.parent;
            this.component = this.object.userData;
            this.uv = intersect.uv;
            this.is3D = true;
            this.hit = true;
        } else {
            this.hit = false;
        }
    }

    _getMaterial() {
        if (Array.isArray(this.intersect.object.material)) {
            return this.intersect.object.material[this.intersect.face.materialIndex];
        } else {
            return this.intersect.object.material;
        }
    }

    equals(int) {
        if (int) {
            return int.object === this.object && int.material === this.material;
        } else {
            return false;
        }
    }
}

export default class Ray {
    constructor(_cam) {
        this.cam = _cam;
        this.ray = new Raycaster();
        this.ray.params.Line.threshold=1.5;
        this.ray.linePrecision=1.5;//This seems to be working fine.
        this.plane = new Plane(new Vector3(0, 0, 1), 0);
    }


    prepareRay(mouse, camera = this.cam) {
        camera.updateProjectionMatrix();
        this.ray.setFromCamera(mouse, camera);
    }

    intersectPlane(mouse, camera) {
        this.prepareRay(mouse, camera);
        let result = new Vector3();
        this.ray.ray.intersectPlane(this.plane, result);
        return result;
    }

    rayCastObject(mouse, object) {
        this.prepareRay(mouse);

        let intersects = this.ray.intersectObject(object, true);
        if (typeof intersects[0] !== 'undefined') {
            return intersects[0].point;
        } else {
            return null;
        }
    }

    rayCastObjects(mouse, objects) {
        this.prepareRay(mouse);
        let intersects = this.ray.intersectObjects(objects, true);
        if (typeof intersects[0] !== 'undefined') {
            return new CastResult(intersects[0]);
        } else {
            return new CastResult();
        }
    }


}
