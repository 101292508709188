import scene3d from "../../threed/Scene3d";
import LayoutManager from "../../threed/layout/LayoutManager";
import {store} from "../../App";
import axios from "axios";

const API_URL = '';

export function sendSubmitRequest(formData) {
    return axios({
        method: 'post',
        url: `${API_URL}/api/submissions`,
        data: formData,
        config: {headers: {'Content-Type': 'multipart/form-data'}}
    });
}

function submitDesign(event, purpose, data) {
    event && event.preventDefault();

    let state = store.getState();
    const metalMaterial = state.appState.metalMaterial;
    const woodMaterial = state.appState.woodMaterial;

    let dataUrl = scene3d.getDataUrl();
    let designJson = LayoutManager.getInstance().serialize();
    const jsonData = {
        ...data,
        purpose,

        metal_material: metalMaterial,
        wood_material: woodMaterial,
        design_image: {data: dataUrl},
        design_json: designJson,
    }

    return sendSubmitRequest(jsonData);
}

export function getSubmission(id) {
    return axios.get(`${API_URL}/submissions/${id}.json`)
}

export function getPrices(id) {
    return axios.get(`${API_URL}/prices.json`)
}


export default submitDesign;
