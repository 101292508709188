import LayoutManager from "../threed/layout/LayoutManager";
import {inventory} from "../threed/Inventory";
import {EventEmitter} from "events";
import InputHandlerBase from "./InputHandlerBase";

export class DragInputHandler extends InputHandlerBase {
    constructor(ray, vpW, vpH, snapPointsCreator) {
        super(ray, vpW, vpH);
        this.snapPointsCreator = snapPointsCreator;
        this.snapped = false;
        this.isDragging = false;

        this.delete_target = document.getElementById('delete-target');
    }

    // when user press escape key.
    escape() {
        this.setComponent(null);
    }

    setComponent(component, event) {
        if (this.component) {
            //If you already holding a component, remove it.
            LayoutManager.getInstance().removeComponent(this.component);
        }
        this.component = component;
        if (component) {
            this.startDrag(event);
        } else {
            this.isDragging = false;
        }
    }

    get3dPos(event) {
        return this.ray.intersectPlane(this.getMouse(event));
    }

    startDrag(event) {
        this.isDragging = true;
        this.createSnapPoints();
        // this.initialPosition = this.component.position;
        this.initialPosition = this.component.position;
        this.offset = this.get3dPos(event);
        this.offset.sub(this.initialPosition);
    }

    pointerDown(event) {
        let castResult = this.ray.rayCastObjects(this.getMouse(event), inventory.getComponentModels());

        if (castResult.hit) {
            event.preventDefault();
            if (LayoutManager.getInstance().canMove(castResult.component)) {
                document.body.style.cursor = 'grab';
                LayoutManager.getInstance().startMove(castResult.component);
                this.setComponent(castResult.component, event);
                return true;
            } else {
                document.body.style.cursor = 'not-allowed';
            }
        }
        return false;
    }

    pointerMove(event) {
        event.preventDefault();

        // Additionally check for column main regions to see if we're hovering over columns.
        let objects = LayoutManager.getInstance().regionRoot.children;
        let regionCastResult = this.ray.rayCastObjects(this.getMouse(event), objects);
        if (regionCastResult.hit) {
            this.emit('hoverColumn', regionCastResult.intersect.object);
        } else {
            this.emit('hoverColumn', null);
        }


        if (this.isDragging) {
            document.body.style.cursor = 'grabbing';
            let pos = this.get3dPos(event);
            pos.sub(this.offset);
            this.snap(pos);
            this.component.position.copy(pos);
            return true;
        } else {
            let castResult = this.ray.rayCastObjects(this.getMouse(event), inventory.getComponentModels());
            if (castResult.hit) {
                this.emit('hover3d', castResult);
                if (LayoutManager.getInstance().canMove(castResult.component)) {
                    document.body.style.cursor = 'grab';
                } else {
                    document.body.style.cursor = 'not-allowed';
                }
            } else {
                document.body.style.cursor = 'default';
            }

        }

        return false;
    }

    pointerUp(event) {
        event.preventDefault();
        if (this.isDragging) {
            this.isDragging = false;
            let path = event.path || (event.composedPath && event.composedPath())

            if (path.includes(this.delete_target)) {
                LayoutManager.getInstance().removeComponent(this.component);
            } else {
                LayoutManager.getInstance().addComponent(this.component,true);
            }
            this.component = null;
        }
        return false;
    }

    createSnapPoints() {
        if (!this.component.isStandard()) {
            this.snapPoints = this.snapPointsCreator(this.component);
        } else {
            this.snapPoints = [];
        }
    }

    snap(point) {
        if (this.snapPoints.length === 0) {
            return point;
        }

        let testPoint = point.clone().add(this.component.pinOffset());

        let closest = this.snapPoints.reduce(function (prev, curr) {
            let currDiffSqr = curr.distanceToSquared(testPoint);
            let prevDiffSqr = prev.distanceToSquared(testPoint);

            return (currDiffSqr < prevDiffSqr) ? curr : prev;
        });
        let diff = closest.clone().sub(testPoint);
        diff.z = 0;

        let threshold = 10;
        if (diff.lengthSq() < threshold) {
            // console.log(diff, diff.lengthSq());
            this.setSnapped(closest);
            point.add(diff);
        }
        this.setSnapped(null);
        return point;
    }

    setSnapped(snapped) {
        if (this.snapped === snapped) {
            return;
        }
        this.snapped = snapped;
    }
}
