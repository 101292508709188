import {PIN_TYPE} from "./Pin";

export const COMPONENT_TYPES = {
    drawer: 'drawer',
    bookshelf: 'bookshelf',
    cord: 'cord',
    tv: 'tv',
    hanger: 'hanger',
    deck: 'deck',
    desk: 'desk',
    bracket: 'bracket',
    cap: 'cap',
    lowerCabinet: 'lower-cabinet',
    shortDrawerCabinet: 'short drawer cabinets',
    tallDrawerCabinet: 'tall drawer cabinets',
    cabinet: 'cabinet',
    rack: 'rack',
    standard: 'standard',
    upperCabinet: 'upper-cabinet',
};

export const BOUND_SIDES = {TOP: 1, BOTTOM: 2};
export const SNAP_TYPE = {NONE: 0, COLUMN: 1, COMPONENT: 2, WALL: 3};

export default class Constraint {
    constructor(component) {
        this.component = component;
        this.minElevation = -9999;
        this.maxElevation = 9999;
        this.boundComponentType = null;

        this.marginTop = 0;
        this.marginBottom = 0;
        this.paddingTop = 0;
        this.paddingBottom = 0;
        //Percentage
        this.marginLeftRight = 0.0;

        this.hasVariation = true;

        this.snapToBottom = SNAP_TYPE.NONE;
        this.snapToTop = SNAP_TYPE.NONE;
        this.mountType = PIN_TYPE.STANDARD;

        this.sharePin = false;
        this.forceSharePin = false;
    }

    static initialize(component) {
        let cons = new Constraint(component);
        switch (component.componentType) {
            case COMPONENT_TYPES.cap:
                cons.setBoundComponentType(COMPONENT_TYPES.drawer)
                    .setBoundSide(BOUND_SIDES.TOP)
                    .setSharePin(true, true)
                    .setHelpTextOnFail(component.helpText)
                ;
                break;
            case COMPONENT_TYPES.bracket:
                cons.setBoundComponentType(COMPONENT_TYPES.desk)
                    .setBoundSide(BOUND_SIDES.BOTTOM)
                    .setSharePin(true, false)
                    .setMinElevation(26)
                    .setMaxElevation(27.5)
                    .setHelpTextOnSuccess(component.helpText)
                ;
                break;
            case COMPONENT_TYPES.desk:
                cons.setHelpTextOnSuccess(component.helpText)
                    .setBoundComponentType(COMPONENT_TYPES.bracket)
                    .setBoundSide(BOUND_SIDES.TOP)
                    .setSharePin(true, false)
                    .setMinElevation(27)
                    .setMaxElevation(29)
                    // .setMarginTop(18)
                    // .setPaddingTop(18)
                    .setMarginLeftRight(0.5)
                    .setMountType(PIN_TYPE.FLOAT)
                    .setHasVariation(false)
                ;
                break;
            case COMPONENT_TYPES.lowerCabinet:
                cons.setSnapToBottom(SNAP_TYPE.COLUMN)
                    .setMarginBottom(0)
                    .setMinElevation(4)
                ;
                break;
            case COMPONENT_TYPES.shortDrawerCabinet:
                cons.setSnapToBottom(SNAP_TYPE.COLUMN)
                    .setMarginBottom(0)
                    .setMinElevation(4)
                ;
                break;
            case COMPONENT_TYPES.tallDrawerCabinet:
                cons.setSnapToBottom(SNAP_TYPE.COLUMN)
                    .setMarginBottom(0)
                    .setMinElevation(4)
                ;
                break;
            case COMPONENT_TYPES.cord:
                cons.setMaxElevation(8);
                break;
            case COMPONENT_TYPES.upperCabinet:
                cons.setMinElevation(30)
                    .setMarginTop(-4)
                ;
                break;
            case COMPONENT_TYPES.tv:
                cons.setHelpTextOnSuccess(component.helpText)
                    .setMountType(PIN_TYPE.CENTER)
                    .setHasVariation(false)
                ;
                break;
        }
        return cons;
    }

    setMinElevation(elevation) {
        this.minElevation = elevation;
        return this;
    }

    setMaxElevation(elevation) {
        this.maxElevation = elevation;
        return this;
    }

    setPaddingTop(padding) {
        this.paddingTop = padding;
        return this;
    }

    setMarginBottom(margin) {
        this.marginBottom = margin;
        return this;
    }

    setMarginTop(margin) {
        this.marginTop = margin;
        return this;
    }

    setMarginLeftRight(margin) {
        this.marginLeftRight = margin * this.component.width;
        return this;
    }

    setBoundComponentType(type) {
        this.boundComponentType = type;
        return this;
    }

    setBoundSide(side) {
        this.boundSide = side;
        return this;
    }

    setSharePin(sharePin, force) {
        this.sharePin = sharePin;
        this.forceSharePin = force;
        return this;
    }

    setHelpTextOnFail(helpText) {
        this.helpTextOnFail = helpText;
        return this;
    }

    setHelpTextOnSuccess(helpText) {
        this.helpTextOnSuccess = helpText;
        return this;
    }

    setSnapToTop(value) {
        this.snapToTop = value;
        return this;
    }

    setSnapToBottom(value) {
        this.snapToBottom = value;
        return this;
    }

    setMountType(value) {
        this.mountType = value;
        return this;
    }

    setHasVariation(value) {
        this.hasVariation = value;
        return this;
    }

    meets() {
        return true;
    }
}
