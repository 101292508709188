import {inventory} from "../Inventory";
import {utils3d} from "../Utils3d";
import Columns from "./Columns";
import Wall from "../Wall";
import {store} from 'App'
import {
    setMaterial,
    setPageNum,
    setSelectedStandard,
    setTotalWidth,
    setWallDimensions,
    showInfoPopup
} from "../../store/reducers/appReducer";
import MeasurementLine from "../MeasurementLine";
import {Box2, Group, Vector2, Vector3} from "three";
import Config, {materialsByName} from "../../config/Config";
import {KEYS} from "../../store/StoreKeys";
import priceCalculator from "../../config/PriceCalculator";
import scene3d from "../Scene3d";
import {COMPONENT_TYPES} from "./Constraint";

export default class LayoutManager {
    constructor(scene) {
        this.scene3d = scene;
        this.createSnapPoints = this.createSnapPoints.bind(this);
        this.measurementLine = new MeasurementLine();
    }

    static getInstance(scene = null) {
        if (!this._instance) {
            this._instance = new LayoutManager(scene);
            window.layout = this._instance;
        } else {
            if (!this._instance.scene3d && scene) {
                this._instance.scene3d = scene;
            }
        }
        return this._instance;
    }

    initialize(corners, dragInputHandler, measureInputHandler) {
        this._setBounds(corners);

        this.createWall();

        this.root.add(this.measurementLine.root);
        dragInputHandler.on('hoverColumn', (model) => {
            if (model) {
                //Userdata is a helper box in 'Region' object. see Region._createHelper method
                let {right, left, leftTop, rightTop, leftBottom, rightBottom} = model.userData.bounds;

                let p1 = new Vector3(left, leftTop, 0.5);
                let p2 = new Vector3(right, rightTop, 0.5);

                this.measurementLine.dotsVisible(false);
                this.measurementLine.measure(p1, p2, 10, (right - left) + '" bay');
            } else {
                this.clearLine();
            }
        });

        measureInputHandler.on('measure', (start, end, mouse3d, mouse2d) => {
            this.measurementLine.dotsVisible(true);
            this.measurementLine.measure(
                // start,end,
                start && this.root.worldToLocal(start.clone()),
                end && this.root.worldToLocal(end.clone()),
                5,
                null,
                true,
                mouse3d,
                mouse2d,
            )
        })

        this.columns = new Columns(this.width, this.height);
    }

    clearLine() {
        this.measurementLine.visible = false;
    }


    setBounds(corners = this.corners) {
        this._setBounds(corners);
        console.log('layout setBounds', corners)
        this.wall.setBounds(this.bounds);
        this.columns.setSize(this.width, this.height);
    }

    center() {
        let center = new Vector3();
        this.wall.workArea.getWorldPosition(center);
        return center;
    }

    size() {
        return {width: this.wall.width, height: this.wall.height};
    }

    setViewMode(viewModeOn) {
        this.wall.back.visible = !viewModeOn;
        this.wall.floor.visible = !viewModeOn;
    }

    setWallDimensions(wallDimensions) {
        this.width = wallDimensions.width;
        this.height = wallDimensions.height;
    }

    _setBounds(corners) {

        this.corners = corners;
        let {topRight, bottomLeft, topLeft} = corners;
        this.left = bottomLeft.x;
        this.bottom = bottomLeft.y;
        this.right = topRight.x;
        this.top = topLeft.y;

        if (!this.width || !this.height) {
            let wallDimensions = store.getState().appState.wallDimensions;
            this.width = wallDimensions.width;
            this.height = wallDimensions.height;
        }

        this.bounds = {
            left: this.left,
            bottom: this.bottom,
            right: this.right,
            top: this.top,
            width: this.width,
            height: this.height,
        }
    }

    createWall() {
        this.wall = new Wall(this.bounds);

        this.root = this.wall.group;
        this.root.name = 'Builder Root';

        this.regionRoot = new Group();
        this.regionRoot.name = 'Region Root';

        this.componentRoot = new Group();
        this.componentRoot.name = 'Component Root';

        this.hiddenLineRoot = new Group();
        this.hiddenLineRoot.name = 'Hidden Line Root';

        this.root.add(this.regionRoot, this.componentRoot, this.hiddenLineRoot);
        this.scene3d.scene.add(this.root);
        return this;
    }

    deleteObject(object) {
        this.componentRoot.remove(object.model3d);
        inventory.remove(object);
    }

    initComponent(object, absolute) {
        if (absolute) {
            object.position.x -= this.wall.left;
            object.position.y -= this.wall.bottom;
        }
        this.componentRoot.add(object);
    }

    async removeComponent(component) {
        this.deleteObject(component)
        return priceCalculator.calculatePrice();
    }

    async addComponent(component, interactive) {
        if (interactive) {
            this._alignComponent(component);
        }
        if (component.isStandard()) {
            await this._addStandard(component, interactive);
        } else {
            await this._addComponent(component, interactive);
        }
        return priceCalculator.calculatePrice();
    }

    checkComponentIntersection(boxToCheck, componentToCheck) {
        let hasIntersection = false;
        inventory.components.forEach(component => {
            if (component.isStandard() || component === componentToCheck) {
                return false;
            }

            if (componentToCheck.componentType === COMPONENT_TYPES.desk &&
                component.componentType !== COMPONENT_TYPES.desk) {
                return false;
            }

            let intersect = component.intersectsWithBox(boxToCheck);
            if (intersect) {
                hasIntersection = true;
            }
            return intersect;
        });
        return hasIntersection
    }

    _alignComponent(component) {
        let MIN_DISTANCE_FROM_WALL = 2;

        let model3d = component.model3d;
        let bounds = component.bounds();

        if (this.width - MIN_DISTANCE_FROM_WALL < bounds.right) {
            model3d.position.x += this.width - (bounds.right + MIN_DISTANCE_FROM_WALL);
        }

        if (bounds.left < MIN_DISTANCE_FROM_WALL) {
            model3d.position.x -= bounds.left - MIN_DISTANCE_FROM_WALL;
        }


        const wallMountMinHeight = 6;
        if (component.type === 'combination') {
            model3d.position.y -= bounds.bottom;
        } else if (component.type === 'wall-mount' && this.columns.columns.length === 0) {
            model3d.position.y -= bounds.bottom - wallMountMinHeight;
        } else {
            if (this.height < bounds.top) {
                model3d.position.y += this.height - bounds.top;
            }

            if (bounds.bottom < 0) {
                model3d.position.y -= bounds.bottom;
                if (component.type === 'wall-mount') {
                    model3d.position.y += wallMountMinHeight;
                }
            }
        }
    }

    _showHelpText(component, helpText) {
        if (helpText) {
            let popupPos = utils3d.getPointOnScreen(component.model3d);
            store.dispatch(showInfoPopup({
                message: helpText,
                position: {...popupPos}
            }));
        }
    }

    _addStandard(standard, interactive) {
        // this._addObject(standard);
        // return
        if (this.columns.addStandard(standard, interactive)) {
            this._addObject(standard);
        } else {
            console.log('delete object');
            this.deleteObject(standard);
        }
        store.dispatch(setTotalWidth(this.columns.totalWidth()));
    }

    async _addComponent(component, interactive) {
        // this._addObject(component);
        // return
        let suggestion = this.columns.addComponent(component);
        if (suggestion) {
            if (suggestion.column.width > 30) {
                await component.initVariation(32);
            } else {
                await component.initVariation(22);
            }

            let valid = suggestion.column.addComponent(component, suggestion);
            if(valid){
                this._addObject(component);
            }else{
                this.deleteObject(component);
            }
        } else {
            this._showHelpText(component, component.constraint.helpTextOnFail);
            //Object is already created. If the columns don't accept it we need to delete the object.
            this.deleteObject(component);
        }
    }

    _addObject(object) {
        if (inventory.add(object)) {
            this._showHelpText(object, object.constraint.helpTextOnSuccess);
        }
    }

    canMove(component) {
        if (component.isStandard()) {
            return (
                (!component.leftColumn || component.leftColumn.isEmpty()) &&
                (!component.rightColumn || component.rightColumn.isEmpty())
            )
        }
        return true;
    }

    startMove(component) {
        if (component.isStandard()) {
            this.columns.removeStandard(component);
            store.dispatch(setTotalWidth(this.columns.totalWidth()));
        } else {
            this.columns.removeComponent(component);
            component.initVariation(22);
        }
    }

    createSnapPoints(component) {
        let snapPoints = [];
        this.columns.columns.forEach(column => {
            if (column.leftStandard) {
                snapPoints = snapPoints.concat(column.pinPositions(component.constraint.mountType));
            }
        });
        return snapPoints;
    }

    clear() {
        let components = inventory.getComponents();
        while (components.length > 0) {
            let component = components[0];
            this.deleteObject(component);
        }
        this.columns.dispose();
        priceCalculator.calculatePrice();
    }

    _serializeModels(models) {
        return models.map(model => {
            return {
                name: model.name,
                position: model.model3d.position.clone(),//need to clone it. Otherwise gsap filelds are added to the output.
                key: model.key
            }
        })
    }

    serialize() {
        let standards = [];
        let components = [];

        inventory.components.forEach(component => {
            if (component.isStandard()) {
                standards.push(component);
            } else {
                components.push(component)
            }
        })
        standards.sort((s1, s2) => s1.bounds().left < s2.bounds().left ? 1 : -1);

        let state = store.getState();
        return {
            standards: this._serializeModels(standards),
            components: this._serializeModels(components),
            woodMaterial: state.appState.woodMaterial,
            metalMaterial: state.appState.metalMaterial,
            wallDimensions: state.appState.wallDimensions,
            selectedStandard: state.appState.selectedStandard.name,
            totalWidth: state.appState.totalWidth,
            totalPrice: state.appState.totalCost,
        }
    }

    async _loadModels(models) {
        let promisesModelsLoaded = models.map(async model => {
            let modelParams = Config.componentsByName[model.name];
            return await this.scene3d.addModel(null, modelParams, new Vector3().copy(model.position), false);
        })
        return Promise.all(promisesModelsLoaded);
    }

    async load(json) {
        await scene3d.promiseInitialized;
        this.clear();
        let woodMat = materialsByName[json.woodMaterial];
        let metalMat = materialsByName[json.metalMaterial];

        store.dispatch(setMaterial({name: KEYS.woodMaterial, value: woodMat}))
        store.dispatch(setMaterial({name: KEYS.metalMaterial, value: metalMat}))
        store.dispatch(setWallDimensions(json.wallDimensions))
        store.dispatch(setPageNum(4))

        this.setBounds()
        store.dispatch(setSelectedStandard(Config.componentsByName[json.selectedStandard]))


        await this._loadModels(json.standards);
        console.log('Standards loaded')

        await this._loadModels(json.components);
        console.log('components loaded')
    }
}

