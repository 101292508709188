import {CircleGeometry, Group, Mesh, MeshBasicMaterial, Vector3} from "three";
import {LineGeometry} from "three/examples/jsm/lines/LineGeometry";
import {LineMaterial} from "three/examples/jsm/lines/LineMaterial";
import {Line2} from "three/examples/jsm/lines/Line2";
import {utils3d} from "./Utils3d";
import EventEmitter from 'events';
import Utils from "../data/Utils";

export default class MeasurementLine extends EventEmitter {
    constructor() {
        super();
        this.root = new Group();
        this.initLine();
    }

    set visible(value) {
        this.line.visible = value;
        this.dotsVisible(false);
        this.emit('measurementChanged', {visible: false})
    }

    initLine() {
        let geometry = new LineGeometry();
        let positions = new Float32Array(10 * 3); // 3 vertices per point
        geometry.setPositions(positions);

        this.lineMaterial = new LineMaterial({
            color: 0x999999,
            linewidth: 2,
            dashed: true,
        });

        this.measureLineMaterial = new LineMaterial({
            color: 0x000000,
            linewidth: 1,
            dashed: true,
        });

        let line = new Line2(geometry, this.lineMaterial);
        line.visible = false;
        line.scale.set(1, 1, 1);

        window.line = line;
        this.line = line;
        this.root.add(line);

        const pointGeometry = new CircleGeometry(0.5, 16);
        const material = new MeshBasicMaterial({
            color: 0x00ff00,
            depthWrite: false,
            depthTest: false,
            transparent: true,
            opacity: 0.5,
            // wireframe: true
        });

        this._dotsVisible = false;
        this.startDot = this._createDot('Start dot', pointGeometry, material);
        this.endDot = this._createDot('End dot', pointGeometry, material);
    }

    _createDot(name, geometry, material) {
        let dot = new Mesh(geometry, material);
        dot.renderOrder = 10000;
        dot.name = name;
        dot.visible = false;
        this.root.add(dot);
        return dot;
    }

    setResolution(width, height) {
        this.lineMaterial.resolution.set(width, height); // resolution of the viewport
        this.measureLineMaterial.resolution.set(width, height); // resolution of the viewport
    }

    lineBetweenPoints(p1, p2) {
        let positions = [p1.x, p1.y, p1.z, p2.x, p2.y, p2.z];
        let geometry = this.line.geometry;
        geometry.setPositions(positions);
        this.line.computeLineDistances();
        this.line.visible = true;
    }

    dotsVisible(visible) {
        this._dotsVisible = visible;
        this.startDot.visible = visible;
        this.endDot.visible = visible;
    }

    setDotState(dot, position) {
        dot.visible = !!(this._dotsVisible && position);
        if (position) {
            dot.position.copy(position);
        }
    }

    measure(p1, p2, height, text, measureTool = false, mouse3d, mouse) {
        this.setDotState(this.startDot, p1);
        this.setDotState(this.endDot, p2);

        if (measureTool) {
            this.line.material = this.measureLineMaterial;
        } else {
            this.line.material = this.lineMaterial;
        }

        if (!p2) {
            this.line.visible = false;
            return
        }

        if (!text) {
            let length = p1.clone().sub(p2).length();
            length = Math.floor(length * 100) / 100;
            length = Utils.convertToSizeStr(length, true);
            text = length + " inch";
        }

        let positions = [p1.x, p1.y, p1.z];
        let center;
        if (measureTool) {
            center = p1.clone().lerp(p2, 0.5);
        } else {
            let top = Math.max(p1.y, p2.y);

            let topLeft = new Vector3(p1.x, top + height, p1.z);
            let topRight = new Vector3(p2.x, top + height, p2.z);
            center = topLeft.clone().lerp(topRight, 0.5);

            positions.push(...topLeft.toArray());
            positions.push(...topRight.toArray());
        }

        positions.push(p2.x, p2.y, p2.z);
        if (measureTool && p2.z > mouse3d.z) {
            let zMark = p2.clone();
            zMark.z = mouse3d.z;
            positions.push(...zMark.toArray());
        }

        let pointOnScreen = utils3d.getPointOnScreen(this.line, center);
        let point = mouse || pointOnScreen;
        this.emit('measurementChanged', {pointOnScreen: point, text, measureTool} );

        let geometry = this.line.geometry;
        geometry.setPositions(positions);
        geometry.maxInstancedCount = positions.length / 3 - 1;

        this.line.visible = true;
    }
}

