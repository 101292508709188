import Config from "../../config/Config";
import {BoxBufferGeometry, Mesh, MeshBasicMaterial} from "three";
import LayoutMahager from "./LayoutManager";

export class PIN_TYPE {
    static STANDARD = new PIN_TYPE('STANDARD', ['FLOAT']);
    static CENTER22 = new PIN_TYPE('CENTER22');
    static CENTER32 = new PIN_TYPE('CENTER32');
    static CENTER44 = new PIN_TYPE('CENTER44');
    static CENTER54 = new PIN_TYPE('CENTER54');
    static CENTER64 = new PIN_TYPE('CENTER64');
    static CENTER = new PIN_TYPE('CENTER', ['STANDARD']);
    static FLOAT = new PIN_TYPE('FLOAT', ['STANDARD']);

    constructor(name, compatibleTypes) {
        this.name = name;
        this.compatibleTypes = compatibleTypes || [];
    }

    toString() {
        return `PinType.${this.name}`;
    }

    compatible(type) {
        if (this.name === type.name || this.compatibleTypes.includes(type.name)) {
            return true;
        }
        return false;
    }
}

export default class Pin {
    constructor(parent, distToTop, distToBottom, distToBack, distLeft = 0, type = PIN_TYPE.STANDARD) {
        this.parent = parent;
        this.distToTop = distToTop;
        this.distToBottom = distToBottom;
        this.distToBack = distToBack;
        this.distToLeft = distLeft;
        this.distToRight = parent.width - this.distToLeft;
        this.used = false;
        this.type = type;
    }

    //DEBUGGING
    _deleteHelper() {
        if (this.helper) {
            LayoutMahager.getInstance().regionRoot.remove(this.helper);
        }
    }

    _createHelper(column, deleteOnly) {
        if (!Config.debug || !Config.debug.pinHelper) {
            return
        }

        this._deleteHelper();

        if (deleteOnly || !column.hasBothStandards()) {
            return;
        }

        let color;
        switch (this.type) {
            case PIN_TYPE.CENTER:
                color = 0x00ff00;
                break;
            default:
                color = 0x0000ff;
                break;
        }
        if (this.eliminated) {
            color = 0xff0000;
        }

        // if (this.type !== PIN_TYPE.CENTER) return;

        let opacity = 1;
        let size = 0.5;
        let pos = column.pinPosition(this);

        let material = new MeshBasicMaterial({color: color, transparent: true, opacity: opacity});

        let geometry = new BoxBufferGeometry(size, size, size);
        this.helper = new Mesh(geometry, material);
        this.helper.position.copy(pos);

        window.pinHelper = this.helper;
        LayoutMahager.getInstance().regionRoot.add(this.helper);
    }
}
