import React from 'react';
import OptionPanel from "./OptionPanel";
import MaterialOptions from "./MaterialsSection/MaterialOptions";
import {Box, Grid, Slide} from "@material-ui/core";
import Config, {Dict} from "../../config/Config";
import OptionFooter from "./OptionsFooter";
import DimensionOptions from "./DimensionsSection/DimensionOptions";
import StandardSelection from "./StandardSection/StandardSelection";
import ComponentSelection from "./ComponentSectoin/ComponentSelection";
import connect from "react-redux/es/connect/connect";
import {setFooter, setPageNum} from "../../store/reducers/appReducer";
import './OptionsPanel.scss';
import clsx from 'clsx';

const options = [
    {
        id: 'woodMaterial',
        mandatory: true,
        component: MaterialOptions,
        title: Dict.woodSelectionHeader,
        props: {
            options: Config.woodOptions
        },
        background: 'bg-gray',
    },
    {
        id: 'metalMaterial',
        mandatory: true,
        component: MaterialOptions,
        title: Dict.metalSelectionHeader,
        props: {
            options: Config.metalOptions
        },
        background: 'bg-gray',
    },
    {
        mandatory: false,
        component: DimensionOptions,
        title: Dict.dimensionsHeader,
        props: {},
        background: 'bg-gray',
    },
    {
        mandatory: false,
        component: StandardSelection,
        title: Dict.standardSelectionHeader,
        props: {},
        background: 'bg-svg',
        initialDescription: Dict.standardSelectionIdleDesc,
    },
    {
        mandatory: false,
        component: ComponentSelection,
        props: {},
        background: false,
        footerType: 2
    }
];

function OptionsPanel(props) {
    const [pageStates, setPageStates] = React.useState({0: true});
    const [pageIndex, setPageIndex] = React.useState(0);
    const [panelIn, setPanelIn] = React.useState(true);
    const [transition, setTransition] = React.useState(Config.transition);
    const [currentOption, setCurrentOption] = React.useState(options[pageIndex]);

    const {pageNum, dispatch} = props;

    React.useEffect(() => {
        setCurrentPage(pageNum);
    }, [pageNum]);

    function setCurrentPage(p) {
        //Transition to the last page
        if (p === options.length - 1) {
            setTransition(0);
            setPanelIn(false);
        }
        setPageStates({...pageStates, [pageIndex]: false});

        //TODO: delete this line. Just for debugging
        p %= options.length;
        setPageIndex(p);
        setCurrentOption(options[p]);
    }

    const optionSelected = () => {
        dispatch(setPageNum(pageIndex + 1));
    };

    const enterNextPage = () => {
        if (!panelIn) {
            setTransition(Config.transition);
            setPanelIn(true);
        }
        dispatch(setFooter(null));
        setPageStates({...pageStates, [pageIndex]: true});
    };

    const getOptionComponent = (option, index) => {
        const Comp = option.component;
        return (
            <OptionPanel key={index} in={pageStates[index]} transition={transition} onExited={enterNextPage}
                         initialDescription={option.initialDescription}>
                <Comp key={index} title={option.title} optionSelected={optionSelected} {...option.props}/>
            </OptionPanel>
        );
    };

    return (
        <Box height={'100%'} width={'100%'}>
            <Slide timeout={Config.transition}
                   direction="right" in={panelIn}
                   mountOnEnter unmountOnExit
                   onExited={enterNextPage}

            >
                <Grid container
                      direction="column"
                      justify="center"
                      alignItems="stretch"
                      wrap="nowrap"
                      className={'verticalContainer'}>

                    <Grid item className={clsx('pos-relative grid85 grid85-col', currentOption.background)}>
                        {currentOption.background !== 'bg-svg' ? null :
                            <svg className={'bg'} height="100%" width="100%" viewBox="0 0 100 100"
                                 preserveAspectRatio="none">
                                <polygon className={'polygon'} points="0,100 100,100 100,85 0,98"/>
                            </svg>
                        }
                        {options.map(getOptionComponent)}
                    </Grid>

                    <Grid item className={'grid15 grid15-col'}>
                        <OptionFooter footerType={currentOption.footerType}/>
                    </Grid>
                </Grid>
            </Slide>
        </Box>
    );
}


//REDUX
const mapStateToProps = (state) => {
    return {
        pageNum: state.appState.pageNum,
        woodMaterial: state.appState.woodMaterial,
        metalMaterial: state.appState.metalMaterial,
    };
};
export default connect(mapStateToProps)(OptionsPanel)
