import {inventory} from "../threed/Inventory";
import InputHandlerBase from "./InputHandlerBase";
import {store} from '../App';
import {toggleMeasurement} from "../store/reducers/appReducer";
import LayoutManager from "../threed/layout/LayoutManager";

export class MeasureInputHandler extends InputHandlerBase {
    constructor(ray, vpW, vpH, snapPointsCreator) {
        super(ray, vpW, vpH);
        this.startPoint = null;
        this.endPoint = null;
    }

    escape() {
        this.reset();
        store.dispatch(toggleMeasurement());
    }

    reset() {
        this.startPoint = null;
        this.endPoint = null;
    }

    _raycast(event) {
        let castObjects = [...inventory.getComponentModels(), LayoutManager.getInstance().wall.floor];
        return this.ray.rayCastObjects(this.getMouse(event), castObjects);
    }

    _measureTextPos(event) {
        console.log(event)
        return {x: event.clientX-20, y: event.clientY + 25}
    }

    pointerDown(event) {
        let castResult = this._raycast(event);

        if (castResult.hit) {
            if (this.startPoint) {
                this.startPoint = null;
                this.endPoint = null;
            } else {
                this.startPoint = castResult.intersect.point;
            }

            if (this.startPoint && this.endPoint) {
                this.emit('measure', this.startPoint, this.endPoint, null, this._measureTextPos(event));
            }
        }
        return false;
    }

    pointerMove(event) {
        event.preventDefault();

        document.body.style.cursor = `url("/images/measure-cursor1.png"), crosshair`;

        let castResult = this._raycast(event);

        if (castResult.hit) {
            let originalPoint = castResult.intersect.point.clone();

            let point = castResult.intersect.point.clone();
            if (this.startPoint) {
                let diff = point.sub(this.startPoint);

                this.endPoint = this.startPoint.clone()
                if (Math.abs(diff.x) > Math.abs(diff.y)) {
                    this.endPoint.x += diff.x;
                } else {
                    this.endPoint.y += diff.y;
                }

                if (this.startPoint && this.endPoint) {
                    this.emit('measure', this.startPoint, this.endPoint, originalPoint, this._measureTextPos(event));
                }
            } else {
                this.emit('measure', point, null, originalPoint, this._measureTextPos(event));
                // document.body.style.cursor = 'default';
            }
        }
        return false;
    }

    pointerUp(event) {
        return false;
    }
}
