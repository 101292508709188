import React from "react";
import './PrintView.scss'
import scene3d from "../../threed/Scene3d";
import {useSelector} from "react-redux";
import MaterialListItem from "../sections/MaterialsSection/MaterialListItem";
import List from "@material-ui/core/List/List";
import * as Config from "../../config/Config";

export default function PdfView(props) {
    const printModeOn = useSelector(state => state.appState.printModeOn);
    const metalMaterial = useSelector(state => state.appState.metalMaterial);
    const woodMaterial = useSelector(state => state.appState.woodMaterial);
    const [src, setSrc] = React.useState(null);

    React.useEffect(() => {
        if (printModeOn) {
            let _src = scene3d.getDataUrl();
            setSrc(_src);

            return () => {
                setSrc(null)
            }
        }
    }, [printModeOn]);

    function setImageRef(ref) {
        if (ref) {
            ref.onload = function () {
                window.print();
            };
        }
    }

    if (!printModeOn) {
        return null;
    }

    return (
        <table className={'pdf-view'}>
            <tr>
                <td style={{height: "100%"}}>
                    <img ref={setImageRef} className={'design-img'} src={src}/>
                </td>
                <td style={{minWidth: "130pt"}}>
                    <List style={{width: "100%"}}>
                        <MaterialListItem key="metal" material={Config.materialsByName[metalMaterial]}/>
                        <MaterialListItem key="wood" material={Config.materialsByName[woodMaterial]}/>
                    </List>
                </td>
            </tr>
        </table>
    )
}
