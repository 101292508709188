import React from "react";
import {CircularProgress} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";


export default function SubmissionProgress(props) {
    const {progressPromise, onComplete, purpose} = props;
    const [completed, setCompleted] = React.useState(false);

    React.useEffect(() => {
        if (!completed && progressPromise) {
            console.log('progressPromise', progressPromise);
            progressPromise.then(result => {
                onComplete(result.data);
            }).catch(error => {
                onComplete(error);
            }).finally(() => {
                setCompleted(true);
            })
        }
    }, [completed, progressPromise])

    let title = purpose === 'download' ? "Please wait while the PDF is being generated." : "submitting for pricing"
    return (
        <React.Fragment>
            <DialogTitle id="form-dialog-title">{title}</DialogTitle>
            <DialogContent><CircularProgress/></DialogContent>
        </React.Fragment>
    )
}
